import React from 'react';
import { LoanService } from '../../services';
import { useNotification } from '../../contexts/NotificationContext';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import { formatDate, getCurrentDateFormatted } from '../../utils/formatters';

const NotifyCheckboxCell = (props) => {
    const { createErrorNotification } = useNotification();

    const handleNotifyClick = async () => {
      if (props.value) {
        updateNotify(false);
      } else {
        updateNotify(true);
      }
    }

    const handleOldNotifyClick = async () => {
      createErrorNotification(`Cannot change ${props.transactionType} notify status from previous dates`);
    }

    const updateNotify = async (notify) => {
      if (props.transactionType === "payment") {
        try {
            const response = await LoanService.updatePaymentNotify(props.id, notify);
            if (response.status && response.status === 200) {
                // TODO: Improve me
                setTimeout(() => window.location.reload(), 100);
            } else {
                createErrorNotification("Failed to change notification setting.");
            }
        } catch (err) {
            createErrorNotification("Failed to change notification setting.");
        }
      } else if (props.transactionType === "return") {
        try {
          const response = await LoanService.updateReturnNotify(props.id, notify);
          if (response.status && response.status === 200) {
              // TODO: Improve me
              setTimeout(() => window.location.reload(), 100);
          } else {
              createErrorNotification("Failed to change notification setting.");
          }
        } catch (err) {
            createErrorNotification("Failed to change notification setting.");
        }
      }
      
    }

    return props.disableUpdates ? (props.value ?
        <CheckBoxIcon/> : 
        <CheckBoxOutlineBlank/>) :
        (formatDate(props.date) === getCurrentDateFormatted() ?
        (props.value ?
        <CheckBoxIcon onClick={handleNotifyClick}/> : 
        <CheckBoxOutlineBlank onClick={handleNotifyClick}/>) :
        (props.value ?
        <CheckBoxIcon onClick={handleOldNotifyClick}/> : 
        <CheckBoxOutlineBlank onClick={handleOldNotifyClick}/>));
}

export default NotifyCheckboxCell;
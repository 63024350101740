import React from 'react';
import "./login.css"

export default function PageNotFound({history}) {
    return (
        <div className="main-pw">
            <p className="sign" align="center">Page Not Found!</p>
            <button
            className="submit"
            align="center"
            onClick={() => history.goBack()}
          >
            Go back
          </button>
            <div style={{width:'100%',height:'0',paddingBottom:'84%',position:'relative', paddingTop:'30px'}}><iframe src="https://giphy.com/embed/9J7tdYltWyXIY" width="100%" title="not-found-gif" height="100%" style={{position:'absolute'}} frameBorder="0" className="giphy-embed" allowFullScreen></iframe></div>
        </div>   
    );
}

import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { UserService } from '../../services';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { useNotification } from '../../contexts/NotificationContext';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

function isBlank(value) {
  return value == null || value.trim().length === 0;
}

function updateRequiredField(value, updater, errorUpdater) {
  if (isBlank(value)) {
    errorUpdater(true);
    return true;
  } else {
    errorUpdater(false);
    updater(value);
    return false;
  }
}

const useStyles = makeStyles((theme) => ({
  paper: {
      position: 'absolute',
      left: "20%",
      top: "15%",
      width: '50vw',
      height: '50vh',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      overflowY: 'scroll',
  },
  modalButton: {
      marginLeft: '15px',
      // float: 'right',
  },
  coreForm: {
    height: '50vh'
  }
}));

const NewUser = (props) => {
    const classes = useStyles();
    const [email, setEmail] = useState(null);
    const [emailError, setEmailError] = useState(false);
    const [tempPassword, setTempPassword] = useState(null);
    const [tempPasswordError, setTempPasswordError] = useState(false);
    const { createErrorNotification } = useNotification();
    const [showWaitMessage, setShowWaitMessage] = useState(false);

    const resetState = () => {
      setEmail(null);
      setEmailError(false);
      setTempPassword(null);
      setTempPasswordError(false);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        // First check for missing required fields
        let hasMissingRequiredFields = false;
        hasMissingRequiredFields = updateRequiredField(email, setEmail, setEmailError) || hasMissingRequiredFields;
        hasMissingRequiredFields = updateRequiredField(tempPassword, setTempPassword, setTempPasswordError) || hasMissingRequiredFields;

        if (hasMissingRequiredFields) {
          createErrorNotification("Missing Required Fields");
        } else {
          try {
            const response = await UserService.newUser({
              email: email,
              tempPassword: tempPassword,
            });

            if (response.status === 200) {
              resetState();
              props.toggleShowNewUser();
              setShowWaitMessage(true);
              setTimeout(() => window.location.reload(), 10000);
            } else {
              throw new Error("Failed to create new user");
            }
          } catch (err) {
            createErrorNotification("Failed to create new user");
          }
        }
    }

    const handleCancel = () => {
      props.toggleShowNewUser();
      resetState();
    }

    const handleEmailChange = (event) => updateRequiredField(event.target.value, setEmail, setEmailError)
    const handleTempPasswordChange = (event) => updateRequiredField(event.target.value, setTempPassword, setTempPasswordError)

    const closeWaitMessage = () => {
      setShowWaitMessage(false);
    }

    const body = (
      <div className={classes.paper}>
          <h2 id="simple-modal-title">Create User</h2>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={5} justifyContent="flex-start">
              <Grid item xs={12}>
                <TextField label="Email" autoFocus={true} fullWidth={true} required={true} error={emailError} onChange={handleEmailChange} />
              </Grid>
              <Grid item xs={12} >
                <TextField label="Temp Password" fullWidth={true} margin="normal" required={true} error={tempPasswordError} onChange={handleTempPasswordChange} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button variant="contained" color="primary" className={classes.modalButton} onClick={handleSubmit}>Create</Button>
                <Button variant="contained" className={classes.modalButton} onClick={handleCancel}>Cancel</Button>
              </Grid>
            </Grid>
          </form>
      </div>
    );

  return (
      <div>
          <Snackbar open={showWaitMessage} autoHideDuration={3000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert onClose={closeWaitMessage} severity="success">
              {"Creating new user. This change will take some time to propagate. Please refresh the page in a few seconds to see the new user."}
            </Alert>
          </Snackbar>
          <Modal
            open={props.showNewUser}
            onClose={props.toggleShowNewUser}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description">
              {body}
          </Modal>
      </div>
  );
}

export default NewUser;
/* eslint eqeqeq: "off" */
import React, { memo, Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import yellow from '@material-ui/core/colors/yellow';
import grey from '@material-ui/core/colors/grey';


const RulesList = ({rules, filters, setFilters}) => {

    return rules == null ? (
        <h1>Loading...</h1>
      ) : (
        <Fragment>
          {rules.map((rule) => {
            return <Grid item><Chip onClick={() => setFilters(rule.name)}
                label={`${rule.name}: ${rule.result} failures`} 
                style={{fontWeight: 'bold', fontSize: '12px', backgroundColor: (filters ? (rule.name == filters ? yellow[500]: grey[500]) : rule.result > 0 ? red[500] : green[500])}}/>
              </Grid>
            
          })}
            
        </Fragment>
      );
    
}

export default memo(RulesList);
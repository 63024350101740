import React, { Fragment, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Error';
import { centsToDollar } from '../../utils/formatters';
import PaymentsTable from './PaymentsTable';
import NewPayment from './NewPayment';
import { LoanTypeMetricBreakdown } from '../../components';
import { AmountCountItem } from './CountAmountLineItem';

const styles = makeStyles((theme) => ({
    root: {
      },
      summaryContainer: {
        paddingLeft: '50px',
        paddingRight: '50px',
        width: '85vw'
      },
      summaryHeader: {
          textAlign: 'center'
      },
      summaryTotals: {
          paddingTop: '10px',
          paddingBottom: '10px'
      },
      summaryBody: {
        paddingTop: '10px',
        paddingBottom: '10px'
      },
      summary: {
        backgroundColor: theme.palette.grey.main,
        borderRadius: '5px',
        border: 'groove',
        borderColor: 'black',
        borderWidth: '0.5px',
      },
      table: {
          marginTop: '30px',
          width: '85vw',
          marginRight: '5vw'
      },
      subItem: {
        listStyleType: "circle",
        marginTop: '5px'
      }
}));

const Payments = ({ dailyProcessingData, selectedDate}) => {
    const classes = styles();
    useTheme();
    const [showNewPayment, setShowNewPayment] = useState(false);

    if (dailyProcessingData === null) {
        return <h1>Loading...</h1>;
    }

    const paymentSummary = dailyProcessingData.paymentsSummary;
    const payments = dailyProcessingData.payments;
    const toggleShowNewPayment = () => setShowNewPayment(!showNewPayment);

    return (
        <Fragment>
            <Grid container direction="column">
                <Grid item className={classes.summaryContainer}>
                    <h3 className={classes.summaryHeader}>Summary</h3>
                    <Grid container direction="column" className={classes.summary}>
                        <Grid container justifyContent="space-evenly" className={classes.summaryBody}>
                            <Grid item className={classes.summaryTotals}>
                                <li>Total Payments: {paymentSummary[0].totalPaymentAmount ? centsToDollar(paymentSummary[0].totalPaymentAmount) : "$0"}</li>
                                <li>Total Payments Count: {paymentSummary[0].totalPaymentNumber || 0}</li>
                            </Grid>
                            <Grid item className={classes.summaryTotals}>
                                <LoanTypeMetricBreakdown loanType={'Solar'} totalStat={paymentSummary[0].totalPaymentAmountESG || 0} countStat={paymentSummary[0].totalPaymentNumberESG || 0}></LoanTypeMetricBreakdown>
                            </Grid>
                            <Grid item className={classes.summaryTotals}>
                                <LoanTypeMetricBreakdown loanType={'Home Improvement'} totalStat={paymentSummary[0].totalPaymentAmountHI || 0} countStat={paymentSummary[0].totalPaymentNumberHI || 0}></LoanTypeMetricBreakdown>
                            </Grid>
                            <Grid item className={classes.summaryTotals}>
                                <LoanTypeMetricBreakdown loanType={'Healthcare'} totalStat={paymentSummary[0].totalPaymentAmountHC || 0} countStat={paymentSummary[0].totalPaymentNumberHC || 0}></LoanTypeMetricBreakdown>
                            </Grid>
                            <Grid item className={classes.summaryTotals}>
                                <AmountCountItem label={"Paid Amount/Count"} amount={paymentSummary[0].totalPaidPaymentAmount} count={paymentSummary[0].paidPaymentsCount} boldLabel={false} />
                                <AmountCountItem label={"Processing Amount/Count"} amount={paymentSummary[0].totalProcessingPaymentAmount} count={paymentSummary[0].processingPaymentsCount} boldLabel={false} />
                                <AmountCountItem label={"Held Amount/Count"} amount={paymentSummary[0].totalHeldPaymentAmount} count={paymentSummary[0].heldPaymentsCount} boldLabel={false} />
                                <AmountCountItem label={"Received Amount/Count"} amount={paymentSummary[0].totalReceivedPaymentAmount} count={paymentSummary[0].receivedPaymentsCount} boldLabel={false} />
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid item >
                            <Grid item>
                                <Grid container spacing={2} justifyContent="space-evenly" direction="row" className={classes.summaryBody}>
                                    {paymentSummary[0].paymentsSummaryByLender ? paymentSummary[0].paymentsSummaryByLender.map((row) => (
                                    <Grid item>
                                        <p><b><u>{row.shortName}</u></b></p>
                                        <AmountCountItem label={"Total Payments/Count"} amount={row.totalPaymentAmount} count={row.totalPaymentNumber} boldLabel={true} />
                                        <AmountCountItem label={"NSF Payments/Count"} amount={row.nsfAmountCents} count={row.nsfNumber} boldLabel={true} />
                                        <AmountCountItem label={"S1+S2+S3 Payments/Count"} amount={row.stagedAmountCents} count={row.stagedNumber} boldLabel={true} />
                                        <ul className={classes.subItem}>
                                            <AmountCountItem label={"S1 Payments/Count"} amount={row.s1AmountCents} count={row.s1Number} />
                                            <AmountCountItem label={"S2 Payments/Count"} amount={row.s2AmountCents} count={row.s2Number} />
                                            <AmountCountItem label={"S3 Payments/Count"} amount={row.s3AmountCents} count={row.s3Number} />
                                        </ul>
                                    </Grid>
                                    )) : null}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid item >
                            <Grid container spacing={2} justifyContent="space-evenly" direction="row" className={classes.summaryBody} >
                                    <Grid item>
                                        <p><b><u>Staged Funding</u></b></p>
                                        <li>Has Staged Funding: {paymentSummary[0].stagedPayments || 0}</li>
                                        <li>NO Staged Funding: {paymentSummary[0].unstagedPayments || 0}</li>
                                    </Grid>
                                    <Grid item>
                                        <p><b><u>Holds</u></b></p>
                                        <li>Loans coming off hold: {paymentSummary[0].comingOffHold || 0}</li>
                                    </Grid>
                                    {paymentSummary[0].totalBouncedPaymentNumber > 0 ?
                                    <Grid item>
                                        <p><ErrorIcon color='error' /><b><u>Bounces</u></b></p>
                                        <li>Bounced Payments: {paymentSummary[0].totalBouncedPaymentNumber || 0}</li>
                                    </Grid> : null}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={classes.table}>
                    <PaymentsTable payments={payments} selectedDate={selectedDate} toggleShowNewPayment={toggleShowNewPayment} paymentType={'payments'} /> :
                </Grid>
            </Grid>
            <NewPayment showNewPayment={showNewPayment} toggleShowNewPayment={toggleShowNewPayment} selectedDate={selectedDate} />
        </Fragment>
    );
}

export default Payments;

import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Grid from '@material-ui/core/Grid';

const TextMask = (props) => {
    const textValue = props.value;
    const maskedValue = '#'.repeat(textValue?.length);
    const [isMasked, setIsMasked] = useState(true);

    const toggleMask = () => {
        setIsMasked(!isMasked);
    }
    
    return (textValue && textValue.length > 0) && (
        <div style={{display: 'inline-flex'}}>
            <Grid container direction="row">
                <p>{isMasked ? maskedValue : textValue}</p>
                <IconButton onClick={toggleMask}>
                    {isMasked ? <Visibility/> : <VisibilityOff />}
                </IconButton>
            </Grid>
        </div>
    )
}

export default TextMask;
import React, { memo, Fragment } from 'react';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { GetApp } from '@material-ui/icons';

const DownloadButton = ({title, onClick}) => {

  return (
    <Fragment>
        <Tooltip title={title}>
            <IconButton onClick={onClick}> 
                <GetApp/>
            </IconButton>
        </Tooltip>
    </Fragment>
  );
}

export default memo(DownloadButton);
import React, { useState, useEffect } from 'react';
import FeesConfigTable from './FeesConfigTable';
import { FeeService } from '../../services';
import NewFeeConfig from './NewFeeConfig';

const FeesConfig = (props) => {
    const [feeConfigs, setFeeConfigs] = useState(null);
    const [showNewFeeConfig, setShowNewFeeConfig] = useState(false);

    const toggleShowNewFeeConfig = () => setShowNewFeeConfig(!showNewFeeConfig);

    useEffect(() => {
        if (feeConfigs === null) {
            FeeService.getCurrentFees().then(data => {
                setFeeConfigs(data);
            })
        }
    }, [feeConfigs]);

    return (
        <div id="fees-config-container">
          <h1>Fee Configurations</h1>
          <FeesConfigTable feeConfigs={feeConfigs} toggleShowNewFeeConfig={toggleShowNewFeeConfig} />
          <NewFeeConfig showNewFeeConfig={showNewFeeConfig} toggleShowNewFeeConfig={toggleShowNewFeeConfig} />
        </div>
    );
}

export default FeesConfig;

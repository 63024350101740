import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';
import blue from '@material-ui/core/colors/blue';
import Chip from '@material-ui/core/Chip';
import { formatDate, promoToColor } from '../utils/formatters';

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }))(Tooltip);

const LoanMetricTooltip = (props) => {
    return (
        <div style={{display:"flex"}}>
          <a style={{paddingTop:"10px"}} href={`/lms/loans/${props.loanId}`}>{props.loanId}</a>
          <HtmlTooltip interactive={true}
            title={
              <React.Fragment>
                <Typography color="inherit"><b><u>Loan Details</u></b></Typography>
                <p><b>Loan Term: </b>{props.loanTerm}</p>
                <p><b>APR: </b>{props.loanApr}</p>
                <p><b>Total Loan Amount: </b>{props.totalLoanAmount}</p>
                <p><b>Estimated Project Amount: </b>{props.estimatedProjectAmount}</p>
                {props.projectType ? <p><b>Project Type: </b>{props.projectType}</p> : ''}
                {props.applicationDate ? <p><b>Application Date: </b>{formatDate(props.applicationDate)}</p> : ''}
                {props.returnDate ? <p><b>Return Date: </b>{formatDate(props.returnDate)}</p> : ''}
                <p><b>Promos: </b>{props.promos.map( (val, key) => {
                  return <Chip label={val} key={key} style={{backgroundColor: promoToColor(val), color: 'white'}}/>;
                })}</p>
              </React.Fragment>
            }
          >
            <IconButton component="span">
                <InfoIcon style={{ color: blue[700] }} />
            </IconButton>
          </HtmlTooltip>
        </div>
      );
}

export default LoanMetricTooltip;
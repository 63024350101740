import React, { memo, Fragment } from 'react';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import RefreshOutlinedIcon from '@material-ui/icons/RefreshOutlined';
import RestoreOutlinedIcon from '@material-ui/icons/RestoreOutlined';

const RepullButton = ({hasItems, pullName, onClick}) => {

  return (
      <Fragment>
          <Tooltip title={`${hasItems ? 'WARNING: Force Pull': 'Pull'} ${pullName}`}>
            <IconButton onClick={onClick}> 
            {
                hasItems ?
                    <RestoreOutlinedIcon /> :
                    <RefreshOutlinedIcon />
            }
            </IconButton>
          </Tooltip>
    </Fragment>
  );
}

export default memo(RepullButton);
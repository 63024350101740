import React, { useState }  from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ConfirmationModal from '../components/ConfirmationModal';
import TextField from '@material-ui/core/TextField';
import green from '@material-ui/core/colors/green';
import InputAdornment from '@material-ui/core/InputAdornment';
import { DealerService } from '../services';
import { useNotification } from '../contexts/NotificationContext';

const styles = makeStyles(() => ({
    submitButton: {
        marginLeft: '10px',
        marginTop: '25px'
    },
    confirmButton: {
        backgroundColor: green[400],
        float: 'right',
        marginTop:'16px',
        marginRight:'4px'

    },
    inputAmount: {
        float: 'right',
        marginTop: '8px',
        marginRight:'4px',
        marginBottom: '4px'
    }


}));




const SyncDealerWithHubspot = (props) => {

    const [DealerID, setLoanID] = useState(null);    
    const [showConfirmConfirmationBox, setShowConfirmConfirmationBox] = useState(false);
    const toggleConfirmConfirmation = () => setShowConfirmConfirmationBox(!showConfirmConfirmationBox);
    const handleChange = (event) => setLoanID(event.target.value)
    const { createErrorNotification } = useNotification();

    const handleConfirmSyncButtonClick = async (event) => {
        
        toggleConfirmConfirmation();

    }
    
    const handleConfirmSyncButton = async (event) => {
        toggleConfirmConfirmation();  
        DealerService.syncDealerWithHubspot(DealerID).then(response => {
            
        }).catch (error => {
            createErrorNotification('Failed to sync the Dealer ID:' + DealerID)
        });
        
        
        
    }

    const classes = styles();
    return(

    <div>
        <ConfirmationModal title="Confirm" message="Are you sure you want to sync dealer with hubspot?" onConfirm={handleConfirmSyncButton} onCancel={toggleConfirmConfirmation} showConfirmationBox={showConfirmConfirmationBox}/>
        
        <Button variant="contained" 
        className={classes.confirmButton} 
        onClick={handleConfirmSyncButtonClick}
        >submit
        </Button>
        <TextField
        id="outlined-select-currency-native"
        className={classes.inputAmount}
        value={DealerID}
        placeholder="6734"
        label="Dealer ID"
        variant="outlined"
        onChange={handleChange}
        InputProps={{
            startAdornment: <InputAdornment position="start">Optional</InputAdornment>,
        }}
        ></TextField>
        
    </div>

    )
    
}

export default SyncDealerWithHubspot;
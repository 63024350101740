export const saveTableSession = (columns, sessionVar) => {
  let colString = columns.toString()
  sessionStorage.setItem(sessionVar, colString);
  return columns;
}

export const getTableSession = (sessionVar) => {
  let columns = sessionStorage.getItem(sessionVar);
  return columns.split(',');
}

import { request } from './utils';
import { formatDate } from './../utils/formatters';
import download from "downloadjs";

export default class FeeService {
    static async newFeeConfiguration(feeConfig){
        const response = await request('/fee-config/new', {
            method: 'POST',
            data: JSON.stringify(feeConfig)
        });
        return response;
    }

    static async getFeePayments(date) {
        const formattedDate = formatDate(date);
        const response = await request(`/fee-config/${encodeURIComponent(formattedDate)}`);
        return response.data;
    }

    static async getFeeSummary(date) {
        const formattedDate = formatDate(date);
        const response = await request(`/fee-config/${encodeURIComponent(formattedDate)}/summary`);
        return response.data;
    }

    static async getCurrentFees() {
        const response = await request(`/fee-config`);
        return response.data;
    }

    static async downloadFeeReport(date, organization) {
        const formattedDate = formatDate(date);
        const response = await request(`/fee-config/report/${encodeURIComponent(formattedDate)}/${encodeURIComponent(organization)}/download`);
        if (response.status === 200) {
            download(response.data, response.headers["x-filename"]);
            return response.data;
        } else if(response.status === 204) {
            return response.data;
        } else {
            throw new Error("Server error downloading Fee File");
        }
    }

    static async deleteFeeConfigurations(feeConfigIDs){
        const response = await request('/fee-config/delete', {
            method: 'POST',
            data: feeConfigIDs
        });

        return response.data;
    }

    static async updateFeeConfiguration(feeConfigID, newPercentage){
        const response = await request(`/fee-config/${feeConfigID}/update`, {
            method: 'POST',
            data: {percentage: newPercentage}
        });
        return response;
    }

    static async downloadNachaFeeFile(date) {
        const formattedDate = formatDate(date);
        const response = await request(`/fee-config/nacha/${encodeURIComponent(formattedDate)}/download`);
        if (response.status === 200) {
            download(response.data, response.headers["x-filename"]);
            return response.data;
        } else if (response.status === 204) {
            return response.data;
        } else {
            throw new Error("Server error downloading Fee Nacha File");
        }
    }

}
import React, { Fragment, memo } from 'react';
import MUIDataTable from 'mui-datatables';
import Chip from '@material-ui/core/Chip';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import ActiveUserCell from './ActiveUserCell';
import EditRolesCell from './EditRolesCell';
import { formatDate } from '../../utils/formatters';

// TODO: Allow filtering by roles. Need custom filtering or rendering logic
const getColumns = (rolesData) => [
  {
    name: 'Username',
    options: {
        filter: false,
        display: false,
    }
  },
  {
    name: 'Email',
    options: {
        filter: false
    }
  },
  {
    name: 'Roles',
    options: {
      customBodyRender: (value) => {
        return value.map(role => {
            return <Chip label={role} style={{ margin: '5px' }} />
        });
    }
    }
  },
  {
    name: 'Edit Roles',
    options: {
      customBodyRender: (value, tableMeta) => {
        return <EditRolesCell username={tableMeta.rowData[0]} email={tableMeta.rowData[1]} currentRoles={tableMeta.rowData[2]} rolesData={rolesData} />
      }
    }
  },
  {
    name: 'Active User?',
    options: {
        customBodyRender: (value, tableMeta) => {
            return <ActiveUserCell username={tableMeta.rowData[0]} email={tableMeta.rowData[1]} value={value} />
        }
    }
  },
  {
    name: 'Created Date',
    options: {
        filter: false
    }
  },
];

const getTableOptions = (toggleShowNewUser) => {
    return {
        filterType: 'dropdown',
        selectableRows: 'none',
        print: false,
        download: false,
        expandableRowsHeader: false,
        customToolbar: () => {
            return (
              <AddUserToolbarButton toggleShowNewUser={toggleShowNewUser} />
            );
          }
    };
};


const tablifyUsers = users =>
  users === null ? null :
  users.map(user => {
      return [
        user.username,
        user.email,
        user.roles.sort(),
        null,
        user.isActive,
        formatDate(user.createdAt),
      ];
  });

const AddUserToolbarButton = ({toggleShowNewUser}) => {
  const handleAddUserClick = () => {
    toggleShowNewUser();
  }

    return (
        <Fragment>
            <Tooltip title={"Add New User"}>
                <IconButton onClick={handleAddUserClick}>
                    <AddIcon />
                </IconButton>
            </Tooltip>
      </Fragment>
    );
}

const UserAdminTable = ({users, roles, toggleShowNewUser}) => {
    const options = getTableOptions(toggleShowNewUser);
    // const userData = tablifyUsers(users);
    // const rolesData = props.roles;
    
    return users == null ? (
        <h1>Loading...</h1>
      ) : (
        <MUIDataTable data={tablifyUsers(users)} columns={getColumns(roles)} options={options} />
      );
}

export default memo(UserAdminTable);
import React, { useState }  from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ConfirmationModal from '../components/ConfirmationModal';
import green from '@material-ui/core/colors/green';
import { LoanService } from '../services';
const styles = makeStyles(() => ({
    submitButton: {
        marginLeft: '10px',
        marginTop: '25px'
    },
    confirmButton: {
        backgroundColor: green[400],
        float: 'right',
        marginTop:'16px',
        marginRight:'4px'

    },
    inputAmount: {
        float: 'right',
        marginTop: '8px',
        marginRight:'4px',
        marginBottom: '4px'
    }


}));




const NLSDownloadXML = (props) => {


    const [showConfirmConfirmationBox, setShowConfirmConfirmationBox] = useState(false);
    const toggleConfirmConfirmation = () => setShowConfirmConfirmationBox(!showConfirmConfirmationBox);

    const handleConfirmNLSDownloadXML = async (event) => {
        
        toggleConfirmConfirmation();

    }
    
    const handleConfirmNLSDownloadXMLButton = async (event) => {
        LoanService.downloadNLSHoldRelease();
        toggleConfirmConfirmation();
    }

    const classes = styles();
    return(

    <div>
        <ConfirmationModal title="Confirm" message="Are you sure you want to download?" onConfirm={handleConfirmNLSDownloadXMLButton} onCancel={toggleConfirmConfirmation} showConfirmationBox={showConfirmConfirmationBox}/>
        
        <Button variant="contained" 
        className={classes.confirmButton} 
        onClick={handleConfirmNLSDownloadXML}
        >submit
        </Button>
        
    </div>

    )
    
}

export default NLSDownloadXML;
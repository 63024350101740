import { request } from './utils';
import { formatDate, serverSidePaginationSearchParams } from './../utils/formatters';
import { downloadReport } from './../utils/downloadHelper';
import download from "downloadjs";

export default class LoanService {
    static async getAllVerbose(limit, offset, sortOrder, searchText, filterList) {
        const response = await request(`/loans/verbose${serverSidePaginationSearchParams(limit, offset, sortOrder, searchText, filterList)}`);
        return response.data;
    }

    static async getSingleLoanVerbose(loanNumber) {
        const response = await request(`loans/${loanNumber}/verbose`);

        return response.data;
    }

    static async getSingleLoanVisionCalculations(loanNumber) {
        const response = await request(`/loans/${loanNumber}/vision-calculations`);
        return response.data;
    }

    static async sendEmail() {
        const response = await request('/loans/test/email');

        return response.data;
    }

    static async getDailyProcessing(date) {
        const formattedDate = formatDate(date);
        const response = await request(`/loans/daily-processing/${encodeURIComponent(formattedDate)}`);

        return response.data;
    }

    static async getHoldsFromDailyProcessing(date) {
        const formattedDate = formatDate(date);
        const response = await request(`/loans/daily-processing/${encodeURIComponent(formattedDate)}/holds`);

        return response.data;
    }

    static async getProcessingPaymentsFromDailyProcessing(date) {
        const formattedDate = formatDate(date);
        const response = await request(`/loans/daily-processing/${encodeURIComponent(formattedDate)}/processing-payments`);

        return response.data;
    }

    static async getFundingRequestsFromDailyProcessing(date) {
        const formattedDate = formatDate(date);
        const response = await request(`/loans/daily-processing/${encodeURIComponent(formattedDate)}/funding-requests`);

        return response.data;
    }

    static async getPaymentsFromDailyProcessing(date) {
        const formattedDate = formatDate(date);
        const response = await request(`/loans/daily-processing/${encodeURIComponent(formattedDate)}/payments`);

        return response.data;
    }

    static async getReturnsFromDailyProcessing(date) {
        const formattedDate = formatDate(date);
        const response = await request(`/loans/daily-processing/${encodeURIComponent(formattedDate)}/returns`);

        return response.data;
    }

    static async getNotificationsFromDailyProcessing(date) {
        const formattedDate = formatDate(date);
        const response = await request(`/loans/daily-processing/${encodeURIComponent(formattedDate)}/notifications`);

        return response.data;
    }

    static async getRulesFromDailyProcessing(date) {
        const formattedDate = formatDate(date);
        const response = await request(`/loans/daily-processing/${encodeURIComponent(formattedDate)}/rules`);

        return response.data;
    }

    static async getFundingRequests(date) { // unused
        const formattedDate = formatDate(date);
        const response = await request(`/loans/funding-request/${encodeURIComponent(formattedDate)}`);
        return response.data;
    }

    static async pullDelinquencies(){
        const formattedDate = formatDate(new Date());
        const response = await request(`loans/delinquency/${encodeURIComponent(formattedDate)}/pull`)
        return response;
    }

    static async pullNLSComment(){
        const response = await request(`loans/daily-processing/nls-comments/pull`)
        return response;
    }

    static async pullDelinquenciesComment(){
        const formattedDate = formatDate(new Date());
        const response = await request(`loans/delinquency/comments/${encodeURIComponent(formattedDate)}/pull`)
        return response;
    }

    static async retryHubspotQueue() {
        const response = await request('/tools/retry-hubspot-queue');
        return response.data;
    }

    static async getRetryHubspotQueue() {
        const response = await request('/tools/get-retry-hubspot-queue');
        return response;
    }

    static async repullDataDump() {
        const formattedDate = formatDate(new Date());
        const response = await request(`/tools/repull-data-dump/${encodeURIComponent(formattedDate)}`);

        return response;
    }

    static async deleteHubspotRetryEntry(entries){
        const response = await request(`/tools/delete-hubspot-retry-entries`, {
            method: 'POST',
            data: entries,
        });
        return response;
    }

    static async retryHubspotRetryEntry(entries){
        const response = await request(`/tools/retry-hubspot-retry-entries`, {
            method: 'POST',
            data: entries,
        });
        return response.status;
    }

    static async downloadNachaFile(date) {
        await downloadReport(`/loans/daily-processing/nacha/${encodeURIComponent(formatDate(date))}`, 'Nacha');
    }

    static async downloadNachaReturnsFile(date) {
        await downloadReport(`/loans/daily-processing/nacha-returns/${encodeURIComponent(formatDate(date))}`, 'Nacha');
    }

    static async downloadFundingRequestReport(date, lender) {
        const formattedDate = new Date(date).toISOString().slice(0, 10);
        downloadReport(`/loans/daily-processing/${encodeURIComponent(formattedDate)}/${encodeURIComponent(lender)}/funding-request/download`, 'Funding Request');
    }

    static async downloadHoldsReport(date) {
        downloadReport(`/loans/daily-processing/${encodeURIComponent(formatDate(date))}/holds/download`, 'Holds');
    }

    static async downloadReturnsReport(date) {
        downloadReport(`/loans/daily-processing/${encodeURIComponent(formatDate(date))}/returns/download`, 'Returns');
    }

    static async downloadPaymentsReport(date, processingPaymentsOnly) {
        const response = await request(`/loans/daily-processing/${encodeURIComponent(formatDate(date))}/payment/download`, {
            method: 'POST',
            data: {processingOnly: processingPaymentsOnly}
        });

        if (response.status === 200) {
            download(response.data, response.headers["x-filename"]);
            return response.data;
        } else if(response.status === 204) {
            return response.data;
        } else {
            throw new Error(`Server error downloading Payment file`);
        }
    }

    static async downloadCommentsFile(loanNumber) {
        downloadReport(`/loans/${loanNumber}/false/comments/download`, 'Comments', false);
    }

    static async downloadNLSFundingXML(date) {
        downloadReport(`/loans/daily-processing/${encodeURIComponent(formatDate(date))}/nls/download/funding`, 'XML', false);
    }

    static async downloadNLSPaymentXML(date) {
        downloadReport(`/loans/daily-processing/${encodeURIComponent(formatDate(date))}/nls/download/payment`, 'XML', false);
    }

    static async downloadPCGCommentsFile(loanNumber) {
        downloadReport(`/loans/${loanNumber}/true/comments/download`, 'PCG comments', false);
    }

    static async sendNLSFundingUpdate(date) {
        const formattedDate = formatDate(date);
        const response = await request(`/loans/daily-processing/${encodeURIComponent(formattedDate)}/nls/funding`);
        return response;
    }

    static async getSendNLSFundingUpdateError(date, errID) {
        const formattedDate = formatDate(date);
        const errorID = errID;
        const response = await request(`loans/daily-processing/${encodeURIComponent(formattedDate)}/nls/funding/error/${errorID}`);
        return response;
    }

    static async sendNLSPaymentUpdate(date) {
        const formattedDate = formatDate(date);
        const response = await request(`/loans/daily-processing/${encodeURIComponent(formattedDate)}/nls/payment`);
        return response;
    }

    static async getSendNLSPaymentUpdateError(date, errID) {
        const formattedDate = formatDate(date);
        const errorID = errID;
        const response = await request(`loans/daily-processing/${encodeURIComponent(formattedDate)}/nls/payment/error/${errorID}`);
        return response;
    }

    static async sendDailyProcessingNotifications(date) {
        const formattedDate = formatDate(date);
        const response = await request(`/loans/daily-processing/notification/${encodeURIComponent(formattedDate)}`);
        return response;
    }

    static async resendNotification(notificationId) {
        const response = await request(`/loans/daily-processing/notification/resend`, {
            method: 'POST',
            data: JSON.stringify({"notificationId": notificationId})
          });
        return response;
    }

    static async sendDailyFundingReport(date) {
        const formattedDate = formatDate(date);
        const response = await request(`/loans/daily-processing/funding/notification/${encodeURIComponent(formattedDate)}`);
        return response;
    }

    static async getAutoSendStatus() {
        const response = await request(`/loans/daily-processing/autosend/retrieve`);
        return response.data;
    }

    static async setAutoSendStatus(status, toggle) {
        const response = await request(`/loans/daily-processing/autosend/${encodeURIComponent(status)}/${encodeURIComponent(toggle)}`);
        return response;
    }

    static async holdFundingRequests(fundingRequestIds) {
        const response = await request('/loans/funding-request/hold', {
            method: 'POST',
            data: fundingRequestIds
          });

          return response.data;
    }

    static async clearFundingRequests(fundingRequestIds) {
        const response = await request('/loans/funding-request/clear', {
            method: 'POST',
            data: fundingRequestIds
          });

          return response.data;
    }
    static async syncLoanUpdate(loanNumber)
    {
        const response = await request('/tools/sync-loan-update', {
            method: 'POST',
            data: [loanNumber]
        });
        return response;
    }
    static async syncPaymentFRReturnWithHubspot(loanNumber) {
        const response = await request(`/tools/sync-payment-fr-return-with-hubspot`,{
            method: 'POST',
            data: [loanNumber]
        });
        return response;
    }
    static async holdPayments(paymentIds) {
        const response = await request('/loans/payment/hold', {
            method: 'POST',
            data: paymentIds
        });

        return response.data;
    }

    static async clearPayments(paymentIds) {
        const response = await request('/loans/payment/clear', {
            method: 'POST',
            data: paymentIds
        });

        return response.data;
    }

    static async releaseHolds(loanNumbers) {
        const response = await request('/loans/holds/release', {
            method: 'POST',
            data: [loanNumbers]
        });

        return response.data;
    }

    static async bulkReleaseHolds(loanNumbers) {
        const response = await request('/loans/holds/release/bulk', {
            method: 'POST',
            data: loanNumbers
        });

        return response.data;
    }

    static async cancelHolds(loanNumber) {
        const response = await request('/loans/holds/cancel', {
            method: 'POST',
            data: [loanNumber]
        });

        return response.data;
    }

    static async markPaymentsAsProcessing(paymentIds) {
        const response = await request('/loans/payment/mark-as-processing', {
            method: 'POST',
            data: paymentIds
        });

        return response.data;
    }

    static async markHoldsAsProcessing(holdsIds) {
        const response = await request('/loans/holds/mark-as-processing', {
            method: 'POST',
            data: holdsIds
        });

        return response.data;
    }

    static async pullFundingRequests(date) {
        const formattedDate = formatDate(date);
        const response = await request(`/loans/daily-processing/${encodeURIComponent(formattedDate)}/funding-request/pull`);
        return response.data;
    }

    static async pullReturns(date) {
        const formattedDate = formatDate(date);
        const response = await request(`/loans/daily-processing/${encodeURIComponent(formattedDate)}/returns/pull`);
        return response.data;
    }

    static async newFundingRequest(fundingRequest){
        const response = await request('/loans/funding-request/new/enhanced', {
            method: 'POST',
            data: JSON.stringify(fundingRequest)
        });

        return response;
    }

    static async deleteFundingRequests(fundingRequestIds){
        const response = await request('/loans/funding-request/delete', {
            method: 'POST',
            data: fundingRequestIds
        });

        return response.data;
    }

    static async deleteReturns(returnIds){
        const response = await request('/loans/return/delete', {
            method: 'POST',
            data: returnIds
        });

        return response.data;
    }

    static async getLatestPullHistoryByDate(date) {
        const formattedDate = formatDate(date);
        const response = await request(`/loans/daily-processing/${encodeURIComponent(formattedDate)}/pull-operation/latest`);
        return response;
    }

    static async getPullHistoryByDate(date) {
        const formattedDate = formatDate(date);
        const response = await request(`/loans/daily-processing/${encodeURIComponent(formattedDate)}/pull-operation/history`);
        return response;

    }

    static async getLoanAuditLog(typeId, historyType) {
        const type = historyType.replaceAll(' ', '-').toLowerCase();
        const response = await request(`/loans/${type}/${typeId}/audit-log`);

        return response;
    }

    static async pullPayments(date) {
        const formattedDate = formatDate(date);
        const response = await request(`/loans/daily-processing/${encodeURIComponent(formattedDate)}/payment/pull`);
        return response.data;
    }

    static async newPayment(payment) {
        const response = await request('/loans/payment/new/enhanced', {
          method: 'POST',
          data: JSON.stringify(payment)
        });

        return response;
    }

    static async deletePayments(paymentIds) {
        const response = await request(`/loans/payment/delete`, {
            method: 'PATCH',
            data: paymentIds
        });

        return response.data;
    }

    static async newReturn(returns) {
        const response = await request('/loans/returns/new', {
          method: 'POST',
          data: JSON.stringify(returns)
        });

        return response;
    }

    static async updatePaymentAmount(paymentId, newAmount) {
        const response = await request(`/loans/payment/${paymentId}/update/amount`, {
            method: 'PATCH',
            data: JSON.stringify({amount: newAmount})
        });

        return response;
    }

    static async updateHoldComment(loanId, newValue) {
        const response = await request(`/loans/${loanId}/update/hold/comment`, {
            method: 'PATCH',
            data: JSON.stringify({comment: newValue})
        });

        return response;
    }

    static async updatePaymentNotify(paymentId, newValue) {
        const response = await request(`/loans/payment/${paymentId}/update/notify`, {
            method: 'PATCH',
            data: JSON.stringify({notify: newValue})
        });

        return response;
    }

    static async updateReturnNotify(returnItemId, newValue) {
        const response = await request(`/loans/returns/${returnItemId}/update/notify`, {
            method: 'PATCH',
            data: JSON.stringify({notify: newValue})
        });

        return response;
    }
    static async updateReturnPrincipleReduction(returnItemId, newValue) {
        const response = await request(`/loans/returns/${returnItemId}/update/PR`, {
            method: 'PATCH',
            data: JSON.stringify({pr: newValue})
        });
        return response;
    }

    static async updateFundingRequestNotes(fundingRequestId, newValue) {
        const response = await request(`/loans/funding-request/${fundingRequestId}/notes`, {
            method: 'PATCH',
            data: JSON.stringify({notes: newValue})
        });

        return response;
    }

    static async updateFundingRequestProjectType(fundingRequestId, newValue) {
        const response = await request(`/loans/funding-request/${fundingRequestId}/project-type`, {
            method: 'PATCH',
            data: JSON.stringify({projectType: newValue})
        });

        return response;
    }

    static async updatePaymentNotes(paymentId, newValue) {
        const response = await request(`/loans/payment/${paymentId}/notes`, {
            method: 'PATCH',
            data: JSON.stringify({notes: newValue})
        });

        return response;
    }

    static async getHeldLoans() {
        const response = await (request('/loans/holds'));

        return response;
    }
    static async getHeldLoanSummary()
    {
        const response = await (request('/loans/holds/summary'))

        return response;
    }
    static async newHold(loanNumber, holdComment) {
        const response = await request('/loans/hold', {
            method: 'POST',
            data: JSON.stringify({loanNumber: loanNumber, holdComment: holdComment})
        });

        return response;
    }

    static async syncNLSHolds(holdQueue, clearQueue) {
        const response = await request('/loans/hold/sync', {
            method: 'POST',
            data: JSON.stringify({holdQueue: holdQueue, clearQueue: clearQueue})
        });

        return response;
    }

    static async getLoanHoldHistory(loanNumber) {
        const response = await request(`/loans/${loanNumber}/hold-history`);

        return response.data;
    }

    static async getLoanNotifications(loanNumber) {
        const response = await request(`/loans/${loanNumber}/notification`);

        return response;
    }

    static async getDashboardMetrics() {
        const response = await request('/dashboard');

        return response.data;
    }

    static async newLoanComment(loanNumber, comment, commentCategory) {
        const response = await request(`/loans/${loanNumber}/comment`, {
            method: 'POST',
            data: JSON.stringify({"comment": comment, "commentCategory": commentCategory})
        });

        return response;
    }

    static async deleteLoanComment(id) {
        const response = await request(`/loans/comment/delete`, {
            method: 'PATCH',
            data: JSON.stringify({commentId: id})
        });

        return response;
    }

    static async getLoanCommentsForLoan(loanNumber) {
        const response = await request(`/loans/${loanNumber}/comment`);

        return response.data;
    }

    static async getLoanPopupsForLoan(loanNumber) {
        const response = await request(`/loans/${loanNumber}/popup-comment`);

        return response.data;
    }

    static async getLoanDelinquenciesForLoan(loanNumber) {
        const response = await request(`/loans/${loanNumber}/delinquencies`);

        return response.data;
    }

    static async getLoanDelinquencyCommentsForLoan(loanNumber) {
        const response = await request(`/loans/${loanNumber}/delinquency/comment`);

        return response.data;
    }

    static async getNLSCommentsForLoan(loanNumber) {
        const response = await request(`/loans/${loanNumber}/nls/comment`);

        return response;
    }

    static async forceCompletePayment(loanNumber, forceCompleteStage, forceCompleteComment, amount) {
        const response = await request(`/loans/${loanNumber}/force-complete-payment`, {
            method: 'POST',
            data: JSON.stringify({"forceCompleteStage": forceCompleteStage, "forceCompleteComment": forceCompleteComment, "amount": amount})
        });

        return response;
    }

    static async updateLender(loanNumber, lenderId) {
        const response = await request(`/loans/${loanNumber}/update/lender`, {
            method: 'POST',
            data: JSON.stringify({"lenderId": lenderId})
        });

        return response;
    }

    static async updateLoanDealer(loanNumber, oldDealerNum, newDealerNum) {
        const response = await request(`/loans/${loanNumber}/update/dealer`, {
            method: 'POST',
            data: JSON.stringify({"oldDealerNum": oldDealerNum, "newDealerNum": newDealerNum})
        });
        return response;
    }

    static async getLoanMilestones(loanNumber) {
        const response = await request(`/loans/${loanNumber}/milestones`);
        return response;
    }

    static async executeFundingRules(date) {
        const formattedDate = formatDate(date);

        const response = await request(`/loans/daily-processing/rules/${encodeURIComponent(formattedDate)}/execute`);

        return response.data;
    }

    static async markPendingRelease(loanNumber) {
        const response = await request(`/loans/${loanNumber}/update/pending-release`, {
            method: 'POST',
        });

        return response;
    }

    static async removePendingRelease(loanNumber) {
        const response = await request(`/loans/${loanNumber}/update/remove-pending-release`, {
            method: 'POST',
        });

        return response;
    }

    static async togglePaymentBounce(transactionId, bounced, isReturn) {
        const response = await request(`/loans/${isReturn ? `returns` : `payment`}/bounce`, {
            method: 'POST',
            data: JSON.stringify({"transactionId": transactionId, "bounced": bounced})
          });
          return response;
    }

    static async togglePaymentReturn(paymentId, principalReduction) {
        const response = await request(`/loans/payment/return`, {
            method: 'POST',
            data: JSON.stringify({"paymentId": paymentId, "principalReduction": principalReduction})
          });
          return response;
    }

    static async downloadNotificationPreview(date) {
        const formattedDate = new Date(date).toISOString().slice(0, 10);
        const response = await request(`/loans/daily-processing/${encodeURIComponent(formattedDate)}/notification-preview/download`);
        if (response.status === 200) {
            download(response.data, response.headers["x-filename"]);
            return response.data;
        } else {
            throw new Error("Server error downloading notification preview");
        }
    }

    static async downloadNLSHoldRelease() {
        const response = await request(`/loans/daily-processing/nls-hold-release/download`);
        if (response.status === 200) {
            download(response.data, response.headers["x-filename"]);
            return response.data;
        } else {
            throw new Error("Server error downloading NLS Hold Release XML");
        }
    }

    static async getSecondStagedFR(fundRequestData, date){
        const formattedDate = formatDate(date);
        const response = await request(`/loans/daily-processing/${encodeURIComponent(formattedDate)}/get-second-staged-funding`);

        const centsToDollar = (cents) => {
            return `${(cents / 100).toFixed(2).toString()}`;
        }

        if (response.status === 200){
            const responseLst = response.data.data;
            let result = "Loan Number, Number of Stages, Second Stage Payment, Final Stage Payment\n";
            for (let i = 0;  i < responseLst.length; i++){
                result += `${responseLst[i].loanNumber}, `;
                result += `${responseLst[i].numberOfstages},  `;
                result += `${centsToDollar(responseLst[i].secondStage)}, `;
                result += `${centsToDollar(responseLst[i].finalStage)}\n`;
            }
            download(result, `s2f-report_${formattedDate}.csv`);
        }
    }

    static async getDeletedFRPR(loanNumber){
        const response = await request(`/loans/${loanNumber}/get-funding-requests-payments-returns`);
        if (response.status === 200) {
            console.log(response.data.data);
            return response;
        } else {
            return false;
        }
    }

    static async setReturnProcessed(id) {
        const response = await request(`/loans/returns/processed`, {
            method: 'POST',
            data: JSON.stringify({'id': id})
          });
          return response;
    }
}

import React, { Fragment, memo, useState } from 'react';
import MUIDataTable from 'mui-datatables';
import CustomSearchBar from '../../components/CustomSearchBar';
import EditableTextCell from './EditableTextCell';
import { LoanService } from '../../services';
import { centsToDollar, sortDollarColumn, aprToPercent, promosToChips } from '../../utils/formatters';
import { isHeldCell, dealerNeedsReviewCell, isConciergeCell, isRuleFailureCell } from '../../utils/cellRenderers';
import { saveTableSession, getTableSession } from '../../utils/sessionHelpers';
import { customTableSearch } from '../../utils/tableHelpers.js';
import { FlagCell, DealerMetricTooltip, LoanMetricTooltip, BorrowerMetricTooltip, CommentPreviewTooltip, LoanTypeIcon, FincenIdCell } from '../../components';
import AddHoldRequestButtons from './AddHoldRequestButtons';
import RepullButton from './RepullButton';
import { Tooltip } from '@material-ui/core';
import AddIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";
import { useRole } from '../../contexts/RolesContext';
import { useNotification } from '../../contexts/NotificationContext';
import ConfirmationModal from '../../components/ConfirmationModal';
import DeleteFundingRequestsButton from './DeleteFundingRequestsButton';
import DownloadButton from './DownloadButton';

const tablifyFundingRequests = fundingRequests => fundingRequests == null ? null : fundingRequests.map(fundingRequest => {
    return [
        fundingRequest.id,
        fundingRequest.loanNumber,
        fundingRequest.dealer.number,
        fundingRequest.dealer.name,
        centsToDollar(fundingRequest.amount),
        (fundingRequest.hasStagedFunding === true ? 'Yes' : 'No'),
        fundingRequest.isHeld,
        fundingRequest.dealer.organization.name,
        fundingRequest.commentCount,
        fundingRequest.dealer.needsReview,
        fundingRequest.dealer.isManuallyFlagged,
        fundingRequest.lender.shortName,
        fundingRequest.applicant.fullName,
        fundingRequest.dealer?.isConcierge,
        fundingRequest.notes,
        (isNaN(parseInt(fundingRequest.dealer.totalFundedLoans)) ? 0 : parseInt(fundingRequest.dealer.totalFundedLoans)),
        fundingRequest.dealer.maxLoanAmount,
        fundingRequest.dealer.totalLoanAmount,
        fundingRequest.dealer.averageLoanAmount,
        centsToDollar(fundingRequest.estimatedProjectAmount),
        centsToDollar(fundingRequest.totalLoanAmount),
        fundingRequest.termMonths,
        aprToPercent(fundingRequest.apr),
        fundingRequest.dealer?.manualFlaggedReason,
        fundingRequest.dealer?.firstFundingDate,
        fundingRequest.comingOffHold ? "Yes" : "No",
        fundingRequest.holdComment,
        fundingRequest.dealer.numberOfActiveLoans,
        fundingRequest.dealer.numberOfDelinquentLoans,
        promosToChips(fundingRequest),
        fundingRequest.projectType,
        fundingRequest.applicant.creditScore,
        fundingRequest.applicant.employer,
        aprToPercent(fundingRequest.applicant.debtIncomeRatio),
        centsToDollar(fundingRequest.applicant.totalIncome),
        fundingRequest.coapplicant.fullName,
        fundingRequest.coapplicant.employer,
        fundingRequest.coapplicant.creditScore,
        fundingRequest.applicationDate,
        fundingRequest.dealer.lastFundingDate,
        fundingRequest.failureCount ? fundingRequest.failureCount : 0,
        fundingRequest.loanType,
        fundingRequest.applicant.address,
        fundingRequest.dealer?.numberOfOverThirtyDayDelinquentLoans,
        fundingRequest.comments,
        fundingRequest.dealer?.hasFincenId,
    ];
});

const FundingRequestsTable = ({ fundingRequests, selectedDate, toggleShowNewFundingRequest }) => {
    const [selectedRowIndices, setSelectedRowsIndices] = useState([]);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const { isDailyProcessingWrite, isAdmin } = useRole();
    const { createErrorNotification } = useNotification();
    const starterColumns = ['Loan ID', 'Dealer #', 'Dealer', 'Amount', 'Staged Funding', 'Payment Stage', 'Completed Payments', 'CU Pull', 'Org', 'On Hold?','Dealer Account Flag', 'Manual Flag', 'FinCEN ID', 'Lender', 'Notes', 'Total Funded Loans', 'Loan Type'];
    const [visibleColumns, setVisibleColumns] = useState((sessionStorage.getItem("fundingRequestsTable")) ? getTableSession("fundingRequestsTable") : saveTableSession(starterColumns, "fundingRequestsTable"));
    const [matchExactText, setmatchExactText] = useState(true);
    const [rowsPerPage, setRowsPerPage] = useState(50);

    const handleMatchExactText = (event) => {
        setmatchExactText(event.target.checked);
    }

    const handleAddFundingRequestClick = () => {
        toggleShowNewFundingRequest();
    };

    const handlePullClick = () => {
        LoanService.pullFundingRequests(selectedDate).then(response => {
            // !FIXME: Do this better!
            setTimeout(() => window.location.reload(), 10000);
        });
        toggleConfirmation();
    };

    const toggleConfirmation = () => {
        setShowConfirmation(!showConfirmation);
    }

    const columns = [
        {
            name: 'Funding Request ID',
            options: {
                filter: false,
                display: false,
                viewColumns: false,
            }
        },
        {
            name: 'Loan ID',
            options: {
                filter: false,
                display: visibleColumns ? visibleColumns.includes('Loan ID') : true,
                customBodyRender: (value, tableMeta) => {
                    return <React.Fragment>
                        <div style={{display: 'flex'}}>
                            <LoanMetricTooltip
                                loanId={value}
                                estimatedProjectAmount={tableMeta.rowData[19]}
                                totalLoanAmount={tableMeta.rowData[20]}
                                loanTerm={tableMeta.rowData[21]}
                                loanApr={tableMeta.rowData[22]}
                                promos={tableMeta.rowData[29]}
                                projectType={tableMeta.rowData[30]}
                                applicationDate={tableMeta.rowData[38]}
                            />
                            <BorrowerMetricTooltip
                                borrowerName={tableMeta.rowData[12]}
                                borrowerCreditScore={tableMeta.rowData[31]}
                                borrowerDTI={tableMeta.rowData[33]}
                                borrowerAddress={tableMeta.rowData[42]}
                                borrowerEmployer={tableMeta.rowData[32]}
                                borrowerIncome={tableMeta.rowData[34]}
                                coborrowerName={tableMeta.rowData[35]}
                                coborrowerCreditScore={tableMeta.rowData[37]}
                                coborrowerEmployer={tableMeta.rowData[36]}
                            />
                        </div>
                    </React.Fragment>
                }
            }
        },
        {
            name: 'Dealer #',
            options: {
                display: visibleColumns ? visibleColumns.includes('Dealer #') : true,
                filter: false,
                customBodyRender: (value, tableMeta) => {
                    return <a href={`/dealers/${value}`}>{value}</a>
                }
            }
        },
        {
            name: 'Dealer',
            options: {
                display: visibleColumns ? visibleColumns.includes('Dealer') : true,
                customBodyRender: (value, tableMeta) => {
                    return <DealerMetricTooltip
                        dealerName={value}
                        totalFundedLoans={tableMeta.rowData[15]}
                        maxLoanAmount={centsToDollar(tableMeta.rowData[16])}
                        totalLoanAmount={centsToDollar(tableMeta.rowData[17])}
                        averageLoanAmount={centsToDollar(tableMeta.rowData[18])}
                        firstFundingDate={tableMeta.rowData[24]}
                        lastFundingDate={tableMeta.rowData[39]}
                        dealerConcierge={tableMeta.rowData[13] ? "Yes" : "No"}
                        dealerDelinquentLoans={tableMeta.rowData[28] == null ? 0 : tableMeta.rowData[28]}
                        dealerThirtyDayDelinquentLoansCount={tableMeta.rowData[43] == null ? 0 : tableMeta.rowData[43] }
                    />
                }
            }
        },
        {
            name: 'Amount',
            options: {
                display: visibleColumns ? visibleColumns.includes('Amount') : true,
                filter: false,
                sortCompare: sortDollarColumn
            }
        },
        {
            name: 'Staged Funding',
            options: {
                display: visibleColumns ? visibleColumns.includes('Staged Funding') : true,
                customFilterListOptions: {
                    render: v => v === "No" ? "Non-Staged Funded" : "Staged Funded",
                },
            }
        },
        {
            name: 'On Hold?',
            options: {
                display: visibleColumns ? visibleColumns.includes('On Hold?') : true,
                customBodyRender: isHeldCell,
                customFilterListOptions: {
                    render: v => v ? "On Hold" : "Not On Hold",
                },
            }
        },
        {
            name: 'Org',
            options: {
                display: visibleColumns ? visibleColumns.includes('Org') : true,
            }
        },
        {
            name: 'Comments',
            options: {
                filter: false,
                display: visibleColumns ? visibleColumns.includes('Comments') : true,
                customBodyRender: (value, tableMeta) => {
                    return (parseInt(value) === 0) ? null : (<CommentPreviewTooltip count={value} comments={tableMeta.rowData[44]}  />)
                }
            }
        },
        {
            name: 'Dealer Account Flag',
            options: {
                display: visibleColumns ? visibleColumns.includes('Dealer Account Flag') : true,
                customBodyRender: dealerNeedsReviewCell,
                customFilterListOptions: {
                    render: v => v ? "Dealer Flagged" : "Dealer Not Flagged",
                },
            }
        },
        {
            name: 'Manual Flag',
            options: {
                display: visibleColumns ? visibleColumns.includes('Manual Flag') : true,
                // Note: dealerNumber comes from diff column on each table...that's why this isn't in cellRenderers
                customBodyRender: (value, tableMeta) => {
                    return (
                        <FlagCell
                            value={value}
                            dealerNumber={tableMeta.rowData[2]}
                            flagReason={tableMeta.rowData[23]}
                            disableUpdates={!isDailyProcessingWrite()}
                        />
                    );
                },
                customFilterListOptions: {
                    render: v => v ? "Manual Flagged" : "Non-Manual Flagged",
                },
            }
        },
        {
            name: 'Lender',
            options: {
                display: visibleColumns ? visibleColumns.includes('Lender') : true,
            }
        },
        {
            name: 'Borrower',
            options: {
                display: visibleColumns ? visibleColumns.includes('Borrower') : true,
            }
        },
        {
            name: 'Concierge Dealer',
            options: {
                filter: false,
                display: visibleColumns ? visibleColumns.includes('Concierge Dealer') : true,
                customBodyRender: isConciergeCell,
            }
        },
        {
            name: 'Notes',
            options: {
                setCellProps: () => ({ style: { minWidth: "250px" }}),
                display: visibleColumns ? visibleColumns.includes('Notes') : true,
                customBodyRender: (value, tableMeta) => {
                    return (
                        isDailyProcessingWrite() || isAdmin() ?
                        <EditableTextCell
                            value={value}
                            id={tableMeta.rowData[0]}
                            label={'Funding Request Notes'}
                            onSave={LoanService.updateFundingRequestNotes} /> : value
                    );
                }
            }
        },
        {
            name: 'Total Funded Loans',
            options: {
                filter: false,
                display: visibleColumns ? visibleColumns.includes('Total Funded Loans') : true,
            }
        },
        {
            name: 'maxLoanAmount',
            options: {
                filter: false,
                display: false,
                viewColumns: false,
            }
        },
        {
            name: 'totalLoanAmount',
            options: {
                filter: false,
                display: false,
                viewColumns: false,
            }
        },
        {
            name: 'averageLoanAmount',
            options: {
                filter: false,
                display: false,
                viewColumns: false,
            }
        },
        {
            name: 'estimatedProjectAmount',
            options: {
                filter: false,
                display: false,
                viewColumns: false,
            }
        },
        {
            // This comes from loan table not FR table
            name: 'hiddenTotalLoanAmount',
            options: {
                filter: false,
                display: false,
                viewColumns: false,
            }
        },
        {
            name: 'term',
            options: {
                filter: false,
                display: false,
                viewColumns: false,
            }
        },
        {
            name: 'apr',
            options: {
                filter: false,
                display: false,
                viewColumns: false,
            }
        },
        {
            name: 'Manual Flag Reason',
            options: {
                filter: false,
                display: visibleColumns ? visibleColumns.includes('Manual Flag Reason') : true,
                viewColumns: true
            }
        },
        {
            name: 'Dealer First Funding Date',
            options: {
                filter: false,
                display: false,
                viewColumns: false
            }
        },
        {
            name: 'Coming Off Hold',
            options: {
                display: visibleColumns ? visibleColumns.includes('Coming Off Hold') : true,
                customFilterListOptions: {
                    render: v => v === "No" ? "Not Coming off Hold" : "Coming off Hold",
                },
            }
        },
        {
            name: 'Hold Comment',
            options: {
                filter: false,
                display: visibleColumns ? visibleColumns.includes('Hold Comment') : true,
            }
        },
        {
            name: 'Dealer Loan Number',
            options: {
                filter: false,
                display: false,
                viewColumns: false
            }
        },
        {
            name: 'Dealer Delinquency Number',
            options: {
                filter: false,
                display: false,
                viewColumns: false
            }
        },
        {
            name: 'Promos',
            options: {
                filter: false,
                display: false,
                viewColumns: false
            }
        },
        {
            name: 'Project Type',
            options: {
                filter: false,
                display: visibleColumns ? visibleColumns.includes('Project Type') : true,
                viewColumns: true,
                setCellProps: () => ({ style: { minWidth: "250px" }}),
                customBodyRender: (value, tableMeta) => {
                    return ( (isDailyProcessingWrite() || isAdmin()) ?
                        <EditableTextCell
                        value={value}
                        id={tableMeta.rowData[0]}
                        label={'Project Type'}
                        onSave={LoanService.updateFundingRequestProjectType} /> : value
                    );
                }
            }
        },
        {
            name: 'borrowerCreditScore',
            options: {
                filter: false,
                display: false,
                viewColumns: false
            }
        },
        {
            name: 'borrowerEmployer',
            options: {
                filter: false,
                display: false,
                viewColumns: false
            }
        },
        {
            name: 'borrowerDTI',
            options: {
                filter: false,
                display: false,
                viewColumns: false
            }
        },
        {
            name: 'borrowerIncome',
            options: {
                filter: false,
                display: false,
                viewColumns: false
            }
        },
        {
            name: 'Coborrower',
            options: {
                filter: false,
                display: false,
                viewColumns: false
            }
        },
        {
            name: 'coborrowerEmployer',
            options: {
                filter: false,
                display: false,
                viewColumns: false
            }
        },
        {
            name: 'coborrowerCreditScore',
            options: {
                filter: false,
                display: false,
                viewColumns: false
            }
        },
        {
            name: 'Application Date',
            options: {
                filter: false,
                display: visibleColumns ? visibleColumns.includes('Application Date') : true,
                viewColumns: true
            }
        },
        {
            name: 'Dealer Last Funding Date',
            options: {
                filter: false,
                display: false,
                viewColumns: false
            }
        },
        {
            name: 'Rules Failed',
            options: {
                filter: false,
                display: visibleColumns ? visibleColumns.includes('Rules Failed') : true,
                viewColumns: true,
                customBodyRender: isRuleFailureCell,
            }
        },
        {
            name: 'Loan Type',
            options: {
                filter: true,
                display: visibleColumns ? visibleColumns.includes('Loan Type') : true,
                sort: false,
                viewColumns: true,
                filterOptions: {
                    names: ['Home Improvement', 'Healthcare', 'Solar'],
                },
                customBodyRender: (value) => {
                    return(
                        <div style={{cursor: 'default'}}>
                            <LoanTypeIcon loanType={value} />
                        </div>
                    )
                },
            }
        },
        {
            name: 'Borrower Address',
            options: {
                filter: false,
                display: false,
                viewColumns: false
            }
        },
        {
            name: 'Delinquent for Over 30 Days Loan Count',
            options: {
                filter: false,
                display: false,
                viewColumns: false
            }
        },
        {
            name: 'Comment Messages',
            options: {
                filter: false,
                display: false,
                viewColumns: false
            }
        },
        {
            name: 'FinCEN ID',
            options: {
                filter: false,
                display: visibleColumns ? visibleColumns.includes('FinCEN ID') : true,
                customBodyRender: (value, tableMeta) => {
                    return <FincenIdCell value={tableMeta.rowData[45]} disableUpdates={true} />
                },
                customFilterListOptions: {
                    render: v => v ? "With FinCEN ID" : "Without FinCEN ID",
                },
            }
        },
    ];

    const options = {
        filterType: 'dropdown',
        print: false,
        download: isDailyProcessingWrite(),
        onDownload: () => {
            LoanService.downloadFundingRequestReport(selectedDate, 'AHFCU').then(data => {}).catch(error => {
                createErrorNotification('Failed to Download Funding Requests');
            });
            LoanService.downloadFundingRequestReport(selectedDate, 'VSCU').then(data => {}).catch(error => {
                createErrorNotification('Failed to Download Funding Requests');
            });
            LoanService.downloadFundingRequestReport(selectedDate, 'VSCU-ESG').then(data => {}).catch(error => {
                createErrorNotification('Failed to Download Funding Requests');
            });
            LoanService.downloadFundingRequestReport(selectedDate, 'CWFCU').then(data => {}).catch(error => {
                createErrorNotification('Failed to Download Funding Requests');
            });
            LoanService.downloadFundingRequestReport(selectedDate, 'FCC').then(data => {}).catch(error => {
                createErrorNotification('Failed to Download Funding Requests');
            });

            return false;
        },
        searchOpen: true,
        rowsPerPage: rowsPerPage,
        rowsPerPageOptions: [50,100,250],
        setRowProps: (row, dataIndex, rowIndex) => {
            if (rowIndex % 2 === 0) {
                return {
                    style: { background: 'snow' }
                };
            }
        },
        downloadOptions: {
            separator: '\t',
            filterOptions: {
                useDisplayedColumnsOnly: true,
                useDisplayedRowsOnly: true
            }
        },
        rowsSelected: selectedRowIndices,
        onRowSelectionChange: (rowsSelected, allRows) => {
            setSelectedRowsIndices(allRows.map(row => row.dataIndex));
        },
        onTableChange: (action, tableState) => {
            switch (action) {
                case 'viewColumnsChange':
                    let displayedVisibleColumns = tableState.columns.map(column => {
                        if (column.display === "true") {
                            return column.name;
                        } else {
                            return null;
                        }
                    }).filter(column => column !== null);

                    if (sessionStorage.getItem("fundingRequestsTable")) {
                        saveTableSession(displayedVisibleColumns, "fundingRequestsTable");
                    }
                    setVisibleColumns(displayedVisibleColumns);

                    break;
                default:
                    break;
            }
        },
        selectToolbarPlacement: 'none',
        textLabels: {
            toolbar: {
                downloadCsv: 'Download Lender Reports',
            },
        },
        customToolbar: () => {
            return isDailyProcessingWrite() ? (
                <Fragment>
                    <DownloadButton title={'Download S2F Report'} onClick={downloadS2F}/>
                    <RepullButton hasItems={fundingRequests.length > 0} pullName={'Funding Requests'} onClick={toggleConfirmation}/>
                    {selectedRowIndices.length > 0 ? (
                        <Fragment>
                            <AddHoldRequestButtons selectedRowIndices={selectedRowIndices} potentialHolds={fundingRequests} isFundingRequests={true} />
                            <DeleteFundingRequestsButton selectedRowIndices={selectedRowIndices} potentialDeletions={fundingRequests} date={selectedDate}  />
                        </Fragment>
                    ) : (
                        <Tooltip title={'Add a new funding request'}>
                            <IconButton onClick={handleAddFundingRequestClick}>
                                <AddIcon/>
                            </IconButton>
                        </Tooltip>
                    )}
                </Fragment>
            ) : null;
        },
        customSearchRender: (searchText, handleSearch, hideSearch, options) => {
            return (
                <CustomSearchBar
                    searchText={searchText}
                    handleSearch={handleSearch}
                    hideSearch={hideSearch}
                    options={options}
                    matchExactText={matchExactText}
                    handleMatchExactText={handleMatchExactText}
                />
            );
        },
        customSearch: (searchQuery, currentRow, columns) => {
            return customTableSearch(searchQuery, currentRow, columns, matchExactText);
        },
        onChangeRowsPerPage: (rowNumber) => {
            setRowsPerPage(rowNumber);
        },
    };

    const downloadS2F = () => {
        const fundingRequestData = tablifyFundingRequests(fundingRequests);
        const stagedFR = [];
        for (let i = 0; i < fundingRequestData.length; i++){
            if (fundingRequestData[i][5] === 'Yes'){
                stagedFR.push(fundingRequestData[i][1]);
            }
        }
        LoanService.getSecondStagedFR(stagedFR, selectedDate);
    };

    return fundingRequests === null ? (
        <h1>Loading...</h1>
    ) : (
        <Fragment>
            <ConfirmationModal title="Confirm" message="Are you sure you want to pull funding requests?"  showConfirmationBox={showConfirmation} onConfirm={handlePullClick} onCancel={toggleConfirmation}/>
            <MUIDataTable data={tablifyFundingRequests(fundingRequests)} columns={columns} options={options} />
        </Fragment>
    );
}

export default memo(FundingRequestsTable);

import { request } from './utils';

export default class UserService {
    static async getAll() {
        const response = await request('/users');

        return response.data;
    }

    static async getRoles() {
        const response = await request('/users/roles');

        return response.data;
    }

    static async newUser(user) {
        const response = await request('/users/new', {
            method: 'POST',
            data: JSON.stringify(user)
        });
        return response;
    }

    static async updateActiveUser(username, active, email) {
        const response = await request(`/users/active/${active}`, {
            method: 'POST',
            data: JSON.stringify({"username": username, "active": active, "email": email})
        });
        return response;
    }

    static async updateUserRoles(rolesUpdateData) {
        const response = await request(`/users/update/roles`, {
            method: 'POST',
            data: rolesUpdateData
        });
        return response;
    }
}
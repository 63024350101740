import React, { Fragment, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { parseCentsToDisplay } from '../../utils/formatters';
import PaymentsTable from './PaymentsTable';
import NewPayment from './NewPayment';
import { CountAmountItem } from './CountAmountLineItem';

const styles = makeStyles((theme) => ({
    table: {
        marginTop: '30px',
        width: '85vw',
        marginRight: '5vw'
    },
    summaryContainer: {
        paddingLeft: '50px',
        paddingRight: '50px',
        width: '85vw'
      },
      summaryHeader: {
          textAlign: 'center'
      },
      summaryTotals: {
          paddingTop: '10px',
          paddingBottom: '10px'
      },
      summaryBody: {
        paddingTop: '10px',
        paddingBottom: '10px'
      },
      summary: {
        backgroundColor: theme.palette.grey.main,
        borderRadius: '5px',
        border: 'groove',
        borderColor: 'black',
        borderWidth: '0.5px',
      }
}));

const Payments = ({ dailyProcessingData, selectedDate }) => {
    const classes = styles();
    useTheme();
    const [showNewPayment, setShowNewPayment] = useState(false);

    if (dailyProcessingData === null) {
        return <h1>Loading...</h1>;
    }

    const paymentSummary = dailyProcessingData.processingPaymentsSummary;
    const payments = dailyProcessingData.processingPayments;

    const toggleShowNewPayment = () => setShowNewPayment(!showNewPayment);

    return (
      <Fragment>
        <Grid container direction="column">
            <Grid item className={classes.summaryContainer}>
                <h3 className={classes.summaryHeader}>Summary</h3>
                <Grid container direction="column" className={classes.summary}>
                    <Grid item>
                        <Grid container justifyContent="space-evenly" className={classes.summaryBody}>
                            <Grid item className={classes.summaryTotals}>
                                <li><b>Total # of payments: </b> {paymentSummary.totalCount || 0} </li>
                                <li><b>Total # of staged payments: </b> {paymentSummary.stagedCount || 0} </li>
                                <li><b>Total # of unstaged payments: </b> {paymentSummary.unstagedCount || 0} </li>
                            </Grid>
                            <Grid item className={classes.summaryTotals}>
                                <li><b>Total amount: </b>{parseCentsToDisplay(paymentSummary.totalAmount)} </li>
                                <li><b>Total staged amount: </b>{parseCentsToDisplay(paymentSummary.stagedAmount)} </li>
                                <li><b>Total unstaged amount: </b>{parseCentsToDisplay(paymentSummary.nsfAmount)} </li>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Divider/>
                    <Grid item>
                        <Grid container spacing={2} justifyContent="space-evenly" direction="row" className={classes.summaryTotals}>
                            <Grid>
                                <CountAmountItem label={"NSF count / amount"} count={paymentSummary.nsfCount} amount={paymentSummary.nsfAmount} />
                                <CountAmountItem label={"S1 count / amount"} count={paymentSummary.s1Count} amount={paymentSummary.s1Amount} />
                            </Grid>
                            <Grid>
                                <CountAmountItem label={"S2 count / amount"} count={paymentSummary.s2Count} amount={paymentSummary.s2Amount} />
                                <CountAmountItem label={"S3 count / amount"} count={paymentSummary.s3Count} amount={paymentSummary.s3Amount} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
                <Grid item className={classes.table}>
                    <PaymentsTable payments={payments} selectedDate={selectedDate} toggleShowNewPayment={toggleShowNewPayment} paymentType={'processingPayments'} /> :
                </Grid>
            </Grid>
            <NewPayment showNewPayment={showNewPayment} toggleShowNewPayment={toggleShowNewPayment} selectedDate={selectedDate} />
        </Fragment>
    );
}

export default Payments;

const { request } = require('./../services/utils');
const download = require("downloadjs");

exports.downloadReport = async (url, fileType, ensureContentPresent = true) => {
    const response = await request(url);
    if (response.status === 200) {
        download(response.data, response.headers["x-filename"]);
        return response.data;
    } else if(ensureContentPresent && response.status === 204) {
        return response.data;
    } else {
        throw new Error(`Server error downloading ${fileType} file`);
    }
}
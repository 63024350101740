import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import purple from '@material-ui/core/colors/purple';
import cyan from '@material-ui/core/colors/cyan';

export const LOAN_STATUSES = ['ACTIVE', 'VOIDED', 'CLOSED', 'RETURNED'];

export const getLoanStatusColor = (loanStatus) => {
    switch(loanStatus) {
        case 'closed':
            return cyan[600];
        case 'voided':
            return red[400];
        case 'returned':
            return purple[500];
        case 'active':
        default:
            return green[400];
    }
}

export const getNotificationCategory = (categoryCode) => {
    switch(categoryCode) {
        case 'internal_funding_daily_processing':
            return 'Internal Funding';
        case 'dealer_paid_daily_processing':
            return 'Merchant Payment';
        case 'dealer_return_daily_processing':
            return 'Merchant Return';
        case 'compliance_ach_update':
            return 'Compliance Update';
        default:
            return 'Uncategorized';
    }
}

export const commentCategories = [
    {value: "HomeOwner Contact"},
    {value: "Dealer Contact"},
    {value: "Loan Changes"},
    {value: "Funding Call"},
    {value: "Collections"},
    {value: "Hold"},
    {value: "Other"},
    {value: "Pop-up"},
];

export const dealerCommentCategories = [
    {value: "Flags"},
    {value: "Disputes"},
    {value: "AFCap"},
    {value: "Issues"},
    {value: "Other"},
    {value: "Pop-up"},
]

export const LOAN_HOLD_STATUS = {
    HOLD_FOR_FUNDING: "Hold for funding",
    HOLD_FOR_PAYMENT: "Hold for payment",
    RELEASE_FOR_FUNDING: "Release for funding",
    RELEASE_FOR_PAYMENT: "Release for payment",
    PENDING_RELEASE: "Pending release tomorrow",
}
/* eslint eqeqeq: "off" */
import React, { memo } from 'react';
import { centsToDollar, aprToPercent, sortDollarColumn, promosToChips } from '../../utils/formatters';
import EditableTextCell from './EditableTextCell';
import { LoanService } from '../../services';
import MUIDataTable from 'mui-datatables';
import Tooltip from "@material-ui/core/Tooltip";
import PlayCircleIcon from '@material-ui/icons/PlayCircleOutline';
import IconButton from "@material-ui/core/IconButton";
import { DealerMetricTooltip, LoanMetricTooltip, BorrowerMetricTooltip } from '../../components';
import { isHeldCell, dealerNeedsReviewCell } from '../../utils/cellRenderers';
import { FlagCell } from '../../components';
import { useRole } from '../../contexts/RolesContext';
import Chip from '@material-ui/core/Chip';
import red from '@material-ui/core/colors/red';

const RunRulesButton = ({ ruleFunction }) => {

  const handleRunRulesClick = () => {
      ruleFunction();
  }
  return (
        <Tooltip title={"Run Rules"}>
          <IconButton onClick={handleRunRulesClick}>
            <PlayCircleIcon/>
          </IconButton>
        </Tooltip>
  );
}


const getTableOptions = (ruleFunction) => {
  return {
      serverSide: true,
      filter: false,
      selectableRows: 'none',
      print: false,
      rowsPerPage: 50,
      rowsPerPageOptions: [50,100,250],
      download: false,
      sort: false,
      search: false,
      customToolbar: () => {
        return (
          <RunRulesButton ruleFunction={ruleFunction}/>
        );
      }
    }
};


const tablifyFailures = (ruleFailures, isLoading) =>
  ruleFailures?.map(ruleFailure => {
      return [
        ruleFailure.id,
        ruleFailure.loanNumber,
        ruleFailure.dealer?.number,
        ruleFailure.dealer?.name,
        centsToDollar(ruleFailure.amount),
        (ruleFailure.hasStagedFunding === true ? 'Yes' : 'No'),
        ruleFailure.isHeld,
        ruleFailure.dealer?.organization.name,
        ruleFailure.dealer?.needsReview,
        ruleFailure.lender?.shortName,
        ruleFailure.failureReasons,
        centsToDollar(ruleFailure.estimatedProjectAmount),
        centsToDollar(ruleFailure.loanAmount),
        ruleFailure.termMonths,
        aprToPercent(ruleFailure.apr),
        promosToChips(ruleFailure),
        ruleFailure.applicant?.fullName,
        ruleFailure.applicant?.creditScore,
        ruleFailure.applicant?.employer,
        centsToDollar(ruleFailure.applicant?.totalIncome),
        ruleFailure.applicant?.debtIncomeRatio,
        ruleFailure.coapplicant?.fullName,
        ruleFailure.coapplicant?.employer,
        ruleFailure.coapplicant?.creditScore,
        (isNaN(parseInt(ruleFailure.dealer?.totalFundedLoans)) ? 0 : parseInt(ruleFailure.dealer?.totalFundedLoans)),
        ruleFailure.dealer?.maxLoanAmount,
        centsToDollar(ruleFailure.dealer?.totalLoanAmount),
        ruleFailure.dealer?.averageLoanAmount,
        ruleFailure.dealer?.firstFundingDate,
        ruleFailure.dealer?.lastFundingDate,
        (isNaN(parseInt(ruleFailure.dealer?.numberOfDelinquentLoans)) ? 0 : parseInt(ruleFailure.dealer?.numberOfDelinquentLoans)),
        (isNaN(parseInt(ruleFailure.dealer?.numberOfOverThirtyDayDelinquentLoans)) ? 0 : parseInt(ruleFailure.dealer?.numberOfOverThirtyDayDelinquentLoans)),
        ruleFailure.dealer?.isConcierge,
        ruleFailure.notes,
        ruleFailure.dealer?.isManuallyFlagged,

      ];
  });

const RulesTable = ({ruleFilters, ruleFailures, selectedDate, ruleFunction}) => {

  const { isDailyProcessingWrite, isAdmin } = useRole();
  

  const columns = [
    {
      name: 'ID',
      options: {
        display: false,
        viewColumns: false,
      }
    },
    { 
      name: 'Loan ID',
      options: {
          filter: false,
          customBodyRender: (value, tableMeta) => {
            return <React.Fragment>
              <div style={{display: 'flex'}}>
                <LoanMetricTooltip
                  loanId={value}
                  estimatedProjectAmount={tableMeta.rowData[11]}
                  totalLoanAmount={tableMeta.rowData[12]}
                  loanTerm={tableMeta.rowData[13]}
                  loanApr={tableMeta.rowData[14]}
                  promos={tableMeta.rowData[15]}
                /><BorrowerMetricTooltip
                  borrowerName={tableMeta.rowData[16]}
                  borrowerCreditScore={tableMeta.rowData[17]}
                  borrowerDTI={tableMeta.rowData[20]}
                  borrowerEmployer={tableMeta.rowData[18]}
                  borrowerIncome={tableMeta.rowData[19]}
                  coborrowerName={tableMeta.rowData[21]}
                  coborrowerCreditScore={tableMeta.rowData[23]}
                  coborrowerEmployer={tableMeta.rowData[22]}
                />
              </div>
            </React.Fragment>
          }
      }
    },
    {
      name: 'Dealer #',
      options: {
        filter: false,
        customBodyRender: (value, tableMeta) => {
          return <a href={`/dealers/${value}`}>{value}</a>
        }
      }
    },
    {
      name: 'Dealer',
      options: {
        customBodyRender: (value, tableMeta) => {
          return <DealerMetricTooltip 
            dealerName={value} 
            totalFundedLoans={tableMeta.rowData[24]} 
            maxLoanAmount={centsToDollar(tableMeta.rowData[25])}
            totalLoanAmount={tableMeta.rowData[26]}
            averageLoanAmount={centsToDollar(tableMeta.rowData[27])}
            firstFundingDate={tableMeta.rowData[28]}
            lastFundingDate={tableMeta.rowData[29]}
            dealerConcierge={tableMeta.rowData[32] ? "Yes" : "No"}
            dealerDelinquentLoans={tableMeta.rowData[30]}
            dealerThirtyDayDelinquentLoansCount={tableMeta.rowData[31]}
          />
        }
      }
    },
    {
      name: 'Amount',
      options: {
          filter: false,
          sortCompare: sortDollarColumn,
      }
    },
    {
      name: 'Staged Funding',
    },
    {
      name: 'On Hold?',
      options: {
        customBodyRender: isHeldCell
      }
    },
    {
      name: 'Org',
    },
    {
      name: 'Dealer Account Flag',
      options: {
        customBodyRender: dealerNeedsReviewCell
      }
    },
    {
      name: 'Lender',
    },
    {
      name: 'Failures',
      options: {
        filterOptions: {
          filter: true,
          logic: (values, filters) => {
            return values.name === filters[0];
          }
        },
        customBodyRender: (failureList) => {
          return failureList.map((failure) => {
            return <Chip 
              label={failure?.toUpperCase()} 
              style={{fontWeight: 'bold', fontSize: '12px', backgroundColor: red[500]}}
            />
          })
        }
      }
    },
    {
      name: 'estimatedProjectAmount',
      options: {
        filter: false,
        display: false,
        viewColumns: false,
      }
    },
    {
      name: 'totalLoanAmount',
      options: {
        filter: false,
        display: false,
        viewColumns: false,
      }
    },
    {
      name: 'term',
      options: {
        filter: false,
        display: false,
        viewColumns: false,
      }
    },
    {
      name: 'apr',
      options: {
        filter: false,
        display: false,
        viewColumns: false,
      }
    },
    {
      name: 'promos',
      options: {
        filter: false,
        display: false,
        viewColumns: false,
      }
    },
    {
      name: 'Borrower',
      options: {
        filter: false,
        display: false,
        viewColumns: false,
      }
    },
    {
      name: 'Borrower credit score',
      options: {
        filter: false,
        display: false,
        viewColumns: false,
      }
    },
    {
      name: 'Borrower Employer',
      options: {
        filter: false,
        display: false,
        viewColumns: false,
      }
    },
    {
      name: 'totalIncome',
      options: {
        filter: false,
        display: false,
        viewColumns: false,
      }
    },
    {
      name: 'debtincomeratio',
      options: {
        filter: false,
        display: false,
        viewColumns: false,
      }
    },
    {
      name: 'Coborrower',
      options: {
        filter: false,
        display: false,
        viewColumns: false,
      }
    },
    {
      name: 'Coborrower employer',
      options: {
        filter: false,
        display: false,
        viewColumns: false,
      }
    },
    {
      name: 'Coborrower credit score',
      options: {
        filter: false,
        display: false,
        viewColumns: false,
      }
    },
    {
      name: 'totalFundedLoans',
      options: {
        filter: false,
        display: false,
        viewColumns: false,
      }
    },
    {
      name: 'maxLoanAmount',
      options: {
        filter: false,
        display: false,
        viewColumns: false,
      }
    },
    {
      name: 'totalLoanAmount',
      options: {
        filter: false,
        display: false,
        viewColumns: false,
      }
    },
    {
      name: 'averageLoanAmount',
      options: {
        filter: false,
        display: false,
        viewColumns: false,
      }
    },
    {
      name: 'firstFundingDate',
      options: {
        filter: false,
        display: false,
        viewColumns: false,
      }
    },
    {
      name: 'lastFundingDate',
      options: {
        filter: false,
        display: false,
        viewColumns: false,
      }
    },
    {
      name: 'Dealer Delinquencies',
      options: {
        filter: false,
        display: true,
        viewColumns: true,
        customBodyRender: (value, tableMeta) => {
          return `${value} (${tableMeta.rowData[24] == 0 ? 0 : Math.trunc((value / tableMeta.rowData[24]) * 100)}%)`;
        }
      }
    },
    {
      name: 'Dealer Delinquencies For 30+ Days:',
      options: {
        filter: false,
        display: true,
        viewColumns: true,
        customBodyRender: (value, tableMeta) => {
          return `${value} (${tableMeta.rowData[24] == 0 ? 0 : Math.trunc((value / tableMeta.rowData[24]) * 100)}%)`;
        }
      }
    },
    {
      name: 'concierge',
      options: {
        filter: false,
        display: false,
        viewColumns: false,
      }
    },
    {
      name: 'Notes',
      options: {
          setCellProps: () => ({ style: { minWidth: "250px" }}),
          display: true,
          customBodyRender: (value, tableMeta) => {
            return ( (isDailyProcessingWrite() || isAdmin()) ? 
            <EditableTextCell 
              value={value} 
              id={tableMeta.rowData[0]} 
              label={'Funding Request Notes'} 
              onSave={LoanService.updateFundingRequestNotes} /> : value
            );
        }
      }
    },
    {
      name: "Manual Flag",
      options: {
        customBodyRender: (value, tableMeta) => {
          return ( isDailyProcessingWrite() ? 
            <FlagCell value={value} dealerNumber={tableMeta.rowData[2]} flagReason={tableMeta.rowData[23]} /> :
            <FlagCell value={value} dealerNumber={tableMeta.rowData[2]} flagReason={tableMeta.rowData[23]} disableUpdates={true} />
          );
        }
      }
    }
  ];

    return ruleFailures == null ? (
        <h1>Loading...</h1>
      ) : (
        <MUIDataTable data={tablifyFailures(ruleFailures)} columns={columns} options={getTableOptions(ruleFunction)} />
      );
    
}

export default memo(RulesTable);
import { request } from './utils';
import download from "downloadjs";

export default class PaymentService {
    static async bulkInstantReleaseProcessingPayments(ids) {
        const response = await request('/payments/processing/release/bulk', {
            method: 'POST',
            data: ids
        });

        return response.data;
    }

    static async pullPaymentsWithoutVisionId() {
        const response = await request('/payments/without-vision-payment-id/download', {
            method: 'GET'
        });

        if (response.status === 200) {
            download(response.data, response.headers["x-filename"]);
        } else {
            throw new Error("Server error downloading Payment without Vision Id File");
        }
        return response;
    }
}

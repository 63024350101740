import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LensIcon from '@material-ui/icons/Lens';
import LensOutlinedIcon from '@material-ui/icons/LensOutlined';
import { Link } from 'react-router-dom';
import orange from '@material-ui/core/colors/orange';
import amber from '@material-ui/core/colors/amber';

const useStyles = makeStyles((theme) => ({
    backgroundIcon: {
      position: 'absolute',
      left: 2,
      top: -3,
      width: '52px',
      height: '52px',
      color: orange[100],
      
    },
    backgroundOutline: {
      position: 'absolute',
      left: 0,
      top: -5,
      width: '55px',
      height: '55px',
      color: amber[800],
      
    },
    logoIcon: {
      position: 'absolute',
      left: 10,
      top: 8,
      underline: 'none',
      userSelect: 'none'
    },
  }));

const P4Logo = (props) => {
  const classes = useStyles();

    return (
      <div>
        <LensIcon className={classes.backgroundIcon} />
        <LensOutlinedIcon className={classes.backgroundOutline} />
        <Link to="/lms/loans" className={classes.logoIcon}>
            <img
                id="brand-logo"
                alt="PowerPay"
                src={"/logo512.png"}
                
            />
        </Link>
    </div>
    )
}

export default P4Logo;


import React, { Fragment, useState, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import LoanHoldDiff from '../components/LoanHoldDiff';
import { LoanService } from '../services';
import { formatDateTime } from '../utils/formatters';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import InfoIcon from '@material-ui/icons/Info';
import orange from '@material-ui/core/colors/orange';
import ListItemText from '@material-ui/core/ListItemText';


const styles = makeStyles((theme) => ({
    history: {
        maxHeight: '60vh',
        overflowY: 'scroll'
    },
    commentDivider: {
        paddingLeft: '5px',
        paddingRight: '10px'
    },
    commentDate: {
        fontSize: '12px',
    },
    cutoverWarning: {
        backgroundColor: 'lightgoldenrodyellow',
        borderRadius: '10px',
        padding: '10px',
        marginTop: '10px',
    },
    hoverMessage: {
        fontSize: '16px',
        backgroundColor: 'transparent', 
        borderRadius: '10px',
        color: 'black',
        maxWidth: '100%',
        overflowX: 'hidden',
        textAlign: 'left'
    },
    summaryHeader: {
        fontSize: '16px',
        textAlign: 'center',
        textDecoration: 'underline',
    },
}));

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      minWidth: 500,
      fontSize: '12px',
      border: '1px solid #dadde9',
    },
  }))(Tooltip);

const AuditLogTooltip = (props) => {
  const [history, setHistory] = useState(null);
  const classes = styles();

  useEffect(() => {
    if (history === null) {
      LoanService.getLoanAuditLog(props.id, props.type).then(response => {
        if (response.status && response.status === 200) { 
          setHistory(response.data.history);
        } else {
          console.log(`Failed to retrieve ${props.type} History`);
        }
      });
    }
  }, [history, props.id, props.type]);
  
    return history === null ?
    null : 
    <div style={{display:"flex"}}>
      <HtmlTooltip interactive={true}
      title={
      <React.Fragment>
        <h3 className={classes.summaryHeader}>{props.type} History</h3>
        <div className={classes.history}>
            <List>
                {
                    history.map((historyItem, index) => {
                        const newValue = history[index - 1];

                        return historyItem.changeType === 'LATEST' ? null : (
                            <>
                                <Divider variant="inset" component="li" />
                                <ListItem alignItems="flex-start">
                                    <ListItemAvatar>
                                        <Avatar alt={historyItem.modifiedBy} src="/does/not/exist/1.jpg" />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <Fragment>
                                                <Typography
                                                    component="span"
                                                    className={`${classes.inline}`}
                                                >
                                                    {historyItem.modifiedBy} -
                                                </Typography> 
                                                <Typography
                                                    component="span"
                                                    variant="body2"
                                                    className={`${classes.inline} ${classes.commentDivider}`}
                                                >
                                                    {historyItem.changeType}
                                                </Typography> 
                                                <Typography
                                                    component="span"
                                                    className={`${classes.inline} ${classes.modificationDate}`}
                                                >
                                                {`(${formatDateTime(historyItem.modificationDate)})`}
                                                </Typography>
                                            </Fragment>
                                        }
                                        secondary={
                                            <>
                                                { historyItem.changeType === 'CUTOVER' ? 
                                                    <div className={classes.cutoverWarning}>
                                                        <Typography
                                                            component="span"
                                                            variant="body1"
                                                            color="textPrimary"
                                                        >
                                                                <InfoIcon style={{ color: orange[700], marginRight: '5px' }} />
                                                                {historyItem.change}
                                                        </Typography>
                                                    </div>
                                                : null }
                                            </>
                                        }
                                    />
                                </ListItem>
                                <LoanHoldDiff newValue={newValue?.historyDetails} oldValue={historyItem.historyDetails} changeType={historyItem.changeType} />
                            </>
                        );
                    })
                }
            </List>
        </div>
      </React.Fragment>
      }
      >
        <IconButton component="span" className={classes.hoverMessage} >
          {props.message}
        </IconButton>
      </HtmlTooltip>
    </div>
};


export default AuditLogTooltip;
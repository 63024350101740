import React, { useState } from 'react';
import { UserService } from '../../services';
import { useNotification } from '../../contexts/NotificationContext';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

const ActiveUserCell = (props) => {
    const { createErrorNotification } = useNotification();
    const [showWaitMessage, setShowWaitMessage] = useState(false);

    const handleActiveClick = async () => {
      if (props.value) {
        updateActive(false);
      } else {
        updateActive(true);
      }
    }

    const closeWaitMessage = () => {
      setShowWaitMessage(false);
    }

    const updateActive = async (active) => {
        try {
            const response = await UserService.updateActiveUser(props.username, active, props.email);
            if (response.status && response.status === 200) {
                setShowWaitMessage(true);
                setTimeout(() => window.location.reload(), 10000);
            } else {
                createErrorNotification("Failed to change active user setting.");
            }
        } catch (err) {
            createErrorNotification("Failed to change active user setting.");
        }
    }

    return (props.value ?
        <div>
          <Snackbar open={showWaitMessage} autoHideDuration={3000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert onClose={closeWaitMessage} severity="success">
              {"Deactivating user. Changes will appear in a few seconds."}
            </Alert>
          </Snackbar>
          <CheckBoxIcon onClick={handleActiveClick}/>
        </div>
        :
        <div>
          <Snackbar open={showWaitMessage} autoHideDuration={3000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert onClose={closeWaitMessage} severity="success">
              {"Activating user. Changes will appear in a few seconds."}
            </Alert>
          </Snackbar>
          <CheckBoxOutlineBlank onClick={handleActiveClick}/>
        </div>
    )
}

export default ActiveUserCell;

import React, { Fragment, useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { LoanService } from '../../services';
import { formatDate } from '../../utils/formatters';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ResendNotificationCell from '../../components/ResendNotificationCell'
import { useRole } from '../../contexts/RolesContext';
import grey from '@material-ui/core/colors/grey';
import Tooltip from "@material-ui/core/Tooltip";
import Divider from '@material-ui/core/Divider';

const styles = makeStyles((theme) => ({
    loanNotifications: {
        maxHeight: '60vh',
        overflowY: 'scroll'
    },
    notificationListItem: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center',
    }
}));

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 1000,
    },
}))(Tooltip);

const Notifications2HTML = (notificationItem, classes) => {
    const { isAdmin, isDealerBrowser } = useRole();

    return (
        <div style={{ width: '80%', margin: '0 auto' }}>
            <ListItem>
                <div className={classes.notificationListItem}>
                    <Typography component="span" variant="body2" color="textSecondary">
                        {isAdmin() || isDealerBrowser() ? <a href={`/lms/daily-processing/?date=${encodeURIComponent(formatDate(notificationItem.sentDate))}&tab=4`} style={{color: grey[600]}} target="_blank" rel="noopener noreferrer">{formatDate(notificationItem.sentDate)}</a> :
                        formatDate(notificationItem.sentDate)}
                    </Typography>
                    <Typography>
                        <Fragment>
                            <HtmlTooltip interactive title={<Fragment><div dangerouslySetInnerHTML={{__html: notificationItem.message}}/></Fragment>} >
                                <span>Preview Notification</span>
                            </HtmlTooltip>
                        </Fragment>
                    </Typography>
                    <Typography style={{ width: '10%', margin: '-50vw left' }}>
                        <ResendNotificationCell resendDate={notificationItem.resendDate} notificationId={notificationItem.id} />
                    </Typography>
                </div>
            </ListItem>
            <Divider />
        </div>
    );
}

const LoanNotificationsModal = ({ loanNumber }) => {
    const classes = styles();
    const [loanNotifications, setLoanNotifications] = useState(null);

    useEffect(() => {
        LoanService.getLoanNotifications(loanNumber).then(response => {
            if (response && response.status === 200) {
                setLoanNotifications(response.data?.notifications);

                if(response.data?.notifications.length) {
                    console.log(`Successfully pulled notifications for loan: ${loanNumber}`)
                } else {
                    console.log(`No notifications for loan: ${loanNumber}`)
                }
            } else {
                console.log(`Failed to retrieve notifications for loan: ${loanNumber}, please contact support`);
            }
        });
    }, [loanNumber]);

    return loanNotifications === null || !loanNotifications.length
        ? null
        : <div className={classes.loanNotifications}>
            <List>
                {loanNotifications.sort((a,b) => a.sentDate > b.sentDate ? -1 : 1).map((notificationItem) => Notifications2HTML(notificationItem, classes))}
            </List>
          </div>
}

export default LoanNotificationsModal;

import React from 'react';
import Button from '@material-ui/core/Button';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';

const AutoSendFRStatus = (props) => {
  const {sendStatus, toggleAutoSend} = props.autoSend || {};
  if(sendStatus === 'true') {
    return (
      <div>
          <Button disabled variant="contained">{props.altTitle}</Button>
      </div>
    );
  }
  else {
    return (
      <div>
          <Button variant="contained" onClick={props.onClick} startIcon={toggleAutoSend === 'true' ? <CheckBoxIcon /> : <CheckBoxOutlineBlank />}>{props.title}</Button>
      </div>
    );
  }
  
}

export default AutoSendFRStatus;
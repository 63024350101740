import React from 'react';
import { LoanService } from '../services';
import { Button, Dialog, DialogTitle, DialogContentText, DialogActions, DialogContent, Table, TableBody,
         TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@material-ui/core';
import { centsToDollar, formatDate, capitalizeString } from '../utils/formatters';
import { useNotification } from '../contexts/NotificationContext';
import { makeStyles } from '@material-ui/core/styles';
import LockIcon from '@material-ui/icons/Lock';
import blue from '@material-ui/core/colors/blue';

const styles = makeStyles(() => ({
    tableRow: {
        "&:hover": {
            backgroundColor: `${blue[50]} !important`,
            cursor: "pointer",
        }
    },
    lockedRow: {
        "&:hover": {
            cursor: "default"
        }
    },
}));

const ReturnsProcessedMarkerModal = (props) => {
    const classes = styles();
    const { createErrorNotification } = useNotification();
    const items = props.loanPaymentHistory ? props.loanPaymentHistory
        .filter(historyItem => historyItem.type === "Return")
        .sort((a,b) => a.date > b.date ? 1 : -1) : null;

    const handleReturnClick = async (item) => {
        if (item.status === 'issued') {
            try {
                const res = await LoanService.setReturnProcessed(item.transactionID);
                if(res.status === 200) {
                  props.onCancel();
                  //improve me
                  setTimeout(() => window.location.reload(), 100);
                } else {
                  props.onCancel();
                  createErrorNotification(`Failed to mark return as Processed, please contact support`);
                }
            } catch(err) {
                console.error(err);
            }
        }
    };

    return (
        <div>
            <Dialog
                open={props.showModal}
                onClose={props.onCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Confirm</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Please select the return to mark as Processed.<br/><b>NOTE:</b> This cannot be undone
                    </DialogContentText>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                  <TableCell><b>Date</b></TableCell>
                                  <TableCell><b>Status</b></TableCell>
                                  <TableCell><b>Amount</b></TableCell>
                                  <TableCell><b>Locked</b></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {items.map((item) => {
                                  return <TableRow hover
                                    className={item.status === 'issued' ? classes.tableRow : classes.lockedRow}
                                    onClick={() => handleReturnClick(item)}
                                  >
                                    <TableCell>{formatDate(item.date)}</TableCell>
                                    <TableCell>{capitalizeString(item?.status)}</TableCell>
                                    <TableCell>{centsToDollar(item.amount)}</TableCell>
                                    <TableCell style={{width: 20}}>
                                        {
                                            item.status !== 'issued' ?
                                            <Tooltip title={'Status cannot be updated'}><LockIcon /></Tooltip> : null
                                        }
                                    </TableCell>
                                </TableRow>
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onCancel} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default ReturnsProcessedMarkerModal;
import React, { memo, useState } from 'react';
import { FeeService } from '../../services';
import green from '@material-ui/core/colors/green';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ConfirmationModal from '../../components/ConfirmationModal'
import { useNotification } from '../../contexts/NotificationContext';


const styles = makeStyles((theme) => ({
  downloadButton: {
      backgroundColor: green[400],
      marginTop: "10px"
  },
}));

const DownloadFeeReportButton = ({date, organization}) => {
  const classes = styles();
  const [showDownloadReportConfirmation, setShowDownloadReportConfirmation] = useState(false);
  const { createErrorNotification, createNotification } = useNotification();
  const toggleDownloadReportConfirmation = () => setShowDownloadReportConfirmation(!showDownloadReportConfirmation);

  const handleDownloadButtonClick = async (event) => {
    toggleDownloadReportConfirmation();
}

  const handleDownloadButton = () => {
    FeeService.downloadFeeReport(date, organization).then(response => {
      if (response.status === 200) {
        createNotification('Successfully downloaded Fee Report!');
      } else {
        createErrorNotification('Failed to download Fee Report...');
      }
    }).catch(error => {
      createErrorNotification('Failed to download Fee Report...');
    });
    toggleDownloadReportConfirmation();
  }

  return (
    <>
    <Button variant="contained" 
      className={classes.downloadButton} 
      onClick={handleDownloadButtonClick}
      >Download Fee Report
    </Button>
    <ConfirmationModal title="Confirm" message={`Are you sure you want to download fee report for ${organization}`} onConfirm={handleDownloadButton} onCancel={toggleDownloadReportConfirmation} showConfirmationBox={showDownloadReportConfirmation}/>
    </>
  );
}

export default memo(DownloadFeeReportButton);

import React from 'react';
import HomeIcon from '@material-ui/icons/Home';
import SolarPowerIcon from '@material-ui/icons/WbSunny';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
    typeIcon: {
      marginRight: '30px',
      marginLeft: '0px',
      fontSize:'25pt',
    },
  }));

const LoanTypeIcon = (props) => {
    const classes = useStyles();
    const { fontSize } = props;
    const { loanType } = props;
    
    //Solar
    if (loanType === "Solar") {
        return (<Tooltip title='Solar'>
                <SolarPowerIcon style={{color: '#F89D3C', fontSize: fontSize, cursor: 'pointer', pointerEvents: 'all'}} className={classes.typeIcon}/>
                </Tooltip>)
    }
    //Healthcare
    else if (loanType === "Healthcare") {
        return (<Tooltip title='Healthcare'>
                <LocalHospitalIcon style={{color: '#2AC11D', fontSize: fontSize, cursor: 'pointer', pointerEvents: 'all'}} className={classes.typeIcon}/>
                </Tooltip>)
    }
    //Home improvement
    else {
        return (<Tooltip title='Home Improvement'>
                <HomeIcon style={{color: '#1E6AE1', fontSize: fontSize, cursor: 'pointer', pointerEvents: 'all'}} className={classes.typeIcon} />
            </Tooltip>)
    }
}

export default LoanTypeIcon;


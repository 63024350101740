import React, { memo, useState, Fragment } from 'react';
import { FeeService } from '../../services';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Delete from '@material-ui/icons/Delete';
import ConfirmationModal from '../../components/ConfirmationModal'
import { useRole } from '../../contexts/RolesContext';

const DeleteFeeConfigButtons = ({selectedRowIndices, potentialDeletions}) => {
  const [showDeleteFeeConfigConfirmation, setShowDeleteFeeConfigConfirmation] = useState(false);
  const { hasRole, isAdmin } = useRole();

  const toggleDeleteFeeConfigConfirmation = () => setShowDeleteFeeConfigConfirmation(!showDeleteFeeConfigConfirmation);

  const handleDeleteClick = () => {
    toggleDeleteFeeConfigConfirmation();
    FeeService.deleteFeeConfigurations(selectedRowIndices.map(rowId => {
      return potentialDeletions[rowId].id;
    })).then(response => {
      // !FIXME: Do this better!
      window.location.reload();
    });
  }

  return (isAdmin()  && !hasRole('cs') ? (
    <>
    <Fragment>
        <Tooltip title={"Delete Fee Configurations"}>
          <IconButton onClick={toggleDeleteFeeConfigConfirmation}> 
            <Delete />
          </IconButton>
        </Tooltip>
    </Fragment>
    <ConfirmationModal title="Confirm" message="Are you sure you want to delete these fee configurations" onConfirm={handleDeleteClick} onCancel={toggleDeleteFeeConfigConfirmation} showConfirmationBox={showDeleteFeeConfigConfirmation}/>
    </>
  ) : null);
}

export default memo(DeleteFeeConfigButtons);
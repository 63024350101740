import React from 'react';
import { LoanService } from '../../services';
import { useNotification } from '../../contexts/NotificationContext';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import { formatDate, getCurrentDateFormatted } from '../../utils/formatters';

const PRCheckboxCell = (props) => {
    const { createErrorNotification } = useNotification();

    const handlePRClick = async () => {
        if (props.value) {
          updatePR(false);
        } else {
          updatePR(true);
        }
    }

    const handleOldPRClick = async () => {
        createErrorNotification(`Cannot update old principle reduction.`);
    }
    const updatePR = async (value) => {
        try {
            const response = await LoanService.updateReturnPrincipleReduction(props.id, value);
            if (response.status === 200) {
                // TODO: Improve me
                console.log(props.value)
                setTimeout(() => window.location.reload(), 100);
                
            } else {
                createErrorNotification(" to change notification setting.");
            }
          } catch (err) {
              console.log("I am here")
              createErrorNotification("Failed to change notification setting.");
          }
    }
    return props.disableUpdates ? (props.value ?
        <CheckBoxIcon/> : 
        <CheckBoxOutlineBlank/>) :
        (formatDate(props.date) === getCurrentDateFormatted() ?
        (props.value ?
        <CheckBoxIcon onClick={handlePRClick}/> : 
        <CheckBoxOutlineBlank onClick={handlePRClick}/>) :
        (props.value ?
        <CheckBoxIcon onClick={handleOldPRClick}/> : 
        <CheckBoxOutlineBlank onClick={handleOldPRClick}/>));

}


export default PRCheckboxCell;
import React, { memo, Fragment } from 'react';
import { LoanService } from '../../services';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import WatchLater from '@material-ui/icons/WatchLater';
import { useNotification } from '../../contexts/NotificationContext';

const MarkHoldsAsProcessingButton = ({selectedRowIndices, holds}) => {
    const { createErrorNotification } = useNotification();
    const handleMarkAsProcessingClick = async () => {
        const loanNumbers = selectedRowIndices.map(rowId => holds[rowId].loanNumber);

        try{
            const response = await LoanService.markHoldsAsProcessing(loanNumbers);
            if (JSON.parse(response)) {
                // !FIXME: Do this better!
                window.location.reload();
            }
        } catch (error) {
            createErrorNotification('Unable to mark the selected holds as processing.');
        }
    }

    return (
        <Tooltip title={`Mark Holds as 'Processing'`}>
            <IconButton onClick={handleMarkAsProcessingClick}>
                <WatchLater />
            </IconButton>
        </Tooltip>
    );
}

export default memo(MarkHoldsAsProcessingButton);

import React, { useEffect, useState }  from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';
import MUIDataTable from 'mui-datatables';
import { useNotification } from '../contexts/NotificationContext';
import { LoanService } from '../services';
import { centsToDollar, formatDate } from '../utils/formatters';

const styles = makeStyles(() => ({ 
    box: {
        position: 'absolute', 
        top: '50%', left: '50%', 
        transform: 'translate(-50%, -50%)',
        width: 'auto',
        height:'auto',
        backgroundColor: '#fff',
        paddingLeft: '24px',
        paddingRight: '24px',
        paddingTop: '16px',
        paddingBottom: '16px',
        border: '1px solid #000',
        borderRadius: '6px',
        display:'block',
        
    },
    table: {
        marginTop: '10px',
        boxShadow: 'none',
    },
    header: {
        textAlign: 'center'
    }
}));

const FRPRModal = ({title, loanNumber, open, onClose}) => {
    const classes = styles();
    const {createErrorNotification} = useNotification();
    const [queryPull, setQueryPull] = useState(null);
    
    const defaultOptions = {filter: false, display: true, viewColumns: true};
    const columns = [
        {
          name: 'Type',
          options: defaultOptions
        }, {
            name: 'Amount',
            options: {
                filter: false, display: true, viewColumns: false,
                customBodyRender: (value, tableMeta) => {
                    return centsToDollar(value);
              }
            }
        }, {
            name: 'Created',
            options: defaultOptions
        }, {
            name: 'Last Updated',
            options: defaultOptions
        }
    ];
    
    const options = {
        selectableRows: 'none', 
        rowsPerPageOptions: [],
        search: false, 
        download: false, 
        print:false, 
        viewColumns: false, 
        filter: false,
        sort: false
    };

    useEffect(() => {
        if (queryPull === null) {
            LoanService.getDeletedFRPR(loanNumber).then(response => {
                if (response && response.status === 200) {
                    setQueryPull(response.data.data);
                } else {
                    createErrorNotification("Error: Failed to retrieve data LMAO");
                }
            });
        }
        console.log('triggered');
        
    }, [loanNumber, createErrorNotification, queryPull]);

    const tablifyDeletedFRPR = queryPull => (queryPull == null) ? null : queryPull.map(queryPull => {
        return [
          queryPull.type,
          queryPull.amountCents,
          formatDate(queryPull.created),
          formatDate(queryPull.updated)
        ];
      });

    return(
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Fade in={open}>
                <Box className={classes.box}>
                    <Typography className={classes.header} id="modal-modal-title" variant="h6" component="h2">
                        {title}
                    </Typography>
                    <MUIDataTable 
                        className={classes.table}
                        data={tablifyDeletedFRPR(queryPull)} 
                        columns={columns}
                        options={options}
                    />
                </Box>
            </Fade>
        </Modal>
    )
}

export default FRPRModal;

import React, { memo, useState, Fragment } from 'react';
import { LoanService } from '../../services';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import ConfirmationModal from '../../components/ConfirmationModal'
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';

const CancelHoldButton = ({loanNumber}) => {
  const [showCancelHoldsConfirmation, setShowCancelHoldsConfirmation] = useState(false);
  const toggleCancelHoldsConfirmation = () => setShowCancelHoldsConfirmation(!showCancelHoldsConfirmation);
  
      const handleCancelHoldClick = () => {
        toggleCancelHoldsConfirmation();
        LoanService.cancelHolds(loanNumber).then(response => {
          // !FIXME: Do this better!
          window.location.reload();
        });
      }

      return (
        <>
          <Fragment>
              <Tooltip title={'Remove loan hold without releasing it'}>
                <IconButton onClick={toggleCancelHoldsConfirmation} >
                  <CancelPresentationIcon />
                </IconButton>
              </Tooltip>
        </Fragment>
        <ConfirmationModal title="Confirm" message="Are you sure you want to cancel this loan hold?" onConfirm={handleCancelHoldClick} onCancel={toggleCancelHoldsConfirmation} showConfirmationBox={showCancelHoldsConfirmation}/>
        </>
      );
  }

export default memo(CancelHoldButton);
import { request } from './utils';

export default class HubspotService {
    static async sendAllLoanNotes() {
        const response = await request('/admin/hubspot/send-all-loan-notes', { method: 'POST' });

        return response;
    }

    static async sendAllMerchantNotes() {
        const response = await request('/admin/hubspot/send-all-merchant-notes', { method: 'POST' });

        return response;
    }

    static async pushPendingLoanPCGNotes() {
        const response = await request('/admin/hubspot/push-pending-pcg-loan-notes', { method: 'POST' });

        return response;
    }
}

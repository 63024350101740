import React, {useState} from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import SettingsIcon from '@material-ui/icons/Settings';
import { AccountService } from '../services';
import { useNotification } from "../contexts/NotificationContext";

function AccountDropDown({history}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const {createErrorNotification} = useNotification()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogoutClick = async () => {
      handleClose();
      const response = await AccountService.logout();
      if (response.status === 200){
        // succesful logout
        /** note: i tried adding a push notification for more clarity, but for some wack reason that crashes things, so i guess this will do. */
        history.push("/login");
      } else {
        createErrorNotification("There was an error logging out, please try cleaning your browser's cookies, logging out again, or contacting us")
      }
  }

  const handleChangePasswordClick = async () => {
      handleClose();
      history.push("/update-password");
  }



  return (
    <div>
      <IconButton component="span" aria-controls="account-menu" aria-haspopup="true"  onClick={handleClick}>
        <SettingsIcon fontSize='large' />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose} disabled>My account</MenuItem>
        <MenuItem onClick={handleChangePasswordClick}>Change Password</MenuItem>
        <MenuItem onClick={handleLogoutClick}>Logout</MenuItem>
      </Menu>
    </div>
  );
}

export default AccountDropDown;
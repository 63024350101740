import React, { memo, Fragment } from 'react';
import { LoanService } from '../../services';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import ReportOutlinedIcon from '@material-ui/icons/ReportOutlined';
import ReportOffOutlinedIcon from '@material-ui/icons/ReportOffOutlined';

const AddHoldRequestButtons = ({selectedRowIndices, potentialHolds, isFundingRequests, excludeRemoveButton = false}) => {
      const handleHoldClick = () => {
        const holdMethod = isFundingRequests ? LoanService.holdFundingRequests : LoanService.holdPayments;
        holdMethod(selectedRowIndices.map(rowId => {
          return potentialHolds[rowId].id;
        })).then(response => {
          // !FIXME: Do this better!
          window.location.reload();
        });
      }
  
      const handleHoldClearClick = () => {
        const clearMethod = isFundingRequests ? LoanService.clearFundingRequests : LoanService.clearPayments;
        clearMethod(selectedRowIndices.map(rowId => {
          return potentialHolds[rowId].id;
        })).then(response => {
          // !FIXME: Do this better!
          window.location.reload();
        });
      }

      return (
          <Fragment>
              <Tooltip title={`Place Hold on ${isFundingRequests ? 'Funding Requests' : 'Payments'}`}>
                <IconButton onClick={handleHoldClick}> 
                  <ReportOutlinedIcon />
                </IconButton>
              </Tooltip>
              {
                excludeRemoveButton ? null :
                <Tooltip title={`Remove Hold on ${isFundingRequests ? 'Funding Requests' : 'Payments'}`}>
                  <IconButton onClick={handleHoldClearClick}>
                    <ReportOffOutlinedIcon />
                  </IconButton>
                </Tooltip>
              }
        </Fragment>
      );
  }

export default memo(AddHoldRequestButtons);
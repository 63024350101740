import React, { useState }  from 'react';
import { LoanService } from '../services';
import Button from '@material-ui/core/Button';
import ConfirmationModal from '../components/ConfirmationModal';
import TextField from '@material-ui/core/TextField';
import green from '@material-ui/core/colors/green';
import { makeStyles } from '@material-ui/core/styles';

//import Notifications from './Notifications';
import { useNotification } from '../contexts/NotificationContext';

// CSS Styling
const styles = makeStyles(() => ({
    submitButton: {
        marginLeft: '10px',
        marginTop: '25px'
    },
    reassignButton: {
        backgroundColor: green[400],
        float: 'right',
        marginTop:'16px',
        marginRight:'4px',
        //width: '33%'
    },
    textInput: {
        float: 'right',
        marginTop: '8px',
        marginRight:'4px',
        marginBottom: '4px',
        //width: '33%'
    },
    container: {
        display: 'inline',
    }
}));

// React Component
const ReassignLoanDealer = (props) => {
    const { createNotification } = useNotification();
    const { createErrorNotification } = useNotification();

	// Modal States
    const [showConfirmModal, setShowConfirmModal] = useState(false);
	const [showFinalWarning, setShowFinalWarning] = useState(false);
		
    // Field variables
	const [loanNum, setLoanNum] = useState('');
	const [oldDealerNum, setOldDealerNum] = useState('');
	const [newDealerNum, setNewDealerNum] = useState('');

	// Toggling Modal Visibility 
    const toggleConfirmModal = () => setShowConfirmModal(!showConfirmModal);
	const toggleFinalWarning = () => setShowFinalWarning(!showFinalWarning);
    const loadConfirmationModal = async (event) => { 
        if (loanNum !== ''|| oldDealerNum !== ''|| newDealerNum !== ''){
            toggleConfirmModal();
        }
    }
	const loadFinalWarning = async (event) => { 
        toggleFinalWarning();
    }
    const toggleModals = async (event) => {
        toggleConfirmModal();
        toggleFinalWarning();
    }

    // Only fires when all textfields are non-empty
    const handleReassignConfirmation = async (event) => {
        try {
            const response = await LoanService.updateLoanDealer(loanNum, oldDealerNum, newDealerNum);
            if (response.status && response.status === 200) {
                setLoanNum('');
                setOldDealerNum('');
                setNewDealerNum('');
                toggleConfirmModal();
                toggleFinalWarning();
                createNotification(`Successfully Updated Loan Dealer`);
            }
        } catch (err) {
            toggleConfirmModal();
            toggleFinalWarning();
            createErrorNotification("Failed to update loan dealer");
        }
            
            
    } 

    const handleLoanTextChange = (event) => setLoanNum(event.target.value);
    const handleOldDealerTextChange = (event) => setOldDealerNum(event.target.value);
    const handleNewDealerTextChange = (event) => setNewDealerNum(event.target.value);

    const classes = styles();
    return(
	    <div>
	        <ConfirmationModal 
                title="Reassigning Loan"
                message={`You are assigning Loan ${loanNum}'s Dealer ID from ${oldDealerNum} to ${newDealerNum}. Would you like to continue?`}
                onConfirm={loadFinalWarning}
                onCancel={toggleConfirmModal}
                showConfirmationBox={showConfirmModal}
            />
            <ConfirmationModal 
                title="Final Warning"
                message="Are you sure you would like to reassign?"
                onConfirm={handleReassignConfirmation}
                onCancel={toggleModals}
                showConfirmationBox={showFinalWarning}
            />
            <div className={classes.container}>
                <TextField
                    variant="outlined"
                    id="outlined-select-currency-native"
                    className={classes.textInput}
                    label="Loan #"
                    placeholder="PP12345"
                    value={loanNum}
                    onChange={handleLoanTextChange}
                ></TextField>
                <TextField
                    variant="outlined"
                    id="outlined-select-currency-native"
                    className={classes.textInput}
                    label="Current Dealer #"
                    placeholder="1234"
                    value={oldDealerNum}
                    onChange={handleOldDealerTextChange}
                ></TextField>
                <TextField
                    variant="outlined"
                    id="outlined-select-currency-native"
                    className={classes.textInput}
                    label="New Dealer #"
                    placeholder="1234"
                    value={newDealerNum}
                    onChange={handleNewDealerTextChange}
                ></TextField>
        	
                <Button 
                    variant="contained" 
                    className={classes.reassignButton} 
                    onClick={loadConfirmationModal}
                    >reassign
                </Button>
            </div>
            
	    </div>
    )  
}

export default ReassignLoanDealer;
import React, { Fragment, useState } from 'react';
import { LoanService } from '../services';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from "@material-ui/core/DialogContentText";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from "@material-ui/core/Tooltip";
import Switch from '@material-ui/core/Switch';
import { Typography } from '@material-ui/core';
import { centsToDollar, formatDate} from '../utils/formatters';
import { makeStyles } from '@material-ui/core/styles';
import { useNotification } from '../contexts/NotificationContext';
import blue from '@material-ui/core/colors/blue';

const styles = makeStyles(() => ({
  tableRow: {
      "&:hover": {
        backgroundColor: `${blue[50]} !important`,
        cursor: "pointer",
      }
  },
  toggleSwitchElement: {
    display: 'inline',
  }
}));

const ReturnIssueDialog = (props) => {
  const classes = styles();
  const { createErrorNotification } = useNotification();
  const [princReduc, setPrincReduc] = useState(false);


  const handlePrincReducToggle = () => {
    setPrincReduc(!princReduc);
  }

  const handlePaymentClick = async (paymentId, princReduc) => {
    LoanService.togglePaymentReturn(paymentId, princReduc)
      .then(res => {
        if(res.status === 200) {
          props.onCancel();
          //improve me
          setTimeout(() => window.location.reload(), 100);
        } else {
          props.onCancel();
          createErrorNotification(`Failed to issue return, please contact support`);
        }
      }).catch(err => {
        console.error(err);
      })
    };

  return (
    <div>
        <Dialog
          open={props.showReturnDialog}
          onClose={props.onCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {props.title}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {props.message}
            </DialogContentText>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell><b>Date</b></TableCell>
                    <TableCell><b>Stage</b></TableCell>
                    <TableCell><b>Amount</b></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.loanPaymentHistory ? props.loanPaymentHistory.filter(historyItem => historyItem.type === "Payment").sort((a,b) => a.date > b.date ? 1 : -1).map((historyItem) => {
                    return <React.Fragment><TableRow hover className={classes.tableRow} onClick={() => handlePaymentClick(historyItem.transactionID, princReduc)}>
                      <TableCell>{formatDate(historyItem.date)}</TableCell>
                      <TableCell>{historyItem.paymentStage}</TableCell>
                      <TableCell>{centsToDollar(historyItem.amount)}</TableCell>
                  </TableRow></React.Fragment>
                  }) : null}
                </TableBody>
              </Table>
            </TableContainer>
            <Fragment>
              <Typography className={classes.toggleSwitchElement}>Principal Reduction</Typography>
              <Tooltip title={<span className={classes.tooltip}><b>Active: </b>Return will generate NLS XML initiating a principal reduction as well as a NACHA entry.<br /><b>Inactive: </b>Return will only generate a NACHA entry.</span>}>
                <Switch checked={princReduc} onChange={handlePrincReducToggle} color="primary"/>
              </Tooltip>
            </Fragment>
          </DialogContent>
          <DialogActions>
            <Button onClick={props.onCancel} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  );
}

export default ReturnIssueDialog;
import React, { Fragment, useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { FundingStagesCell, LoanTypeIcon, NLSComments } from '../../components';
import { formatDate, formatTime, centsToDollar, aprToPercent, timeAgo, formatCodes, stage2Text } from '../../utils/formatters';
import { LoanService, LenderService } from '../../services';
import { getLoanStatusColor, commentCategories } from '../../utils/constants';
import { Grid, Typography, TextField, Link, Breadcrumbs,  Tooltip, List, Button,
         IconButton, ListItem, Divider, ListItemText, ListItemAvatar, Avatar, Chip } from '@material-ui/core';
import LoanHoldAuditLog from './LoanHoldAuditLog';
import LoanNotificationsModal from './LoanNotificationsModal';
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector,
         TimelineContent, TimelineDot, TimelineOppositeContent } from '@material-ui/lab';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import PanToolIcon from '@material-ui/icons/PanTool';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import DialpadIcon  from '@material-ui/icons/Dialpad';
import PaymentIcon from '@material-ui/icons/Payment';
import DelinquencyIcon from '@material-ui/icons/History';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import PhoneIcon from '@material-ui/icons/Phone';
import NoteIcon from '@material-ui/icons/Note';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import WarningIcon from '@material-ui/icons/Warning';
import MailIcon from '@material-ui/icons/Mail';
import DoneIcon from '@material-ui/icons/Done';
import CallMissedOutgoingIcon from "@material-ui/icons/CallMissedOutgoing";
import { AssignmentTurnedIn, Dvr } from '@material-ui/icons';
import { useParams } from "react-router-dom";
import SendIcon from '@material-ui/icons/Send';
import CloseIcon from '@material-ui/icons/Close';
import { blue, grey, blueGrey, red, yellow, green } from '@material-ui/core/colors';
import LoanForceComplete from './LoanForceComplete';
import ConfirmationModal from '../../components/ConfirmationModal';
import BounceMarkerModal from '../../components/BounceMarkerModal';
import ReturnIssueModal from '../../components/ReturnIssueModal';
import AlertModal from '../../components/AlertModal';
import FRPRModal from '../../components/FRPRModal'
import ReturnsProcessedMarkerModal from '../../components/ReturnsProcessedMarkerModal';
import { useNotification } from '../../contexts/NotificationContext';
import { useRole } from '../../contexts/RolesContext';
import { createBrowserHistory } from "history";

const styles = makeStyles((theme) => ({
    root: {
    },
    summary: {
        backgroundColor: theme.palette.grey.main,
        borderRadius: '5px',
        border: 'groove',
        borderColor: 'black',
        borderWidth: '0.5px',
        margin: "25px",
        padding: "10px",
        width: "25vw",
    },
    summaryHeader: {
        textAlign: 'center',
        textDecoration: 'underline',
    },
    summaryDownload: {
        float: 'right'
    },
    summaryUL: {
        listStylePosition: 'inside',
    },
    secondRow: {
        borderRadius: '5px',
        border: 'groove',
        borderColor: 'black',
        borderWidth: '0.5px',
        margin: "25px",
        padding: "10px",
        width: "40vw",
    },
    calculatorBtn: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: '1px'
    },
    calculatorUL: {
        listStylePosition: 'inside',
        listStyle: 'none'
    },
    historyTimelineWrapper: {
        left: '-8vw',
        position: 'relative',
        maxHeight: '60vh',
        overflowY: 'scroll'
    },
    notesWrapper: {
        maxHeight: '60vh',
        overflowY: 'scroll'
    },
    pageHeader: {
        display: 'flex',
        justifyContent: 'center',
    },
    pageHeaderChip: {
        marginTop: '20px',
        marginLeft: '40px',
        fontWeight: 'bold',
        fontSize: '18px',
    },
    comment: {
        margin: '10px 10px 10px 5px',
        width: '40%'
    },
    commentType: {
        margin: '10px 5px 10px 20px',
    },
    commentButton: {
        margin: '15px 10px 10px 10px',
    },
    commentDate: {
        fontSize: '12px',
    },
    commentDivider: {
        paddingLeft: '5px',
        paddingRight: '10px'
    },
    selectInput: {
        margin: '5px 5px 5px 10px',
        width: '50%',
    },
    milestones: {
        display: 'flex',
    }
}));

const gatherHistory = (historyItems) => {
    let items = [];
    for (const item of historyItems) {
        switch (item.type) {
            case "Funding Request":
                items.push(FundingRequestHistory2HTML(item));
                break;
            case "Return":
                items.push(ReturnHistory2HTML(item));
                break;
            case "Payment":
                items.push(PaymentHistory2HTML(item));
                break;
            default:
                return null;
        }
    }

    return items.flat().sort((a,b) => a.date > b.date ? 1 : -1).map((r)=>r.cmp)
}

const ReturnHistory2HTML = (historyItem) => {
    let items = [];

    if (historyItem.processed_at) {
        items.push({
          date: historyItem.processed_at,
          message: `Return processed (${centsToDollar(
            historyItem.amount
          )})`,
          icon: (
            <TimelineDot style={{ backgroundColor: green[700] }}>
              <DoneIcon />
            </TimelineDot>
          ),
        });
    }

    if (historyItem.bounced_at) {
        items.push({
            date: historyItem.bounced_at,
            message: `Return bounced (${centsToDollar(historyItem.amount)})`,
            icon: (
              <TimelineDot style={{ backgroundColor: red[500] }}>
                <CallMissedOutgoingIcon />
              </TimelineDot>
            )
        })
    }

    if (historyItem.issued_at) {
        items.push({
            date: historyItem.issued_at,
            message: `Return Issued (${centsToDollar(historyItem.amount)})`,
            icon: (
                <TimelineDot style={{ backgroundColor: grey[500] }}>
                    <KeyboardReturnIcon />
                </TimelineDot>
            )
        })
    }
    return items.map(item => GenerateHistoryItem(item, 3, historyItem.bounced));
}

const FundingRequestHistory2HTML = (historyItem) => {
    let message = '';
    let icon = null;

    if (historyItem.isHeld) {
        message = `Funding Request Held (${centsToDollar(historyItem.amount)})`;
        icon = (<TimelineDot style={{backgroundColor: red[500]}}>
                <PanToolIcon />
            </TimelineDot>);
    } else {
        message = `Funding Request Completed (${centsToDollar(historyItem.amount)})`;
        icon = <TimelineDot><AccountBalanceIcon /></TimelineDot>;
    }

    Object.assign(historyItem, { icon: icon, message: message, completed: false})
    return GenerateHistoryItem(historyItem, 1);
}

const PaymentHistory2HTML = (historyItem) => {
    const msgPrefix = historyItem.bounced ? `[BOUNCED] ` : '';
    const stage = stage2Text(historyItem.paymentStage);
    const amount = centsToDollar(historyItem.amount);
    let items = [];

    const paidAt = formatDate(historyItem.paidAt);

    if (historyItem.receivedAt) {
      items.push({
        message: `${msgPrefix}${stage} payment received (${amount})`,
        date: historyItem.receivedAt,
        icon: (
          <TimelineDot style={{ backgroundColor: blueGrey[600] }}>
            <MailIcon />
          </TimelineDot>
        ),
      });
    }
    if (historyItem.paidAt) {
        items.push({
            message: `${msgPrefix}${stage} payment paid (${amount})`,
            date: historyItem.paidAt,
            icon: (
                <TimelineDot style={{ backgroundColor: green[700] }}>
                    <DoneIcon />
                </TimelineDot>
            )
        });
    }
    if (historyItem.heldAt && formatDate(historyItem.heldAt) !== paidAt) {
        items.push({
            message: `${msgPrefix}${stage} payment held (${amount})`,
            date: historyItem.heldAt,
            icon: (
                <TimelineDot style={{ backgroundColor: red[600] }}>
                    <PanToolIcon fontSize="small" />
                </TimelineDot>
            )
        });
    }
    if (historyItem.processingAt && formatDate(historyItem.processingAt) !== paidAt) {
        items.push({
            message: `${msgPrefix}${stage} payment processing (${amount})`,
            date: historyItem.processingAt,
            icon: (
                <TimelineDot style={{ backgroundColor: yellow[600] }}>
                    <WarningIcon />
                </TimelineDot>
            )
        });
    }
    if (historyItem.isForcedPayment) {
        items.push({
          message: `${msgPrefix}${stage} payment force completed (${amount})`,
          date: historyItem.date,
          completed: true,
          icon: (
            <TimelineDot style={{ backgroundColor: blue[500] }}>
              <AttachMoneyIcon />
            </TimelineDot>
          )
        });
    }
    if (!historyItem.receivedAt && !historyItem.paidAt && !historyItem.processingAt && !historyItem.heldAt) {
        items.push({
          message: `${msgPrefix}${stage} payment completed (${amount})`,
          date: historyItem.date,
          completed:
            historyItem.paymentStage === "unstaged" ||
            historyItem.paymentStage === "final",
          icon: (
            <TimelineDot color="secondary">
              <AttachMoneyIcon />
            </TimelineDot>
          )
        });
    }

    return items.map((item) => GenerateHistoryItem(item, 2, historyItem.bounced));
}

const GenerateHistoryItem = (item, tab, bounced) => {
    const { isAdmin, isDealerBrowser } = useRole();

    return {
        date: item.date,
        cmp: (
            <TimelineItem>
              <TimelineOppositeContent>
                <Typography variant="body2" color="textSecondary">
                  {isAdmin() || isDealerBrowser() ? (
                    <a
                      href={`/lms/daily-processing/?date=${encodeURIComponent(
                        formatDate(item.date)
                      )}&tab=${tab}`}
                      style={{ color: grey[600] }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {formatDate(item.date)}
                    </a>
                  ) : (
                    formatDate(item.date)
                  )}
                </Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                {item.icon}
                {!item.completed ? <TimelineConnector /> : null}
              </TimelineSeparator>
              <TimelineContent
                style={{ backgroundColor: 'transparent', borderRadius: "20px" }}
              >
                <Typography component="span">{item.message}</Typography>
                <Typography component="span" variant="body2">
                  {item.holdComment && item.isHeld
                    ? `  Reason: '${item.holdComment}'`
                    : null}
                </Typography>
              </TimelineContent>
            </TimelineItem>
      )
    };
}

const Delinquency2HTML = (delinquencyItem) => {
    let paidDate = delinquencyItem.isDelinquent ? null : new Date(delinquencyItem.dueDate);
    let princBalance = 'Remaining Balance: ' + centsToDollar(delinquencyItem.currentPrincipalBalance);
    let message = 'Delinquent: '+ delinquencyItem.daysPastDue+' days';
    // let codes = null;
    let icon = <TimelineDot style={{backgroundColor: red[500]}} ><DelinquencyIcon /></TimelineDot>;;
    let payment = null;

//    if(delinquencyItem.statusCodes !== null) {
//         codes = 'Status Codes: ' + delinquencyItem.statusCodes;
//    }
   if(paidDate) {
       paidDate.setDate(paidDate.getDate() + delinquencyItem.daysPastDue)
       payment = 'Paid:'+ formatDate(paidDate);
       icon = <TimelineDot style={{backgroundColor: grey[400]}} ><DelinquencyIcon /></TimelineDot>;;
   }


    return (
        <TimelineItem>
            <TimelineOppositeContent>
                <Typography variant="body2" color="textSecondary">Due:
                    {formatDate(delinquencyItem.dueDate)}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    {payment}
                </Typography>

            </TimelineOppositeContent>
            <TimelineSeparator>
                {icon}
                <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
                <Typography component="span">
                    {message}
                </Typography><br />
                <Typography component="span">
                    {princBalance}
                </Typography><br />
                {/* <Typography component="span">
                    {codes}
                </Typography> */}
            </TimelineContent>
        </TimelineItem>

    );
}

const DelinquencyComment2HTML = (nlsDelinquencyComment) => {
    let icon = <TimelineDot style={{backgroundColor: grey[400]}} ><NoteIcon /></TimelineDot>;
    let commentData = nlsDelinquencyComment.comment;
    let phoneRegex = /(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}/g;
    let chatRegex = /-------------------- Chat Log/g;
    let paymentRegex = /https:\/\//g;
    if(commentData.match(chatRegex)) {
        icon = <TimelineDot style={{backgroundColor: green[300]}} ><ChatBubbleIcon /></TimelineDot>;
    }
    else if(commentData.match(phoneRegex)) {
        icon = <TimelineDot style={{backgroundColor: blue[300]}} ><PhoneIcon /></TimelineDot>;
    }

    else if(commentData.match(paymentRegex)) {
        icon = <TimelineDot style={{backgroundColor: green[500]}} ><AttachMoneyIcon/></TimelineDot>;
    }


    return (
        <TimelineItem>
            <TimelineOppositeContent>
                <Typography variant="body2" color="textSecondary">
                    {formatDate(nlsDelinquencyComment.commentDate)}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    {formatTime(nlsDelinquencyComment.commentDate)}
                </Typography>
            </TimelineOppositeContent>

            <TimelineSeparator>
                {icon}
                <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
                <Typography style={{whiteSpace: 'pre-line'}} variant="body2" component="span">
                    {commentData}
                </Typography><br />
            </TimelineContent>
        </TimelineItem>
    );
}

const Loan = () => {
    const classes = styles();
    const { createNotification, createErrorNotification } = useNotification();
    const [comment, setComment] = React.useState("");
    const [commentCategory, setCommentCategory] = React.useState(commentCategories[0].value);
    const [loanHistoryData, setLoanHistoryData] = useState(null);
    const [loanVisionFeeCalc, setLoanVisionFeeCalc] = useState(null);
    const [showVisionStats, setShowVisionStats] = useState(false);
    const [calcDisplay, setCalcDisplay] = useState(null);
    const [calculatorTitle, setCalculatorTitle] = useState('Calculator');
    const [commentData, setCommentData] = useState(null);
    const [currentCommentId, setCurrentCommentId] = useState(null);
    const [delinquencyCommentData, setLoanDelinquencyCommentData] = useState(null);
    const [loanDelinquencyData, setLoanDelinquencyData] = useState(null);
    const [loanMilestones, setLoanMilestones] = useState(null);
    const [showForceComplete, setShowForceComplete] = useState(false);
    const [showConfirmationBox, setShowConfirmationBox] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [showReturnsProcessedMarkerModal, setShowReturnsProcessedMarkerModal] = useState(false);
    const [markBouncedPayment, setMarkBouncedPayment] = useState(false);
    const [issueReturnPayment, setIssueReturnPayment] = useState(false);
    const [showFRPRmodal, setShowFRPRModal] = useState(false);
    const [alertData, setAlertData] = useState(null);
    const [allLenders, setAllLenders] = useState(null);
    const [lenderId, setLenderId] = useState(null);
    const { loanNumber } = useParams();
    const { isDailyProcessingWrite, isAdmin, isDealerBrowser } = useRole();

    const history = createBrowserHistory();

    const toggleForceComplete = () => setShowForceComplete(!showForceComplete);
    const toggleConfirmation = () => setShowConfirmationBox(!showConfirmationBox);
    const toggleAlert = () => setShowAlert(!showAlert);

    const handleCommentChange = (event) => {
        setComment(event.target.value);
    };

    const handleCommentTypeChange = (event) => {
        setCommentCategory(event.target.value);
    };

    const toggleMarkBouncedClick = (event) => {
        setMarkBouncedPayment(!markBouncedPayment);
    };

    const handleFRPRModal = (event) => {
        setShowFRPRModal(!showFRPRmodal);
    }

    const toggleReturnProcessedModal = (event) => {
        setShowReturnsProcessedMarkerModal(!showReturnsProcessedMarkerModal);
    }

    const toggleIssueReturnClick = (event) => {
        setIssueReturnPayment(!issueReturnPayment);
    };

    const cycleAlert = () => {
        if(alertData.length - 1 === 0) toggleAlert();
        setAlertData(alertData.slice(1, alertData.length));
    }

    const toggleVisionStats = () => {
        setShowVisionStats(!showVisionStats);
    }

    const handleLenderChange = (event) => {
        const previousLenderId = lenderId;
        const newLenderId = event.target.value;
        setLenderId(newLenderId);
        LoanService.updateLender(loanNumber, newLenderId)
            .then(res => {
                if (res.status !== 200) {
                    console.error("Failed to save Lender Update to loan");
                    createErrorNotification('Failed to save lender, try refreshing the page and re-saving');
                    setLenderId(previousLenderId); // reset on screen lender to be in sync with backend
                } else {
                    createNotification('Successfully Updated Lender');
                }
            })
            .catch(err => {
                console.error(err);
                createErrorNotification('Failed to save lender, try refreshing the page and re-saving');
                setLenderId(previousLenderId); // reset on screen lender to be in sync with backend
            })
    }

    const handleCommentSubmit = async (event) => {
        if (comment && comment.length > 0) {
            const response = await LoanService.newLoanComment(loanNumber, comment, commentCategory);

            if (response.status && response.status === 200) {
                // TODO: Improve me
                setTimeout(() => window.location.reload(), 100);
            } else {
                createErrorNotification(`Failed to save comment, please contact support`);
            }
        } else {
            createErrorNotification(`Can't create blank comment`);
        }
    }

    const handleCommentDelete = async (event) => {
        if(currentCommentId) {
            const response = await LoanService.deleteLoanComment(currentCommentId);

            if (response.status && response.status === 200) {
                // TODO: Improve me
                setTimeout(() => window.location.reload(), 100);
            } else {
                createErrorNotification(`Failed to remove comment, please contact support`);
            }

        } else {
            createErrorNotification(`Failed to identify comment, please contact support`);
        }
    }

    useEffect(() => {
        LoanService.getSingleLoanVerbose(loanNumber).then(data => {
            setLoanHistoryData(data);
            if (data?.lender?.id) {
                setLenderId(data?.lender?.id);
            }
        });
        LoanService.getSingleLoanVisionCalculations(loanNumber).then(data => {
            if (data != null) {
                setLoanVisionFeeCalc(data);
            }
        }).catch(error => {
            setLoanVisionFeeCalc(false);
        });
        LoanService.getLoanCommentsForLoan(loanNumber).then(data => setCommentData(data.comments));
        LoanService.getLoanPopupsForLoan(loanNumber).then(data => {
            setAlertData(data?.comments);
            if(data?.comments?.length > 0) setShowAlert(true);
        });
        LoanService.getLoanDelinquenciesForLoan(loanNumber).then(data => setLoanDelinquencyData(data));
        LoanService.getLoanDelinquencyCommentsForLoan(loanNumber).then(data => setLoanDelinquencyCommentData(data?.comments));
        LoanService.getLoanMilestones(loanNumber).then(response => {
            setLoanMilestones(response?.data?.milestones)
        });
        LenderService.getAll().then(data => setAllLenders(data)); /** @todo seems to sometimes be set as null? weird. */
    }, [loanNumber]); //does this really need to be tied to loanNumber? this makes more sense to be tied to first load...

    useTheme();

    useEffect(() => {
        setCalculatorTitle(!showVisionStats ? 'Calculator' : 'Calculator (Vision)'  );

        const visionCalc = () => {
            return (loanVisionFeeCalc != null) ?
            (
                <Fragment>
                    <li>&nbsp;&nbsp;{centsToDollar(loanVisionFeeCalc.loan_amount)} <b>(Loan Amount)</b></li>
                    {loanVisionFeeCalc.activation_cents && loanVisionFeeCalc.concierge_cents !== "0" ? <li>- {centsToDollar(loanVisionFeeCalc.activation_cents)} <b>(Activation Fee)</b></li> : null}
                    {loanVisionFeeCalc.concierge_cents && loanVisionFeeCalc.concierge_cents !== "0" ? <li>- {centsToDollar(loanVisionFeeCalc.concierge_cents)} <b>(Concierge Fee)</b></li> : null}
                    {loanVisionFeeCalc.stage_funding_cents ? <li>- {centsToDollar(loanVisionFeeCalc.stage_funding_cents)} <b>(Staged Funding Cost)</b></li> : null}
                    {loanVisionFeeCalc.buydown_cents ? <li>- {centsToDollar(loanVisionFeeCalc.buydown_cents)} <b>(Buy Down Cost)</b></li> : null}
                    {/*loanVisionFeeCalc.stip_cents? <li>- {centsToDollar(loanHistoryData.stip_cents)} <b>(StipPay Cost)</b></li> : null*/}
                    {loanVisionFeeCalc.promo_cents ? <li>- {centsToDollar(loanVisionFeeCalc.promo_cents)} <b>(Promo Cost)</b></li> : null}
                    {loanVisionFeeCalc.pop_cents? <li>- {centsToDollar(loanHistoryData.pop_cents)} <b>(POP Promo)</b></li> : null}
                    <li>= {centsToDollar(loanVisionFeeCalc.dealer_net_proceeds)} <b>(Dealer Net Proceeds)</b></li>
                </Fragment>
            ) : (null);
        }

        const defaultCalc = () => {
            return (loanHistoryData != null) ?
            (
                <Fragment>
                    <li>&nbsp;&nbsp;{centsToDollar(loanHistoryData.amount)} <b>(Loan Amount)</b></li>
                    {loanHistoryData.activationFee ? <li>- {centsToDollar(loanHistoryData.activationFee)} <b>(Activation Fee)</b></li> : null}
                    {loanHistoryData.conciergeFee && loanHistoryData.conciergeFee !== "0" ? <li>- {centsToDollar(loanHistoryData.conciergeFee)} <b>(Concierge Fee)</b></li> : null}
                    {loanHistoryData.hasStagedFunding ? <li>- {centsToDollar(loanHistoryData.stageFundingCostCents)} <b>(Staged Funding Cost)</b></li> : null}
                    {loanHistoryData.hasBuyDown ? <li>- {centsToDollar(loanHistoryData.buyDownCostCents)} <b>(Buy Down Cost)</b></li> : null}
                    {loanHistoryData.hasStipPay ? <li>- {centsToDollar(loanHistoryData.stipPayCostCents)} <b>(StipPay Cost)</b></li> : null}
                    {loanHistoryData.hasBalPay || loanHistoryData.hasOHNO ? <li>- {centsToDollar(loanHistoryData.promoCostCents)} <b>(Promo Cost)</b></li> : null}
                    {loanHistoryData.hasPOP ? ( <li>- {centsToDollar(loanHistoryData.popPromoProgramFeeCents)} <b>(POP Promo)</b></li> ) : null}
                    <li>= {centsToDollar(loanHistoryData.dealerNetProceeds)} <b>(Dealer Net Proceeds)</b></li>
                    {loanHistoryData.hasOHNO || loanHistoryData.hasBalPay ? ((loanHistoryData.hasBalPay ? (<p><b>Balance Pay Promo</b></p>) : (<p><b>{loanHistoryData.ohnoPromoTerm} month OHNO Promo</b></p>))) : null}

                </Fragment>

            ) : (null);

        }

        setCalcDisplay(!showVisionStats ? defaultCalc : visionCalc);

    }, [showVisionStats, loanVisionFeeCalc, loanHistoryData]);

    function handleClick(event) {
        event.preventDefault();
        history.push("/lms/loans")
    }

    const handleForceCompleteClick = () => {
        toggleForceComplete();
    }

    const handleShowConfirmationClick = (id) => {
        setCurrentCommentId(id);
        toggleConfirmation();
    }

    return (loanHistoryData === null || loanMilestones === null) ? (<h1>Loading...</h1>) : (loanHistoryData.hasOwnProperty('loans') && loanHistoryData.loans === false) ? (<h1>Failed to find loan by loanNumber: {loanNumber}</h1>) :
        (<div id="loans-container">
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href="/lms/loans" onClick={handleClick}>
                Loan Browser
            </Link>
            <Typography color="textPrimary">{loanNumber}</Typography>
          </Breadcrumbs>
          <Grid container spacing={2} justifyContent="flex-end" direction="row">
            <Grid item>
                { isDailyProcessingWrite() ? (<Button Button variant="contained" color="warning"  onClick={handleForceCompleteClick}>Force Loan Payment</Button>): null}
            </Grid>
            {/* <Grid item>
              <Button variant="contained" className={classes.modalButton}>Cancel</Button>
            </Grid> */}
          </Grid>
          <div className={classes.pageHeader}>
          <LoanTypeIcon className={classes.pageHeaderLoanType} loanType={loanHistoryData.loanType}/>
            <h1>Loan: {loanNumber}</h1>
            <Chip
                className={classes.pageHeaderChip}
                label={loanHistoryData.loanStatus.toUpperCase()}
                style={{backgroundColor: getLoanStatusColor(loanHistoryData.loanStatus)}}
            />
          </div>

          <Fragment>
            <ReturnsProcessedMarkerModal onCancel={toggleReturnProcessedModal} loanPaymentHistory={loanHistoryData.history} showModal={showReturnsProcessedMarkerModal}/>
            <LoanForceComplete showForceComplete={showForceComplete} toggleForceComplete={toggleForceComplete} loanHistoryData={loanHistoryData} />
            <ConfirmationModal title="Confirm" message="Are you sure you want to delete comment?" onConfirm={handleCommentDelete} onCancel={toggleConfirmation} showConfirmationBox={showConfirmationBox}/>
            <BounceMarkerModal title="Confirm" message="Please select the payment or return that bounced" onCancel={toggleMarkBouncedClick} loanPaymentHistory={loanHistoryData.history} showBounceDialog={markBouncedPayment}/>
            <ReturnIssueModal title="Confirm" message="Please select a payment to issue a return" onCancel={toggleIssueReturnClick} loanPaymentHistory={loanHistoryData.history} showReturnDialog={issueReturnPayment}/>
            <FRPRModal
                title="Prior Deleted FRs/Payments/Returns"
                loanNumber={loanNumber}
                open={showFRPRmodal}
                onClose={handleFRPRModal}
            />
            <AlertModal title="A pop-up alert is on file:" message={alertData && alertData.length > 0 ? alertData[0].comment : ""} onAccept={cycleAlert} showAlertBox={showAlert}/>
            {(loanMilestones.fundingMilestone || loanMilestones.paymentMilestone) && (
                <div className={classes.milestones}>
                    <h2>Milestones:</h2>
                    {loanMilestones.fundingMilestone &&
                        <Chip
                            label={loanMilestones.fundingMilestone === 'pending' ? 'Pending CU Funding Completed Today' : 'CU Funding Completed'}
                            style={{marginLeft: '15px', marginTop: '15px', backgroundColor: (loanMilestones.fundingMilestone === 'pending' ? blue[500] : green[500])}}
                        />
                    }
                    {loanMilestones.paymentMilestone &&
                        <Chip
                            label={loanMilestones.paymentMilestone === 'pending' ? 'Pending Dealer Payments Completed Today' : 'Dealer Payments Completed'}
                            style={{marginLeft: '15px', marginTop: '15px', backgroundColor: (loanMilestones.paymentMilestone === 'pending' ? blue[500] : green[500])}}
                        />
                    }
                </div>
                )
            }
            <Grid container direction="row">
                <Grid item className={classes.summary}>
                    <h3 className={classes.summaryHeader}>Details</h3>
                    <ul className={classes.summaryUL}>
                      <li><b>Status Codes:</b> {formatCodes(loanHistoryData.loanCodes, isAdmin()).join(', ')}</li>
                      <Tooltip arrow placement="left" title={
                          loanHistoryData.hasStagedFunding ?
                          <Fragment>
                              <div>Payment 1: {centsToDollar(loanHistoryData.firstPaymentAmount)}</div>
                              <div>Payment 2: {centsToDollar(loanHistoryData.secondPaymentAmount)}</div>
                              <div>Payment 3: {centsToDollar(loanHistoryData.finalPaymentAmount)}</div>
                          </Fragment> :
                          `Payment Amount: ${centsToDollar(loanHistoryData.firstPaymentAmount)}`}>
                              <li><b>Dealer Payments:</b> {
                                  FundingStagesCell({
                                      "hasStagedFunding": loanHistoryData.hasStagedFunding,
                                      "paidStages": loanHistoryData.paidStages,
                                      "heldStages": loanHistoryData.heldStages,
                                      "numberOfPaymentStages": loanHistoryData.numberOfPaymentStages
                                  })}
                              </li>
                      </Tooltip>
                      <li><b>Amount:</b> {centsToDollar(loanHistoryData.amount)}</li>
                      <li><b>Int Accrual:</b> {aprToPercent(loanHistoryData.interestAccrual)}</li>
                      <li><b>APR:</b> {`${aprToPercent(loanHistoryData.apr)}${loanHistoryData.hasOHNO ? ` (0% for ${loanHistoryData.ohnoPromoTerm} months)` : ''}`}</li>
                      <li><b>Term:</b> {loanHistoryData.term}</li>
                      <li><b>Project Type:</b> {loanHistoryData.projectType}</li>
                      <li><b>Structure Tier:</b> {loanHistoryData.structureTier}</li>
                      {
                        loanHistoryData.visionId &&
                        <li><b>Vision Link:</b> <a href={loanHistoryData.visionLoanUrl} target="_blank" rel="noopener noreferrer">Go to Vision Page</a></li>
                      }
                    </ul>
                </Grid>
                <Grid item className={classes.summary}>
                    <h3 className={classes.summaryHeader}>People</h3>
                    <ul className={classes.summaryUL}>
                      <li><b>Borrower:</b> {loanHistoryData.applicant?.fullName}</li>
                      <li><b>Borrower Email:</b> {loanHistoryData.applicant?.email}</li>
                      {loanHistoryData.coapplicant ? <li><b>CoBorrower:</b> {loanHistoryData.coapplicant?.fullName}</li> : null}
                      {loanHistoryData.coapplicant ? <li><b>CoBorrower Email:</b> {loanHistoryData.coapplicant?.email}</li> : null}
                      <li><b>Dealer Number:</b> {isAdmin() || isDealerBrowser() ? <a href={`/dealers/${loanHistoryData.dealer?.dealerNumber}`}>{loanHistoryData.dealer?.dealerNumber}</a> : loanHistoryData.dealer?.dealerNumber}</li>
                      <li><b>Dealer:</b> {loanHistoryData.dealer?.name}</li>
                      <Tooltip title="Merchant app count at time of P4 loan creation: TOTAL / FUNDED / IN PROGRESS"><li><b>Dealer Apps:</b> {loanHistoryData.merchantAppCount}</li></Tooltip>
                      <li><b>Organization:</b> {loanHistoryData.dealer?.organization?.name}</li>
                      <li><b>Sponsor-Affiliate:</b> {loanHistoryData.sponsorAffiliate}</li>
                      <li><b>Concierge Dealer:</b> {(loanHistoryData.dealer?.isConcierge ? "Yes" : "No")}</li>
                      <li><b>Lender:</b> {
                        isDailyProcessingWrite() ?
                            <TextField
                                id="outlined-select-lender-native"
                                select
                                label="Lender"
                                value={lenderId}
                                onChange={handleLenderChange}
                                className={classes.selectInput}
                                SelectProps={{
                                    native: true,
                                }}
                                variant="outlined"
                                >
                                {allLenders.map((option) => (
                                    <option key={option.shortName} value={option.id}>
                                        {option.shortName}
                                    </option>
                                ))}
                            </TextField> :
                            loanHistoryData.lender?.shortName
                        }
                      </li>
                    </ul>
                    {loanHistoryData.dealer?.isManualFlagged ? (<p><b>Warning: Dealer is manually flagged because '{loanHistoryData.dealer?.manualFlaggedReason}'</b></p>) : null}
                </Grid>
                <Grid item className={classes.summary}>
                    <h3 className={classes.summaryHeader}>{calculatorTitle}</h3>
                    <ul className={classes.calculatorUL}>
                        {calcDisplay}
                    </ul>
                    <h3 className={classes.summaryHeader}>Payments</h3>
                    <ul className={classes.summaryUL}>
                        <li><b>Monthly Payment:</b> {loanHistoryData.monthlyPayment ? centsToDollar(loanHistoryData.monthlyPayment) : null}</li>
                        <li><b>Current Amount Due:</b> {loanHistoryData.currentAmountDue ? centsToDollar(loanHistoryData.currentAmountDue) : null}</li>
                        <li><b>Date Last Paid:</b> {loanHistoryData.dateLastPaid ? formatDate(loanHistoryData.dateLastPaid) : 'N/A'}</li>
                        <li><b>Next Due Date:</b> {loanHistoryData.nextDueDate ? formatDate(loanHistoryData.nextDueDate) : null}</li>
                        <li><b>Total Past Due:</b> {loanHistoryData.totalPastDue ? centsToDollar(loanHistoryData.totalPastDue) : null}</li>
                        <li><b>Total Balance Due:</b> {loanHistoryData.totalBalanceDue ? centsToDollar(loanHistoryData.totalBalanceDue) : null}</li>
                    </ul>

                    { (loanVisionFeeCalc === false) ?
                        (null) : (
                            <Tooltip title="Toggle Vision Calculator">
                                <IconButton
                                    className={classes.calculatorBtn}
                                    onClick={() => toggleVisionStats()}>
                                    <DialpadIcon/>
                                </IconButton>
                            </Tooltip>
                        )
                    }
                </Grid>
            </Grid>

            <Grid container direction="row">
                <Grid item className={classes.secondRow}>
                    <h3 className={classes.summaryHeader}>History</h3>
                    <div className={classes.historyTimelineWrapper}>
                        <Timeline>
                            {gatherHistory(loanHistoryData.history)}
                        </Timeline>
                    </div>
                    {isAdmin() && isDailyProcessingWrite()
                        ? <div className={classes.summaryDownload}>
                             <Tooltip title="Mark Return as Processed">
                                <IconButton onClick={() => toggleReturnProcessedModal()}>
                                    <AssignmentTurnedIn/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Deleted FR, Payments, & Icons">
                                <IconButton onClick={() => handleFRPRModal()}>
                                    <Dvr/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Mark Bounced Payments and Returns">
                                <IconButton onClick={() => toggleMarkBouncedClick()}>
                                    <MoneyOffIcon/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Issue Return for Payment">
                                <IconButton onClick={() => toggleIssueReturnClick()}>
                                    <PaymentIcon/>
                                </IconButton>
                            </Tooltip>
                        </div>
                        : null
                    }
                </Grid>
                <Grid item className={classes.secondRow}>
                    <h3 className={classes.summaryHeader}>Notes</h3>
                    <form className={classes.root} noValidate autoComplete="off">
                        <TextField
                            id="outlined-select-currency-native"
                            select
                            label="Category"
                            value={commentCategory}
                            onChange={handleCommentTypeChange}
                            className={classes.commentType}
                            SelectProps={{
                                native: true,
                            }}
                            variant="outlined"
                            >
                            {commentCategories.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.value}
                                </option>
                            ))}
                        </TextField>
                        <TextField id="outlined-basic" label="Add a comment..." value={comment} onChange={handleCommentChange} variant="outlined" multiline className={classes.comment}/>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.commentButton}
                            endIcon={<SendIcon/>}
                            onClick={handleCommentSubmit}
                        >
                            Submit
                        </Button>
                    </form>
                    <div className={classes.notesWrapper}>
                        <List className={classes.root}>
                            {
                                (commentData === null) ?
                                (<h1> </h1>) :
                                commentData.map(currentComment => {
                                    return (
                                        <Fragment>
                                            <Divider variant="inset" component="li" />
                                            <ListItem alignItems="flex-start">
                                                <ListItemAvatar>
                                                    <Avatar alt={currentComment.commenter} src="/does/not/exist/1.jpg" />
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={
                                                        <Fragment>
                                                            <Typography
                                                                component="span"
                                                                className={`${classes.inline}`}
                                                            >
                                                                {currentComment.commenter} -
                                                            </Typography>
                                                            <Typography
                                                                component="span"
                                                                variant="body2"
                                                                className={`${classes.inline} ${classes.commentDivider}`}
                                                            >
                                                                {currentComment.category}
                                                            </Typography>
                                                            <Typography
                                                                component="span"
                                                                className={`${classes.inline} ${classes.commentDate}`}
                                                            >
                                                             {`(${timeAgo(currentComment.date)})`}
                                                            </Typography>
                                                        </Fragment>
                                                    }
                                                    secondary={
                                                        <Typography
                                                            component="span"
                                                            variant="body1"
                                                            className={classes.commentBody}
                                                            color="textPrimary"
                                                        >
                                                            {currentComment.comment}
                                                        </Typography>

                                                    }
                                                />
                                                {isAdmin() ? <IconButton
                                                    onClick={() => handleShowConfirmationClick(currentComment.id)}>
                                                    <CloseIcon/>
                                                </IconButton>:''}
                                            </ListItem>
                                        </Fragment>
                                    );
                                })
                            }
                        </List>
                    </div>
                    <div className={classes.summaryDownload}><Tooltip title="Download Notes"><IconButton onClick={() => LoanService.downloadCommentsFile(loanNumber)}><CloudDownloadIcon/></IconButton></Tooltip></div>
                </Grid>
                <NLSComments loanNumber={loanNumber} />
                {
                (loanHistoryData.delinquencyHistory && loanHistoryData.delinquencyHistory.length > 0) ?
                    <Grid item className={classes.secondRow}>
                        <h3 className={classes.summaryHeader}>Delinquency Record</h3>
                        <div className={classes.historyTimelineWrapper}>
                            <Timeline>
                                {loanHistoryData.delinquencyHistory ? loanHistoryData.delinquencyHistory.map(Delinquency2HTML) : null}
                            </Timeline>
                        </div>
                    </Grid>
                    : null
                }
                {
                    (loanDelinquencyData === null) ?
                    (<h1> </h1>) :
                (delinquencyCommentData && delinquencyCommentData.length > 0) ?
                    <Grid item className={classes.secondRow}>
                        <h3 className={classes.summaryHeader}>PCG Delinquency Notes</h3>
                        <div className={classes.historyTimelineWrapper}>
                            <Timeline>
                                {delinquencyCommentData ? delinquencyCommentData.sort((a,b) => {
                                    return Date.parse(b.commentDate) - Date.parse(a.commentDate);
                                }).map(DelinquencyComment2HTML) : null}
                            </Timeline>
                        </div>
                        <div className={classes.summaryDownload}><Tooltip title="Download PCG Notes"><IconButton onClick={() => LoanService.downloadPCGCommentsFile(loanNumber)}><CloudDownloadIcon/></IconButton></Tooltip></div>
                    </Grid>
                    : null
                }
                <Grid item className={classes.secondRow}>
                    <h3 className={classes.summaryHeader}>Loan Hold History</h3>
                    <LoanHoldAuditLog loanNumber={loanNumber} />
                </Grid>
                <Grid item className={classes.secondRow}>
                    <h3 className={classes.summaryHeader}>Loan Notifications</h3>
                    <LoanNotificationsModal loanNumber={loanNumber} />
                </Grid>
            </Grid>
          </Fragment>
        </div>
        );
    }

export default Loan;

import React, { Fragment, useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { centsToDollar } from '../../utils/formatters';
import FeesTable from './FeesTable';
import { FeeService } from '../../services';
import DownloadFeeReportButton from './DownloadFeeReportButton';

const styles = makeStyles((theme) => ({
    root: {},
    summaryContainer: {
        paddingLeft: '50px',
        paddingRight: '50px',
        width: '85vw'
    },
    summaryHeader: {
        textAlign: 'center'
    },
    summaryTotals: {
        paddingTop: '10px',
        paddingBottom: '10px'
    },
    summaryBody: {
        paddingTop: '10px',
        paddingBottom: '10px'
    },
    summary: {
        backgroundColor: theme.palette.grey.main,
        borderRadius: '5px',
        border: 'groove',
        borderColor: 'black',
        borderWidth: '0.5px',
    },
    table: {
        marginTop: '30px',
        width: '85vw',
        marginRight: '5vw'
    }
}));

const Fees = ({ selectedDate }) => {
    const [feeSummary, setFeeSummary] = useState(null);
    const [payments, setPayments] = useState(null);
    const classes = styles();
    useTheme();

    useEffect(() => {
        FeeService.getFeeSummary(selectedDate).then(data => {
            setFeeSummary(data);
        })

        FeeService.getFeePayments(selectedDate).then(data => {
            setPayments(data);
        })
    }, [selectedDate]);

    return feeSummary === null  ? (
        <h1>Loading...</h1>
    ) : ( !feeSummary.length ? <h1>No Fees For Selected Date</h1> :
        <Fragment>
            <Grid container direction="column">
                <Grid item className={classes.summaryContainer}>
                    <h3 className={classes.summaryHeader}>Summary</h3>
                    <Grid container direction="column" className={classes.summary}>
                        <Grid item >
                            <Grid item>
                                <Grid container spacing={2} justifyContent="space-evenly" direction="row" className={classes.summaryBody}>
                                    {feeSummary ? feeSummary.map((row) => (
                                    <Grid item>
                                        <p><b><u>{row.configName}</u></b></p>
                                        <li>Organization: {row.organization}</li>
                                        <li>Total Payments: {row.paymentsTotal ? centsToDollar(row.paymentsTotal) : "$0"}</li>
                                        <li>Payment Count: {row.paymentCount || 0}</li>
                                        <li>Fee %: {row.feePercentage}%</li>
                                        <li>Total Fee Amount: {row.totalFee ? centsToDollar(row.totalFee) : "$0"}</li>
                                        <DownloadFeeReportButton date={selectedDate} organization={row.organization}  />
                                    </Grid>
                                    )) : null}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={classes.table}>
                    <FeesTable payments={payments} selectedDate={selectedDate} />
                </Grid>
            </Grid>
        </Fragment>
    );
}

export default Fees;
import React, { memo, useState, Fragment } from 'react';
import { LoanService } from '../../services';
import { useNotification } from '../../contexts/NotificationContext';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import ConfirmationModal from '../../components/ConfirmationModal'
import ReportOffOutlinedIcon from '@material-ui/icons/ReportOffOutlined';
import LoadingModal from '../../components/LoadingModal';

const BulkReleaseHoldButton = ({selectedRowIndices, holds}) => {
  const [showReleaseHoldsConfirmation, setShowReleaseHoldsConfirmation] = useState(false);
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const { createErrorNotification } = useNotification();

  const toggleReleaseHoldsConfirmation = () => setShowReleaseHoldsConfirmation(!showReleaseHoldsConfirmation);
  const openLoadingModal = () => setShowLoadingModal(true);
  const closeLoadingModal = () => setShowLoadingModal(false);
  
      const handleReleaseHoldClick = () => {
        toggleReleaseHoldsConfirmation();
        openLoadingModal();
        LoanService.bulkReleaseHolds(selectedRowIndices.map(rowId => {
          return {'loanNumber': holds[rowId].loanNumber};
        })).then(response => {
          // !FIXME: Do this better!
          window.location.reload();
          closeLoadingModal();
        }).catch(error => {
          createErrorNotification('Failed to release holds');
        });
      }

      return (
        <>
          <Fragment>
              <Tooltip title={'Bulk Instant Release Selected Loans'}>
                <IconButton onClick={toggleReleaseHoldsConfirmation} >
                  <ReportOffOutlinedIcon />
                </IconButton>
              </Tooltip>
        </Fragment>
        <ConfirmationModal title="Confirm" message="Are you sure you want to bulk instant release these loans from hold? They will move to today's DPP tables" onConfirm={handleReleaseHoldClick} onCancel={toggleReleaseHoldsConfirmation} showConfirmationBox={showReleaseHoldsConfirmation}/>
        <LoadingModal
            open={showLoadingModal}
          />
        </>
      );
  }

export default memo(BulkReleaseHoldButton);
import React, { Fragment, memo, useState } from 'react';
import MUIDataTable from 'mui-datatables';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import  EditableTextCell  from '../DailyProcessing/EditableTextCell';
import { FeeService } from '../../services';
import { useRole } from '../../contexts/RolesContext';
import DeleteFeeConfigButton from './DeleteFeeConfigButton';

const tablifyFeeConfig = feeConfigs =>
feeConfigs == null ? null :
feeConfigs.map(feeConfig => {
  return [
    feeConfig.id,
    <a href={`dealers/${feeConfig.dealerNumber}`} target='_blank' rel='noopener noreferrer'>{feeConfig.dealerNumber} - {feeConfig.dealerName}</a>,
    feeConfig.configName,
    feeConfig.organization,
    feeConfig.percentageOwed,
    feeConfig.states.join(', ')
  ];
});

const FeeConfigTable = ({ feeConfigs, toggleShowNewFeeConfig }) => {
  const [selectedRowIndices, setSelectedRowsIndices] = useState([]);
  const { isAdmin } = useRole();

  const handleAddFeeConfigClick = () => {
    toggleShowNewFeeConfig();
  };

  const columns = [
    {
      name: 'Fee Config ID',
      options: {
        filter: false,
        display: false,
        viewColumns: false,
      }
    },
    {
      name: 'Dealer',
    },
    {
      name: 'Config Name',
    },
    {
      name: 'Organization',
    },
    {
      name: 'Fee %',
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            (isAdmin()) ?
            <EditableTextCell
            value={value}
            id={tableMeta.rowData[0]}
            label={'Fee %'}
            onSave={FeeService.updateFeeConfiguration}
            regex={new RegExp('^\\d{1,2}\\.\\d{2}$')}/> : value
          )
        },
      }
    },
    {
      name: 'States'
    }
  ];

  const options = {
    filterType: 'dropdown',
    print: false,
    download: false,
    rowsSelected: selectedRowIndices,
    setRowProps: (row, dataIndex, rowIndex) => {
      if (rowIndex % 2 === 0) {
        return {
          style: { background: 'snow' }
        };
      }
    },
    onRowSelectionChange: (rowsSelected, allRows) => {
      setSelectedRowsIndices(allRows.map(row => row.dataIndex));
    },
    selectToolbarPlacement: 'none',
    customToolbar: () => {
      if (selectedRowIndices.length > 0) {
        return (
          <Fragment>
            <DeleteFeeConfigButton selectedRowIndices={selectedRowIndices} potentialDeletions={feeConfigs}  />
          </Fragment>
        );
      } else {
        return (
          <Fragment>
            <Tooltip title={'Create New Fee Config'}>
              <IconButton onClick={handleAddFeeConfigClick}>
                <AddIcon />
              </IconButton>
            </Tooltip>
          </Fragment>
        );
      }
    }
  };

  return feeConfigs === null ? (
    <h1>Loading...</h1>
  ) : (
    <Fragment>
      <MUIDataTable data={tablifyFeeConfig(feeConfigs)} columns={columns} options={options} />
    </Fragment>
  );
}

export default memo(FeeConfigTable);

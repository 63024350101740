import React, {useState} from "react";
import { request } from "../services/utils";
import "./login.css";
import { useNotification } from "../contexts/NotificationContext";

export default function UpdatePassword({history, error, success}) {
    const [isFetching, setIsFetching] = useState(false)
    const {createErrorNotification, createNotification} = useNotification()

    const onSubmit = async (event) => {
        event.preventDefault();
        setIsFetching(true);
        request.post('/update-password', {
            current_password: document.getElementById("update-password-form_current-password").value,
            new_password_1: document.getElementById("update-password-form_new-password-1").value,
            new_password_2: document.getElementById("update-password-form_new-password-2").value
        }).then((response) => {
            createNotification("Succesfully updated password")
            history.push("/lms") /** @todo log user out and send to login after password update? maybe, idk */
        }).catch((error) => {
            createErrorNotification(`Error: ${error?.response?.data?.message}`)
            console.error([error.message, error.response.data.message].join(", "))
        }).finally(() => {
            setIsFetching(false)
        })
    }

    return (
        <div className="card">
            <p className="sign" align="center">Update your password below</p>
            <form disabled={isFetching} className="form1" id="login-form" onSubmit={(e) => onSubmit(e)} autocomplete="on">
                <input disabled={isFetching} id="update-password-form_current-password" name="current_password" className="pass" type="password" align="center" placeholder="Current Password" autocomplete="current-password" required></input>
                <input disabled={isFetching} id="update-password-form_new-password-1" name="new_password_1" className="pass" type="password" align="center" placeholder="New Password" autocomplete="new-password" required ></input>
                <input disabled={isFetching} id="update-password-form_new-password-2" name="new_password_2" className="pass" type="password" align="center" placeholder="Confirm New Password" autocomplete="new-password" required></input>
                {isFetching ? <p className="please-wait-text">Please wait...</p> : <button type="submit" id="submit" className="submit-pw" align="center">Update Password</button>}
            </form>
        </div>
    );
}

import React, { useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useDropzone } from 'react-dropzone';
import { DealerService } from '../../services';
import { useNotification } from '../../contexts/NotificationContext';

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        left: "30%",
        top: "30%",
        width: 600,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    dropzone: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: '2px',
        borderRadius: '2px',
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out',
    },
    modalButton: {
        marginLeft: '15px',
        float: 'right',
    },
    errorPanel: {
        backgroundColor: '#f44336',
        padding: '10px',
        borderRadius: '5px',
        margin: '15px',
    },
    progressBar: {
        margin: '10px',
    }
}));

function MyDropzone(props) {
  const classes = useStyles();
  const {setFiles} = props;

  const readFile = (file) => {
    return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onabort = () => reject('file reading was aborted')
        reader.onerror = () => reject('file reading has failed')
        reader.onload = function(){
            return resolve({data:reader.result, name:file.name, size: file.size, type: file.type});
        }
        reader.readAsArrayBuffer(file);
    });
  }

  const onDrop = useCallback(async (acceptedFiles) => {
    const uploadedFiles = await Promise.all(acceptedFiles.map(f=>{return readFile(f)}));
    setFiles(uploadedFiles);
  }, [setFiles])
  const {acceptedFiles, getRootProps, getInputProps} = useDropzone({onDrop, accept: 'text/plain'})

  const files = acceptedFiles.map(file => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  return (
    <section className="container">
        <div className={classes.dropzone} {...getRootProps()}>
            <input {...getInputProps()} />
            <p>Drag 'n' drop some files here, or click to select files</p>
        </div>
        <aside>
            <h4>Files</h4>
                <ul>{files}</ul>
        </aside>
    </section>
  )
}
  
const DealerBulkUploader = (props) => {
    const classes = useStyles();
    const [files, setFiles] = useState([]);
    const [errors, setErrors] = useState([]);
    const [savedDealerCount] = useState(0);
    const [uploadInProgress, setUploadInProgress] = useState(false);
    const { createNotification } = useNotification();

    const handleSubmit = async () => {
        setUploadInProgress(true);
        setTimeout(async () => {
            var result = await DealerService.bulkUpload(files);
            if (result.errors && result.errors.length > 0) {
                setErrors(result.errors);
            }
            
            if (result.dealersAdded && result.dealersAdded > 0) {
                createNotification(`Created ${savedDealerCount} dealers`);
            }
    
            setUploadInProgress(false);
        }, 5000)
    }
    
    const handleCancel = () => {
        props.toggleShowBulkUpload();
    }

    const body = (
        <div className={classes.paper}>
            <h2 id="simple-modal-title">Bulk Dealer Upload</h2>
            <p id="simple-modal-description">
              Provide a txt file of dealer information to upload.
            </p>
            <MyDropzone setFiles={setFiles} />
            {
                uploadInProgress ?
                    (
                        <LinearProgress className={classes.progressBar} />
                    ) :
                    null
            }
            {
                errors.length > 0 ?
                    (
                        <div className={classes.errorPanel}>
                            <p><b>Failed to save all dealers. Please edit the file and try again.</b></p>
                            <ul>
                                {errors.map(error => <li>{error}</li>)}
                            </ul>
                        </div>
                    ) :
                    null
            }
            
            <Button variant="contained" color="primary" className={classes.modalButton} onClick={handleSubmit} disabled={uploadInProgress || files.length === 0}>Upload</Button>
            <Button variant="contained" className={classes.modalButton} onClick={handleCancel}>Cancel</Button>
        </div>
    );

    return (
        <div>
            <Modal
            open={props.showBulkUpload}
            onClose={props.toggleShowBulkUpload}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            >
                {body}
            </Modal>
        </div>
    );
}

export default DealerBulkUploader;
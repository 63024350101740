import React, { useState } from 'react';
import { makeStyles, FormHelperText } from '@material-ui/core';
import { FeeService } from '../../services';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import IconButton from '@material-ui/core/IconButton';
import { useNotification } from '../../contexts/NotificationContext';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    left: "30%",
    top: "15%",
    width: '40vw',
    height: '50vh',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflowY: 'scroll',
  },
  modalButton: {
    marginLeft: '15px',
    marginTop: '15px',
    float: 'right',
  },
  coreForm: {
    height: '50vh'
  }
}));

function updateRequiredField(value, updater, errorUpdater) {
  if (isBlank(value)) {
    errorUpdater(true);
    return true;
  } else {
    errorUpdater(false);
    updater(value);
    return false;
  }
}

function isBlank(value) {
  return value == null || value.trim().length === 0;
}

const NewFeeConfig = (props) => {
  const classes = useStyles();
  const [dealerNumber, setDealerNumber] = useState(null);
  const [dealerNumberError, setDealerNumberError] = useState(false);
  const [configName, setConfigName] = useState(null);
  const [configNameError, setConfigNameError] = useState(false);
  const [organization, setOrganization] = useState(null);
  const [organizationError, setOrganizationError] = useState(false);
  const [percentageOwed, setPercentageOwed] = useState(null);
  const [percentageOwedError, setPercentageOwedError] = useState(false);
  const [states, setStates] = useState(null);
  const [statesError, setStatesError] = useState(false);
  const { createErrorNotification } = useNotification();

  const resetState = () => {
    setDealerNumber(null);
    setOrganization(null);
    setPercentageOwed(null);
    setStates([]);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    // First check for missing required fields
    let hasMissingRequiredFields = false;
    hasMissingRequiredFields = updateRequiredField(dealerNumber, setDealerNumber, setDealerNumberError) || hasMissingRequiredFields;
    hasMissingRequiredFields = updateRequiredField(configName, setConfigName, setConfigNameError) || hasMissingRequiredFields;
    hasMissingRequiredFields = updateRequiredField(organization, setOrganization, setOrganizationError) || hasMissingRequiredFields;
    hasMissingRequiredFields = updateRequiredField(percentageOwed, setPercentageOwed, setPercentageOwedError) || hasMissingRequiredFields;
    hasMissingRequiredFields = updateRequiredField(states, setStates, setStatesError) || hasMissingRequiredFields;
    console.log(hasMissingRequiredFields); // esLint Hack

    if (dealerNumberError || isBlank(dealerNumber)) {
      createErrorNotification("Invalid dealer Number input.");
    } else if (configNameError || isBlank(configName)) {
      createErrorNotification("Invalid configuration name input.");
    } else if (organizationError || isBlank(organization)) {
      createErrorNotification("Invalid organization input.");
    } else if (percentageOwedError || isBlank(percentageOwed)) {
      createErrorNotification("Invalid percentage. Must be in format XX.XX% or X.XX%");
    } else if (statesError || isBlank(states)) {
      createErrorNotification("Invalid states, please insert at least one.");
    } else {
      const response = await FeeService.newFeeConfiguration({
        dealerNumber: dealerNumber,
        configName: configName,
        organization: organization,
        percentageOwed: percentageOwed,
        states: states,
      });

      if (response.status && response.status === 200) {
        resetState();
        props.toggleShowNewFeeConfig();
        // TODO: Improve me
        setTimeout(() => window.location.reload(), 500)
      } else if (response.status && response.status === 204) {
        createErrorNotification(`Duplicate fee configuration for organization. Cannot create fee configuration for organization with an existing fee`);
      } else {
        createErrorNotification(`Failed to save new fee config`);
      }
    }
  }

  const handleCancel = () => {
    props.toggleShowNewFeeConfig();
    resetState();
  }

  const handleDealerNumberChange = (event) => {
    const numericRegex = RegExp(/^\d+$/);;
    if (numericRegex.test(event.target.value)) {
      setDealerNumberError(false);
      setDealerNumber(event.target.value);
    } else {
      setDealerNumberError(true);
    }
  }

  const handleConfigNameChange = (event) => {
    setConfigNameError(false);
    setConfigName(event.target.value);
  }

  const handleOrganizationChange = (event) => {
    setOrganizationError(false);
    setOrganization(event.target.value);
  }

  const handlePercentageOwedChange = (event) => {
    const percentageRegex = new RegExp('^\\d{1,2}\\.\\d{2}$');
    if (percentageRegex.test(event.target.value)) {
      setPercentageOwedError(false);
      setPercentageOwed(event.target.value);
    } else {
      setPercentageOwedError(true);
    }
  }

  const handleStatesChange = (event) => {
    setStatesError(false);
    setStates(event.target.value);
  }

  const body = (
    <div className={classes.paper}>
      <h2 id="simple-modal-title">Create Fee Configuration</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <TextField label="Dealer Number" autoFocus={true} margin="normal" required={true} error={dealerNumberError} onChange={handleDealerNumberChange} />
          <FormHelperText>Dummy Dealer Number will house the ACH information which will receive the payouts via NACHA for any payment linked to the organization</FormHelperText>
        </div>
        <div>
          <TextField label="Config Name" fullWidth={true} margin="normal" required={true} error={configNameError} onChange={handleConfigNameChange} />
          <FormHelperText>Display name for given Fee Configuration</FormHelperText>
        </div>
        <div>
          <TextField label="Organization" fullWidth={true} margin="normal" required={true} error={organizationError} onChange={handleOrganizationChange} />
          <FormHelperText>All dealers belonging to this organization will have their payments included in the fee calculation and payout</FormHelperText>
        </div>
        <div>
          <TextField label="Percentage Owed" InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment>, maxLength: 5, }} margin="normal" required={true} error={percentageOwedError} onChange={handlePercentageOwedChange} />
          <FormHelperText>Fee Percentage: Must be of form X.XX or XX.XX</FormHelperText>
        </div>
        <div>
          <TextField label="States" fullWidth={true} margin="normal" required={true} error={statesError} onChange={handleStatesChange} />
          <FormHelperText>
            US States to include in the fee configuration
            <Tooltip title="Insert states separated by a comma, i.e: CA, LA, PA, NY, WA">
              <IconButton>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </FormHelperText>
        </div>
        <Grid container spacing={5} justifyContent="flex-start">
          <Grid item xs={12}>
            <Button variant="contained" color="primary" className={classes.modalButton} onClick={handleSubmit}>Create</Button>
            <Button variant="contained" className={classes.modalButton} onClick={handleCancel}>Cancel</Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );

  return (
    <div>
      <Modal
        open={props.showNewFeeConfig}
        onClose={props.toggleShowNewFeeConfig}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}

export default NewFeeConfig;
import React, {useState} from "react";
import { request } from "../services/utils";
import "./login.css";
import { useNotification } from "../contexts/NotificationContext";

export default function Login({history}) {
    const [isFetching, setIsFetching] = useState(false)
    const {createErrorNotification} = useNotification()

    const onSubmit = async (event) => {
        event.preventDefault();
        setIsFetching(true);
        request.post('/login', {
            email: document.getElementById("login-form-email-input").value,
            password: document.getElementById("login-form-password-input").value 
        }).then((response) => {
            console.log("Logged in")
        }).catch((error) => {
            createErrorNotification(`Error: ${error?.response?.data?.message}`)
            console.error([error?.message, error?.response?.data?.message].join(", "))
        }).finally(() => {
            history.push("/lms") /**@todo should be /dashboard ? */
            setIsFetching(false)
        })
    }
    return (
        <div className="card">
            <p className="sign" align="center">It’s nice to see you again!</p>
            <form disabled={isFetching} className="form1" id="login-form" onSubmit={event => onSubmit(event)} autocomplete="on">
                <input disabled={isFetching} name="email" id="login-form-email-input" className="un " type="email" align="center" placeholder="Email" autocomplete="username" required></input>
                <input disabled={isFetching} name="password" id="login-form-password-input" className="pass" type="password" align="center" placeholder="Password" autocomplete="current-password" required></input>
                {isFetching ? <p className="please-wait-text">Please wait...</p> : <button disabled={isFetching} type="submit"  value="login" id="submit" className="submit" align="center">Sign in</button>}
            </form>
            <button disabled={isFetching} onClick={() => history.push("/reset-password")} type="submit" id="submitid" className="forgotpw" align="center">Forgot password?</button>
        </div>   
    );
}

/* eslint eqeqeq: "off" */
import React, { Fragment, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextMask, FundingStagesCell, FlagCell } from '../../components';
import UpdateDealer from './UpdateDealer';
import DealerAuditLog from './DealerAuditLog';
import DealerTestTrxnLog from './DealerTestTrxnLog';
import { dealerCommentCategories } from '../../utils/constants';
import { DealerService } from '../../services';
import { centsToDollar, formatDate, timeAgo, lastFourDigits } from '../../utils/formatters';
import { useParams } from "react-router-dom";
import { mean } from 'lodash';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import SendIcon from '@material-ui/icons/Send';
import BookIcon from '@material-ui/icons/Book';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import CloseIcon from '@material-ui/icons/Close';
import ConfirmationModal from '../../components/ConfirmationModal';
import AlertModal from '../../components/AlertModal';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import LinkMatUI from '@material-ui/core/Link';
import grey from '@material-ui/core/colors/grey';
import blue from '@material-ui/core/colors/blue';
import orange from '@material-ui/core/colors/orange';
import Chip from '@material-ui/core/Chip';
import IconButton from "@material-ui/core/IconButton";
import SettingsApplicationsTwoToneIcon from '@material-ui/icons/SettingsApplicationsTwoTone';
import { useRole } from '../../contexts/RolesContext';
import { useNotification } from '../../contexts/NotificationContext';

const styles = makeStyles((theme) => ({
    notesWrapper: {
        maxHeight: '60vh',
        overflowY: 'scroll'
    },
    pageHeader: {
        display: 'flex',
        justifyContent: 'center',
    },
    pageSubHeader: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '0px'
    },
    pageHeaderChip: {
        marginTop: '20px',
        marginLeft: '40px',
        marginRight: '50px',
        fontWeight: 'bold',
        fontSize: '18px',
    },
    settingsIcon: {
        transform: 'scale(2.0)',
    },
    summary: {
        backgroundColor: theme.palette.grey.main,
        borderRadius: '5px',
        border: 'groove',
        borderColor: 'black',
        borderWidth: '0.5px',
        margin: "25px",
        padding: "10px",
        width: "25vw",
    },
    summaryHeader: {
        textAlign: 'center',
        textDecoration: 'underline',
    },
    summaryFooter: {
        textAlign: 'right',
        fontSize: '12px',
    },
    emptyFooter: {
        textAlign: 'center',
        fontSize: '12px',
    },
    summaryUL: {
        listStylePosition: 'inside',
    },
    secondRow: {
        borderRadius: '5px',
        border: 'groove',
        borderColor: 'black',
        borderWidth: '0.5px',
        margin: "25px",
        padding: "10px",
        width: "40vw",
    },
    comment: {
        margin: '10px 10px 10px 5px',
        width: '40%'
    },
    commentType: {
        margin: '10px 5px 10px 20px',
    },
    commentButton: {
        margin: '15px 10px 10px 10px',
    },
    commentDate: {
        fontSize: '12px',
    },
    commentDivider: {
        paddingLeft: '5px',
        paddingRight: '10px'
    },
    emailChips: {
        margin: '3px',
        backgroundColor: orange[300],
    }
}));

const Dealer = () => {
    const classes = styles();
    const { dealerNumber } = useParams();
    const [dealerDetails, setDealerDetails] = useState(null);
    const [dealerDelinquencyDetails, setDealerDelinquencyDetails] = useState(null);
    const [dealerPeopleFundedDetails, setDealerPeopleFundedDetails] = useState(null);
    const [dealerFundingYTD, setDealerFundingYTD] = useState(null);
    const [dealerPromoCount, setDealerPromoCount] = useState(null);
    const [dealerOutstandingFunding, setDealerOutstandingFunding] = useState(null);
    const { createErrorNotification } = useNotification();
    const [showUpdateDealer, setShowUpdateDealer] = useState(false);
    const [showConfirmationBox, setShowConfirmationBox] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertData, setAlertData] = useState(null);
    const [currentCommentId, setCurrentCommentId] = useState(null);
    const [comment, setComment] = React.useState("");
    const [commentCategory, setCommentCategory] = React.useState(dealerCommentCategories[0].value);
    const [commentData, setCommentData] = useState(null);

    // Permissions: Dealer Browser can only see non-sensitive info. If they get here otherwise, they're admin
    const { isDealerBrowser, isAdmin, isDailyProcessingWrite } = useRole();

    const toggleConfirmation = () => setShowConfirmationBox(!showConfirmationBox);
    const toggleAlert = () => setShowAlert(!showAlert);

    const cycleAlert = () => {
        if(alertData.length - 1 === 0) toggleAlert();
        setAlertData(alertData.slice(1, alertData.length));
    }

    useEffect(() => {
        DealerService.get(dealerNumber).then(data => {
            setDealerDetails(data);
        });
        DealerService.getDelinquencies(dealerNumber).then(data => {
            setDealerDelinquencyDetails(data);
        });
        DealerService.getDealerPeopleFundedMetrics(dealerNumber).then(data => {
            setDealerPeopleFundedDetails(data);
        });
        DealerService.getDealerFundingYTD(dealerNumber).then(data => {
            setDealerFundingYTD(data);
        });
        DealerService.getDealerPromoCount(dealerNumber).then(data => {
            setDealerPromoCount(data);
        });
        DealerService.getDealerCommentsForDealer(dealerNumber).then(data => setCommentData(data.comments));
        DealerService.getOutstandingFunds(dealerNumber).then(data => setDealerOutstandingFunding(data));
        DealerService.getDealerPopupsForDealer(dealerNumber).then(data => {
            setAlertData(data.comments);
            if(data.comments.length > 0) setShowAlert(true);
        });
    }, [dealerNumber]);

    const handleBreadCrumbClick = (event) => {
        event.preventDefault();
        window.location = "/dealers"; /**@todo: use history.push instead. window.location bypasses the client side routing and asks the server instead */
    }

    const handleCommentChange = (event) => {
        setComment(event.target.value);
    };

    const handleCommentTypeChange = (event) => {
        setCommentCategory(event.target.value);
    };

    const handleCommentSubmit = async (event) => {
        if (comment && comment.length > 0) {
            const response = await DealerService.newDealerComment(dealerNumber, comment, commentCategory);

            if (response.status && response.status === 200) {
                // TODO: Improve me
                setTimeout(() => window.location.reload(), 100);
            } else {
                createErrorNotification(`Failed to save comment, please contact support`);
            }
        } else {
            createErrorNotification(`Can't create blank comment`);
        }
    }

    const handleCommentDelete = async (event) => {
        if(currentCommentId) {
            const response = await DealerService.deleteDealerComment(currentCommentId);

            if (response.status && response.status === 200) {
                // TODO: Improve me
                setTimeout(() => window.location.reload(), 100);
            } else {
                createErrorNotification(`Failed to remove comment, please contact support`);
            }
        } else {
            createErrorNotification(`Failed to identify comment, please contact support`);
        }
    }

    const handleShowConfirmationClick = (id) => {
        setCurrentCommentId(id);
        toggleConfirmation();
    }

    const toggleShowUpdateDealer = () => setShowUpdateDealer(!showUpdateDealer);

    const mapDealerUpdateFields = (details) => ({
        dealerNumber: details?.dealerNumber,
        dealerName: details?.legalBusinessName,
        routingNumber: details?.routingNumber,
        accountNumber: details?.accountNumber,
        organization: details?.organization,
        affiliate: details?.affiliate,
        notificationEmails: details?.notificationEmails
    });

    return (dealerDetails === null || commentData === null) ?
        (<h1>Loading...</h1>) :
        (typeof dealerDetails === 'string') ?
            (<h1>Failed to find dealer by dealerNumber: {dealerNumber}</h1>) : (
            <div id="dealer-container">
                <Breadcrumbs aria-label="breadcrumb">
                    <LinkMatUI color="inherit" href="/dealers" onClick={handleBreadCrumbClick}>
                        Dealer Browser
                    </LinkMatUI>
                    <Typography color="textPrimary">{dealerNumber}</Typography>
                </Breadcrumbs>
                <div className={classes.pageHeader}>
                    <h1>Dealer: {dealerNumber}</h1>
                    <Chip
                        className={classes.pageHeaderChip}
                        label={dealerDetails.isActive ? 'ACTIVE' : 'DE-ACTIVATED'}
                        style={{backgroundColor: (dealerDetails.isActive ? blue[500] : grey[500])}}
                    />
                    { isAdmin() &&
                        <>
                            <IconButton onClick={() => {toggleShowUpdateDealer()}}>
                                <SettingsApplicationsTwoToneIcon className={classes.settingsIcon} />
                            </IconButton>
                            <FlagCell value={dealerDetails.isManuallyFlagged} dealerNumber={dealerNumber} flagReason={dealerDetails.manualFlaggedReason} />
                        </>
                    }
                </div>
                <div className={classes.pageSubHeader}>
                    <h1>{dealerDetails.legalBusinessName}</h1>
                </div>
                <ConfirmationModal title="Confirm" message="Are you sure you want to delete comment?" onConfirm={handleCommentDelete} onCancel={toggleConfirmation} showConfirmationBox={showConfirmationBox}/>
                <AlertModal title="A pop-up alert is on file:" message={alertData && alertData.length > 0 ? alertData[0].comment : ""} onAccept={cycleAlert} showAlertBox={showAlert}/>
                <Grid container direction="row">
                    <Grid item className={classes.summary}>
                        <h3 className={classes.summaryHeader}>Details</h3>
                        <ul className={classes.summaryUL}>
                            <li><b>Organization:</b> {dealerDetails.organization ? dealerDetails.organization : `None`}</li>
                            <li><b>Affiliate:</b> {dealerDetails.affiliate ? dealerDetails.affiliate : `None`}</li>
                            <li><b>Concierge:</b> {dealerDetails.isConcierge ? "Yes" : "No"}</li>
                            <li><b>FinCEN ID:</b> {dealerDetails.hasFincenId ? "Yes" : "No"}</li>
                            <li><b>Avg Borrower Age:</b> {dealerPeopleFundedDetails && dealerPeopleFundedDetails.averageAge ? dealerPeopleFundedDetails.averageAge : "No data"}</li>
                            <li><b>Avg Borrower Credit:</b> {dealerPeopleFundedDetails && dealerPeopleFundedDetails.averageScore ? dealerPeopleFundedDetails.averageScore : "No data"}</li>
                            { isDealerBrowser() ?
                                <Fragment>
                                    <li><b>Routing Number:</b>{lastFourDigits(dealerDetails.routingNumber)}</li>
                                    <li><b>Account Number:</b>{lastFourDigits(dealerDetails.accountNumber)}</li>
                                </Fragment> :
                                <Fragment>
                                    <li><b>Routing Number:</b> <TextMask value={dealerDetails.routingNumber} /></li>
                                    <li><b>Account Number:</b> <TextMask value={dealerDetails.accountNumber} /></li>
                                    <li><b>NotificationEmails:</b> {dealerDetails.notificationEmails != null ?
                                        dealerDetails.notificationEmails.map(email => <Chip label={email} className={classes.emailChips} />) : null}
                                    </li>
                                </Fragment>

                            }
                        </ul>
                    </Grid>
                    <Grid item className={classes.summary}>
                        <h3 className={classes.summaryHeader}>Status</h3>
                        <ul className={classes.summaryUL}>
                            <li><b>Created:</b> {formatDate(dealerDetails.created)}</li>
                            <li><b>Last Updated:</b> {formatDate(dealerDetails.updated)}</li>
                            <li><b>First Funding Date:</b> {dealerDetails.firstFundingDate ? (formatDate(dealerDetails.firstFundingDate)) : 'N/A'}</li>
                            <li><b>Last Funding Date:</b> {dealerDetails.lastFundingDate ? (formatDate(dealerDetails.lastFundingDate)) : 'N/A'}</li>
                            <li><b>Account Missing ACH Details:</b> {dealerDetails.needsReview ? "Yes" : "No"}</li>
                            <li><b>Manually Flagged:</b> {dealerDetails.isManuallyFlagged ? "Yes" : "No"}</li>
                            {dealerDetails.isManuallyFlagged && <li><b>Manually Flagged Reason:</b> {dealerDetails.manualFlaggedReason}</li>}
                            <li><b>Test Trxns:</b> {dealerDetails.testTransStatus.toUpperCase()}</li>
                            <li><b>90-Day Avg Credit:</b> {dealerDetails.ninetyDayAvgCreditScore ? dealerDetails.ninetyDayAvgCreditScore : 'N/A'}</li>
                            <li><b>90-Day Loans Paid:</b> {dealerDetails.ninetyDayPaidLoans ? dealerDetails.ninetyDayPaidLoans : 'N/A'}</li>
                            <li><b>90-Day Delinquencies:</b> {dealerDetails.ninetyDayDeliqLoans ? dealerDetails.ninetyDayDeliqLoans  : 'N/A'}</li>
                            <li><b>90-Day Delinquency Rate:</b> {dealerDetails.ninetyDayDelinquencyRate}%</li>

                        </ul>
                    </Grid>
                    <Grid item className={classes.summary}>
                        <h3 className={classes.summaryHeader}>Numbers</h3>
                        <ul className={classes.calculatorUL}>
                            <li>
                                <b>Funded Loans:</b> {dealerDetails.totalFundedLoans ? <a href={'/lms'} target='_blank' rel='noopener noreferrer' onClick={() => {localStorage.setItem("dealerNumber",dealerDetails.dealerNumber);} }> {dealerDetails.totalFundedLoans} </a> : 0}
                            </li>
                            <li><b>Total of Loans:</b> {centsToDollar(dealerDetails.totalLoanAmount)}</li>
                            <li><b>Average Loan:</b> {centsToDollar(dealerDetails.averageLoanAmount)}</li>
                            <li><b>Max Loan:</b> {centsToDollar(dealerDetails.maxLoanAmount)}</li>
                            <li><b>Delinquent Loans:</b> {(dealerDelinquencyDetails == null || dealerDelinquencyDetails.length === 0 ? 0 :
                                <a href={'/lms'} target='_blank' rel='noopener noreferrer' onClick={() => {localStorage.setItem("dealerNumber",dealerDetails.dealerNumber); localStorage.setItem("delinquency",true)} }> {dealerDelinquencyDetails.length} </a>)}
                            </li>
                            <li><b>Delinquency Rate:</b> {(dealerDelinquencyDetails == null || dealerDetails.totalFundedLoans == null || dealerDetails.totalFundedLoans == 0 ? 0 : Math.trunc(100 * (dealerDelinquencyDetails.length / dealerDetails.totalFundedLoans)))}%</li>
                            <li><b>Delinquent for 30+ Days Rate:</b> {dealerDelinquencyDetails && dealerDetails.totalFundedLoans && dealerDetails.totalFundedLoans !== 0 ? `${Math.trunc(100 * dealerDelinquencyDetails.filter(detail => detail.daysPastDue > 30).length / dealerDetails.totalFundedLoans)}%` : '0%'}</li>
                            <li><b>Avg Days Delinquent:</b> {(dealerDelinquencyDetails == null || dealerDelinquencyDetails.length == 0 ? 0 : Math.trunc(mean(dealerDelinquencyDetails.map((detail) => {return detail.daysPastDue}))))}</li>
                            <li><b>Funded YTD:</b> {dealerFundingYTD ? centsToDollar(dealerFundingYTD.totalFundedYTD): 0}</li>
                            <li><b>Loans in LAM:</b> {dealerDetails.lamNumber ? dealerDetails.lamNumber: 0}</li>
                            <li><b>Closed Loans:</b> {dealerDetails.totalClosedLoans ? dealerDetails.totalClosedLoans: 0}</li>
                            <li><b>Refunds Issued:</b> {dealerDetails.refundsIssued ? dealerDetails.refundsIssued: 0}</li>
                            <li><b>Total Refunded:</b> {dealerDetails.totalRefunded ? centsToDollar(dealerDetails.totalRefunded): 0}</li>
                            <li><b>Funded Promo Loans:</b> {dealerPromoCount ? dealerPromoCount.totalPromos : 0}</li>
                            <li><b>Percent Funded Promo:</b> {(dealerPromoCount && dealerDetails.totalFundedLoans && dealerDetails.totalFundedLoans > 0) ? Math.trunc(100 * (dealerPromoCount.totalPromos/dealerDetails.totalFundedLoans)) + "%" : "0%"}</li>
                            <li><b>Canceled Loans:</b> {(dealerDetails.numCanceledLoans && dealerDetails.canceledAmount ?
                                <a href={'/lms'} target='_blank' rel='noopener noreferrer' onClick={() => {localStorage.setItem("dealerNumber",dealerDetails.dealerNumber);localStorage.setItem("loanStatusCode","PIC")} }> {`${dealerDetails.numCanceledLoans} (${centsToDollar(dealerDetails.canceledAmount)})`} </a> : 0)}
                            </li>
                        </ul>
                    </Grid>
                </Grid>
                <Grid container direction="row">
                    <Grid item className={classes.secondRow}>
                        <h3 className={classes.summaryHeader}>Notes</h3>
                        { isAdmin() ? <form className={classes.root} noValidate autoComplete="off">
                            <TextField
                                id="outlined-select-currency-native"
                                select
                                label="Category"
                                value={commentCategory}
                                onChange={handleCommentTypeChange}
                                className={classes.commentType}
                                SelectProps={{
                                    native: true,
                                }}
                                variant="outlined"
                                >
                                {dealerCommentCategories.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.value}
                                    </option>
                                ))}
                            </TextField>
                            <TextField id="outlined-basic" label="Add a comment..." value={comment} onChange={handleCommentChange} variant="outlined" multiline className={classes.comment}/>
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.commentButton}
                                endIcon={<SendIcon/>}
                                onClick={handleCommentSubmit}
                            >
                                Submit
                            </Button>
                        </form> : ''}
                        <div className={classes.notesWrapper}>
                            <List className={classes.root}>
                                {
                                    commentData.map(currentComment => {
                                        return (
                                            <Fragment>
                                                <Divider variant="inset" component="li" />
                                                <ListItem alignItems="flex-start">
                                                    <ListItemAvatar>
                                                        <Avatar alt={currentComment.commenter} src="/does/not/exist/1.jpg" />
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        primary={
                                                            <Fragment>
                                                                <Typography component="span" className={`${classes.inline}`} >
                                                                    {currentComment.commenter} -
                                                                </Typography>
                                                                <Typography component="span" variant="body2" className={`${classes.inline} ${classes.commentDivider}`} >
                                                                    {currentComment.category}
                                                                </Typography>
                                                                <Typography component="span" className={`${classes.inline} ${classes.commentDate}`} >
                                                                    {`(${timeAgo(currentComment.date)})`}
                                                                </Typography>
                                                            </Fragment>
                                                        }
                                                        secondary={
                                                            <Typography component="span" variant="body1" className={classes.commentBody} color="textPrimary" >
                                                                {currentComment.comment}
                                                            </Typography>
                                                        }
                                                    />
                                                    { isAdmin() ?
                                                        <IconButton onClick={() => handleShowConfirmationClick(currentComment.id)}>
                                                            <CloseIcon/>
                                                        </IconButton> : ''
                                                    }
                                                </ListItem>
                                            </Fragment>
                                        );
                                    })
                                }
                            </List>
                        </div>
                    </Grid>
                {isAdmin() || isDealerBrowser() ?
                <Grid item className={classes.secondRow}>
                    <Tooltip title='Loans displayed here have had FIRST stage payments made, but are awaiting FINAL stage payments.' placement="top">
                        <h3 className={classes.summaryHeader}>Open Stage Funding</h3>
                    </Tooltip>
                    {dealerOutstandingFunding ?
                        dealerOutstandingFunding.length === 0 ?
                            <Fragment>
                                <h3 className={classes.emptyFooter}>No Partially Paid SF Loans</h3>
                            </Fragment> :
                            <Fragment>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                        <TableRow>
                                            <TableCell><b>Loan&nbsp;Number</b></TableCell>
                                            <TableCell align="right"><b>Paid&nbsp;to&nbsp;Date</b></TableCell>
                                            <TableCell align="right"><b>Funding&nbsp;Rem.</b></TableCell>
                                            <TableCell align="right"><b>Dealer&nbsp;Net&nbsp;Proc.</b></TableCell>
                                            <TableCell align="right"><b>Days&nbsp;Open</b></TableCell>
                                            <TableCell align="right"><b>Payments</b></TableCell>
                                        </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {dealerOutstandingFunding.map((row) => (
                                            <TableRow>
                                                <TableCell><BookIcon style={{ color: orange[300] }}/><a href={`/lms/loans/${row.loanNumber}`}>{row.loanNumber}</a></TableCell>
                                                <TableCell align="right">{centsToDollar(row.paymentHistory)}</TableCell>
                                                <TableCell align="right">{centsToDollar(row.fundingRemaining)}</TableCell>
                                                <TableCell align="right">{centsToDollar(row.dealerNetProceeds)}</TableCell>
                                                <TableCell align="right">{row.daysOpen}</TableCell>
                                                <TableCell align="right">{FundingStagesCell({"hasStagedFunding": row.hasStagedFunding, "paidStages": row.paidStages, "heldStages": row.heldStages, "hasForcedPayment": row.hasForcedPayment, "numberOfPaymentStages": row.numberOfPaymentStages})}</TableCell>

                                            </TableRow>
                                        ))}
                                        </TableBody>
                                    </Table>
                                    </TableContainer>
                                <h3 className={classes.summaryFooter}>Count: {dealerOutstandingFunding.length}, Total Remaining Funding: {centsToDollar(dealerOutstandingFunding.reduce((sum, loan) => {return sum + parseInt(loan.fundingRemaining || 0)},0))}</h3>
                            </Fragment>
                        : null
                    }
                </Grid> : null
                }
                { isAdmin() || isDailyProcessingWrite() ?
                    <Grid item className={classes.secondRow}>
                        <h3 className={classes.summaryHeader}>Test Transactions</h3>
                        <DealerTestTrxnLog dealerNumber={dealerNumber} />
                    </Grid>
                    : null
                }
                { isAdmin() || isDailyProcessingWrite() ?
                    <Grid item className={classes.secondRow}>
                        <h3 className={classes.summaryHeader}>Dealer History</h3>
                        <DealerAuditLog dealerNumber={dealerNumber} />
                    </Grid>
                    : null
                }
            </Grid>
                { isAdmin() &&
                    <UpdateDealer
                        showUpdateDealer={showUpdateDealer}
                        currentDealer={mapDealerUpdateFields(dealerDetails)}
                        toggleShowUpdateDealer={toggleShowUpdateDealer}
                        callback={() => setTimeout(() => window.location.reload(), 100)}
                    />
                }
            </div>
        );
}

export default Dealer;
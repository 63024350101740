/*eslint no-unused-vars: ["error", { "varsIgnorePattern": "[nN]otification" }]*/
import React, {useState} from 'react';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

const NotificationContext = React.createContext();
const { Consumer: NotificationConsumer, Provider } = NotificationContext;

function NotificationProvider({ children }) {
    const [notification, setNotification] = useState({
        visible: false,
        message: null,
        config: {},
    });

    const clearNotification = () => {
        setNotification(state => ({
            ...state,
            visible: false,
            config: {}
        }));
    };

    const createNotification = (message, config) => {
        clearNotification();
        setNotification({
            visible: true,
            message,
            config: { ...config, severity: 'success' },
        });
    };

    const createErrorNotification = (message, config) => {
        clearNotification();
        setNotification({
            visible: true,
            message,
            config: { ...config, severity: 'error' },
        });
    }

    return (
        <Provider value={{createNotification, createErrorNotification}} >
            <Snackbar open={notification.visible} autoHideDuration={4000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} onClose={() => clearNotification()} >
                <Alert onClose={() => clearNotification()} {...notification.config} >
                    {`${notification.message}`}
                </Alert>
            </Snackbar>
            {children}
        </Provider>
    )
}

function useNotification() {
    const context = React.useContext(NotificationContext);
    if (context === undefined) {
        throw new Error('useNotification must be used within a NotificationProvider');
    }

    return context;
}

export { NotificationProvider, useNotification }
import React, { Fragment, memo, useState } from 'react';
import MUIDataTable from 'mui-datatables';
import { DealerService } from '../../services';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { centsToDollar, sortDollarColumn, formatDate } from '../../utils/formatters';
import { dealerNeedsReviewCell } from '../../utils/cellRenderers';
import { FlagCell } from '../../components';
import ConfirmationModal from '../../components/ConfirmationModal';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import VerifiedUserTwoTone from '@material-ui/icons/VerifiedUserTwoTone';
import InlineLoadingText from '../../components/InlineLoadingText';


  const tablifyTestTransactions = testTransactions =>
    testTransactions == null ? null :
    testTransactions.map(testTransaction => {
      return [
        testTransaction.dealerID,
        testTransaction.dealerNumber,
        testTransaction.dealerName,
        testTransaction.testAmount,
        testTransaction.testStatus,
        testTransaction.testCreated,
        testTransaction.sendDate,
        testTransaction.confirmDate,
        testTransaction.organization,
        testTransaction.dealerAccountFlag,
        testTransaction.dealerManualFlag,
        testTransaction.manualFlaggedReason,
        testTransaction.testCreated,
        testTransaction.isManuallyFlagged,
      ];
    });

  const TestTransactionTable = ({ testTransactions }) => {
      const [selectedRowIndices, setSelectedRowsIndices] = useState([]);
      const [showConfirmation, setShowConfirmation] = useState(false);
      const handleVerifyClick = () => {

        const potentialConfirms = selectedRowIndices.map(rowId => {
          return testTransactions[rowId];
        });
        const definiteConfirms = potentialConfirms.filter(testTrxn => {
          return testTrxn.testStatus === 'sent'
        });
        
        Promise.all([DealerService.verifyBulkTestTransactions(definiteConfirms.map(trxn => {return trxn.id;})),
          DealerService.verifyBulkDealers(definiteConfirms.map(trxn => {return trxn.dealerId;}))]).then(response => {
          // !FIXME: Do this better!
          setTimeout(() => window.location.reload(), 500);
        });
        toggleConfirmation();
      };
      const toggleConfirmation = () => {
        setShowConfirmation(!showConfirmation);
      }
      const columns = [
        {
          name: 'Dealer ID',
          options: {
            filter: false,
            display: false,
            viewColumns: false
          }
      },
      {
        name: 'Dealer Number',
        options: {
          filter: true,
          customBodyRender: (value, tableMeta) => {
            return <a href={`/dealers/${value}`}>{value}</a>
          }
        }
      },
      {
        name: 'Name',
        options: {
            filter: false
        }
      },
      {
        name: 'Trxn Amount',
        options: {
          customBodyRender: (value, tableMeta) => {
            return centsToDollar(value);
          },
          sortCompare: sortDollarColumn
        }
      },
      {
        name: 'Trxn Status',
        options: {
            filter: true,
            customBodyRender: (value, tableMeta) => {
              return value.toUpperCase();
            },
        }
      },
      {
        name: 'Created',
        options: {
            filter: true,
            display: true,
            viewColumns: true,
            customBodyRender: (value, tableMeta) => {
              return value ? formatDate(value) : null;
            },
        }
      },
      {
        name: 'Send Date',
        options: {
            filter: true,
            display: true,
            viewColumns: true,
            customBodyRender: (value, tableMeta) => {
              return value ? formatDate(value) : null;
            },
        }
      },
      {
        name: 'Confirmed',
        options: {
            filter: true,
            display: true,
            viewColumns: true,
            customBodyRender: (value, tableMeta) => {
              return value ? (<Fragment><div style={{pointerEvents: 'none'}}><VerifiedUserTwoTone/>{formatDate(value)}</div></Fragment>) : null;
            },
        }
      },
      {
        name: 'Organization',
        options: {
          filter: false
        }
      },
      {
        name: "Account Flag",
        options: {
          customBodyRender: (value, tableMeta) => { 
            return dealerNeedsReviewCell(testTransactions[tableMeta.rowIndex].needsReview); 
          }
        }
      },
      {
        name: "Manual Flag",
        options: {
          customBodyRender: (value, tableMeta) => {
            return <FlagCell value={testTransactions[tableMeta.rowIndex].isManuallyFlagged} dealerNumber={tableMeta.rowData[1]} flagReason={tableMeta.rowData[10]} style={{pointerEvents: 'all'}}/>
          }
        }
      },
      {
        name: 'Manual Flag Reason',
        options: {
            filter: false,
            display: false,
            viewColumns: true
        }
      },
      
      ];

      const options = {
        filterType: 'dropdown',
        print: false,
        download: false,
        searchOpen: true,
        rowsPerPage: 50,
        rowsPerPageOptions: [50,100,250],
        rowsSelected: selectedRowIndices,
        setRowProps: (row, dataIndex, rowIndex) => { 
          if (rowIndex % 2 === 0) {
            return {
              style: { background: 'snow' }
            };
          }
        },
        onRowSelectionChange: (rowsSelected, allRows) => {
          setSelectedRowsIndices(allRows.map(row => row.dataIndex));
        },
        selectToolbarPlacement: 'none',
        customToolbar: () => {
          return (
            <Fragment>
              {selectedRowIndices.length > 0 ?
                <Tooltip title={'Confirm All Selected Trxns'}>
                  <IconButton onClick={toggleConfirmation}> 
                    <VerifiedUserIcon />
                  </IconButton>
                </Tooltip> : null}
            </Fragment>
        );
        }
      };
      
      return testTransactions === null ? (
        <InlineLoadingText/>
      ) : (testTransactions.length === 0 ? 
          <h1>No Test Trxns</h1> : 
          (
          <Fragment>
            <ConfirmationModal title="Confirm" message="Are you sure you want to verify ALL selected dealer test transactions?"  showConfirmationBox={showConfirmation} onConfirm={handleVerifyClick} onCancel={toggleConfirmation}/>
            <MUIDataTable data={tablifyTestTransactions(testTransactions)} columns={columns} options={options} />
          </Fragment>
          )
        );   
  }

  export default memo(TestTransactionTable);
import React, { memo, Fragment, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import MUIDataTable from 'mui-datatables';
import CustomSearchBar from '../../components/CustomSearchBar';
import { customTableSearch } from '../../utils/tableHelpers.js';
import { formatDate } from '../../utils/formatters';
import { getNotificationCategory } from '../../utils/constants';
import Chip from '@material-ui/core/Chip';
import { booleanIconCell } from '../../utils/cellRenderers';
import { LoanService } from '../../services';
import { useNotification } from '../../contexts/NotificationContext';
import ResendNotificationCell from '../../components/ResendNotificationCell'
import { truncate } from 'lodash';
import Tooltip from "@material-ui/core/Tooltip";

const columns = [
    {
        name: 'Message',
        options: {
            filter: false,
            customBodyRender: (values) => {
                try{
                    return (<Fragment><HtmlTooltip interactive title={<Fragment><div dangerouslySetInnerHTML={{__html: values}}/></Fragment>}><span>{truncate(values, {'length': 50})}</span></HtmlTooltip></Fragment>)
                } catch(err) {
                    console.log(err)
                }
            }

        }
    },
    {
        name: 'Recipients',
        options: {
            filter: false,
            customBodyRender: (values) => {

                if (values !== null) {
                    return values.map(value => <Chip label={value} />)
                } else {
                    return null;
                }

            }
        }
    },
    {
        name: 'Sent?',
        options: {
            customBodyRender: booleanIconCell,
            customFilterListOptions: {
                render: v => v ? "Sent" : "Not Sent",
            }
        }
    },
    {
        name: 'Error Message'
    },
    {
        name: 'Notification Date'
    },
    {
        name: 'Category',
        options: {
            filter: true
        }
    },
    {
        name: 'Resend Notification (Latest Resend Date)',
        options: {
            filter: false,
            customBodyRender: (value) => {
                return <ResendNotificationCell resendDate={value[0]} notificationId={value[1]} />
            }
        }
    }
];

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 1000,
    },
}))(Tooltip);

const tablifyNotifications = notifications => notifications == null ? null : notifications.map(notification => {
    return [
        notification.message,
        notification.recipients,
        notification.isSent,
        notification.error,
        formatDate(notification.sentDate),
        getNotificationCategory(notification.category),
        [notification.resendDate, notification.id]
    ];
});

const getTableOptions = (selectedDate, errorNotification) => {
    return {
        filterType: 'dropdown',
        print: false,
        download: true,
        onDownload: () => {
            LoanService.downloadNotificationPreview(selectedDate).then(data => {}).catch(error => {
                errorNotification('Failed to Download Notification Preview');
            });

            return false;
        },
        selectableRows: false,
        setRowProps: (row, dataIndex, rowIndex) => {
            if (rowIndex % 2 === 0) {
                return {
                    style: { background: 'snow' }
                };
            }
        },
        textLabels: {
            toolbar: {
                downloadCsv: "Download Notification Preview"
            }
        },
    };
};

const NotificationsTable = ({notifications, selectedDate}) => {
    const { createErrorNotification } = useNotification();
    const [matchExactText, setmatchExactText] = useState(true);
    const options = getTableOptions(selectedDate, createErrorNotification);
    const handleMatchExactText = (event) => {
        setmatchExactText(event.target.checked);
    }

    options.customSearchRender = (searchText, handleSearch, hideSearch, options) => {
        return (
            <CustomSearchBar
                searchText={searchText}
                handleSearch={handleSearch}
                hideSearch={hideSearch}
                options={options}
                matchExactText={matchExactText}
                handleMatchExactText={handleMatchExactText}
                tooltipText="For multiple searches, enter the terms separated by a space."
            />
        );
    };

    options.customSearch = (searchQuery, currentRow, columns) => {
        return customTableSearch(searchQuery, currentRow, columns, matchExactText);
    }

    return notifications === null ? (
        <h1>Loading...</h1>
    ) : (
        <MUIDataTable data={tablifyNotifications(notifications)} columns={columns} options={options} />
    )
}

export default memo(NotificationsTable);
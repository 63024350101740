import React, { useState, useEffect } from "react";
import Cookies from 'js-cookie';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from "@material-ui/core/DialogContentText";
import { formatCountdown } from '../utils/formatters';
import { AccountService } from '../services';

const RefreshModal = () => {
  const [showModal, setShowModal] = useState(false);
  const [expiresIn, setExpiresIn] = useState(Cookies.get('expiresIn') ? JSON.parse(Cookies.get('expiresIn')) : null);
  const [remainingTime, setRemainingTime] = useState(Cookies.get('remainingTime') ? JSON.parse(Cookies.get('remainingTime')) : null);

const handleLogoutClick = async () => {
    setShowModal(false);
    AccountService.logout().then(response => {
      setExpiresIn(null);
      setRemainingTime(null);
      if (response.redirect === '/login'){
        window.location = "/login";
      }
    })
}

 const handleStayLoggedIn = async () => {
    AccountService.refreshToken().then(response => {
      if (response && response.status === 200) {
        setExpiresIn(null);
        setRemainingTime(null);
        setShowModal(false);
        setTimeout(() => window.location.reload(), 250);
      }});
 };

 useEffect(() => {
  const handleLogout = async () => {
    if (remainingTime !== null &&expiresIn !== null && Cookies.get('remainingTime') !== null && Cookies.get('expiresIn') !== null) {
      if (remainingTime <= 0 && showModal) {
        setShowModal(false);
        setExpiresIn(null);
        setRemainingTime(null);
        const response = await AccountService.logout();
        if (response.redirect === '/login') {
          window.location = "/login";
        }
      } else if (!showModal && remainingTime > 0 && remainingTime < 300) {
        setShowModal(true);
      }

    }
  };

  handleLogout();
}, [remainingTime, showModal, expiresIn]);

// Adapted off of https://michell-e.medium.com/session-timeout-in-react-2bcf87b12b2
useEffect(() => {
  if(Cookies.get('remainingTime')) {
    const countdownInterval = setInterval(() => {
      setRemainingTime((prevRemainingTime) =>
        prevRemainingTime > 0 ? prevRemainingTime - 1 : 0
      );
      Cookies.set('remainingTime', remainingTime > 0 ? remainingTime - 1 : 0);
    }, 1000);
    return () => {
      clearInterval(countdownInterval);
    };
  }
});

useEffect(() => {
  const checkCookies = () => {
    const expiresInCookie = Cookies.get('expiresIn');
    const remainingTimeCookies = Cookies.get('remainingTime');

    if (expiresInCookie !== undefined && remainingTimeCookies !== undefined) {
      setExpiresIn(JSON.parse(expiresInCookie));
      setRemainingTime(JSON.parse(remainingTimeCookies));
    } else {
      setTimeout(checkCookies, 1000);
    }
  };

  checkCookies();
}, []);

  return (process.env.REACT_APP_DEPLOY_ENV === "LOCAL") ? null : 
  (
    <div>
        <Dialog
          open={showModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Session Timeout
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Time remaining:&nbsp;&nbsp;&nbsp;
              {formatCountdown(remainingTime)}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleLogoutClick} color="primary">
              Logout
            </Button>
            <Button onClick={handleStayLoggedIn} color="primary" >
              Stay Logged In
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  );
}

export default RefreshModal;
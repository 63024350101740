import React from 'react';
import CheckCircle from '@material-ui/icons/CheckCircle';
import { makeStyles } from '@material-ui/core';
import { green } from '@material-ui/core/colors';


const styles = makeStyles((theme, props) => ({
    success: {
        fill: `${green[600]}`,
        width: '40px',
        height: '40px',
        marginLeft: '0px',
        marginRight: '0px',
        marginTop: '0px',
        marginBottom: '0px',
        paddingLeft: '0px',
        paddingRight: '0px',
        paddingTop: '0px',
        paddingBottom: '0px',
        pointerEvents: 'none'
        
    },
}));


const SuccessIcon = () => {
    const classes = styles();
    return(
        <CheckCircle className={classes.success}/>

    );

} 

export default SuccessIcon;
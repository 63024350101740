import React from 'react';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import GavelIcon from '@material-ui/icons/Gavel';
import { DealerService } from '../services';
import { green, red } from '@material-ui/core/colors';
import { useNotification } from '../contexts/NotificationContext';

const FincenIdCell = (props) => {
    const { createErrorNotification, createNotification } = useNotification();
    const disableUpdates = (props.disableUpdates === true);
    let message = '';

    const handleToggleClick = async () => {
        sendUpdate(!props.value, props.dealerNumber, props.dealerName);
    }

    const sendUpdate = async (hasFlag, dealerNumber, dealerName) => {
        try {
            const response = await DealerService.updateDealerHasFincenIDFlag(hasFlag, dealerNumber);

            if (response.status && response.status === 200) {
                createNotification(`${dealerName}'s FincenID Flag has been updated - Reloading...`);
                setTimeout(() => window.location.reload(), 2000)
            } else {
                createErrorNotification(`Failed to update ${dealerName}'s FincenID Flag`);
            }
        } catch (err) {
            createErrorNotification(`Failed to update ${dealerName}'s FincenID Flag`);
        }
    }

    if (disableUpdates) {
        if (props.value) {
            message = 'This dealer has Fincen ID';
        } else {
            message = 'This dealer does not have Fincen ID';
        }
    } else {
        message = `Click to ${props.value ? 'un' : '' }check Fincen ID flag`;
    }

    return (
        <Tooltip title={message}>
            {
                disableUpdates ? (
                    <GavelIcon fontSize="small" style={ { color: props.value ? green[500] :  red[500] } } />
                ) : (
                    <IconButton onClick={handleToggleClick}>
                        <GavelIcon fontSize="small" style={ { color: props.value ? green[500] :  red[500] } } />
                    </IconButton>
                )
            }
        </Tooltip>
    );
}

export default FincenIdCell;

import React, { Fragment, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import PullHistoryTable from './PullHistoryTable';
import { LoanService } from '../../services';
import { useNotification } from '../../contexts/NotificationContext';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import InlineLoadingText from '../../components/InlineLoadingText';

const styles = makeStyles((theme) => ({
    root: {
    },
    table: {
        marginTop: '30px',
        width: '85vw',
        marginRight: '5vw'
    }
}));

const PullHistory = ({ selectedDate }) => {
    const [pullHistory, setPullHistory] = useState(null);
    const [fetchAllPullHistory, setFetchAllPullHistory] = useState(false);
    const { createErrorNotification } = useNotification();
    const classes = styles();
    useTheme();

    useEffect(() => {
        if (pullHistory === null) {
            LoanService.getLatestPullHistoryByDate(selectedDate).then(response => {
                if (response && response.status === 200 && response.data) {
                    setPullHistory(response.data);
                } else {
                    createErrorNotification("Failed to retrieve latest pull history logs");
                }
            });
        }
    }, [pullHistory, createErrorNotification, selectedDate]);

    useEffect(() => {
        if(!fetchAllPullHistory) {
            LoanService.getLatestPullHistoryByDate(selectedDate).then(response => {
                if (response && response.status === 200 && response.data) {
                    setPullHistory(response.data);
                } else {
                    createErrorNotification("Failed to retrieve latest pull history logs");
                }
            });
        } else {
            LoanService.getPullHistoryByDate(selectedDate).then(response => {
                if (response && response.status === 200 && response.data) {
                    setPullHistory(response.data);
                } else {
                    createErrorNotification("Failed to retrieve all pull history logs");
                }
            });
        }
    }, [fetchAllPullHistory, createErrorNotification, selectedDate]);
    
    const toggleFetchAllPullHistory = () => {
        setFetchAllPullHistory(!fetchAllPullHistory);        
    }
  
    return pullHistory === null ? (
        <InlineLoadingText/>
      ) : (
        <Fragment>
        <Grid container direction="column">
            <Grid item className={classes.table}>
                <PullHistoryTable pullHistory={pullHistory} selectedDate={selectedDate}  toggleFetchAllPullHistory={toggleFetchAllPullHistory} fetchAllPullHistory={fetchAllPullHistory} />
            </Grid>
        </Grid>
    </Fragment>
    )
}

export default PullHistory;
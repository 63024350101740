import React, { Fragment, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { centsToDollar } from '../../utils/formatters';
import ReturnsTable from './ReturnsTable';
import NewReturn from './NewReturn';
import ErrorIcon from '@material-ui/icons/Error';
import { ReturnTypeMetricBreakdown } from '../../components';

const styles = makeStyles((theme) => ({
    root: {
      },
      summaryContainer: {
        paddingLeft: '50px',
        paddingRight: '50px',
        width: '85vw'
      },
      summaryHeader: {
          textAlign: 'center'
      },
      summaryBody: {
        paddingTop: '10px',
        paddingBottom: '10px'
      },
      summary: {
        backgroundColor: theme.palette.grey.main,
        borderRadius: '5px',
        border: 'groove',
        borderColor: 'black',
        borderWidth: '0.5px',
      },
      table: {
          marginTop: '30px',
          width: '85vw',
          marginRight: '5vw'
      }
}));

const Returns = ({ dailyProcessingData, selectedDate }) => {
    const classes = styles();
    useTheme();

    const [showNewReturn, setShowNewReturn] = useState(false);
    const {returnsSummary, returns} = dailyProcessingData || {};

    const toggleShowNewReturn = () => setShowNewReturn(!showNewReturn);

    return dailyProcessingData === null ? (
        <h1>Loading...</h1>
      ) : (
        <Fragment>
            <Grid container direction="column">
                <Grid item className={classes.summaryContainer}>
                    <h3 className={classes.summaryHeader}>Summary</h3>
                    <Grid container direction="column" className={classes.summary}>
                        <Grid container justifyContent="space-evenly" className={classes.summaryBody}>
                            <Grid item>
                                <ReturnTypeMetricBreakdown loanType={'Home Improvement'} totalStat={returnsSummary[0].totalReturnAmountHI || 0} countStat={returnsSummary[0].totalReturnNumberHI || 0} >
                                </ReturnTypeMetricBreakdown>
                            </Grid>
                            <Grid item>
                                <p><b><u>Returns</u></b></p>
                                <li>Total Returns: {returnsSummary[0].totalReturnAmount ? centsToDollar(returnsSummary[0].totalReturnAmount) : "$0"}</li>
                                <li>Total Returns Count: {returnsSummary[0].totalReturnNumber || 0}</li>
                            </Grid>
                            {returnsSummary[0].totalBouncedReturnNumber > 0 ?
                            <Grid item>
                                <p><ErrorIcon color='error' /><b><u>Bounces</u></b></p>
                                <li>Bounced Payments: {returnsSummary[0].totalBouncedReturnNumber || 0}</li>
                            </Grid> : null}
                            <Grid item className={classes.summaryTotals}>
                                <ReturnTypeMetricBreakdown loanType={'Healthcare'} totalStat={returnsSummary[0].totalReturnAmountHC || 0} countStat={returnsSummary[0].totalReturnNumberHC || 0}>
                                </ReturnTypeMetricBreakdown>
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="space-evenly" className={classes.summaryBody}>
                            <Grid item>
                            {returns.some((returnItem) => {
                                return returnItem.noPaymentsCompleted === true;
                            }) ? (
                                <Grid>
                                    <p><b><u>Alerts</u></b></p>
                                    <li>A return exists for a loan that has had no payments completed yet.</li>
                                </Grid>
                            ) : null}</Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={classes.table}>
                    <ReturnsTable returns={returns} selectedDate={selectedDate} toggleShowNewReturn={toggleShowNewReturn} />
                </Grid>
            </Grid>
            <NewReturn showNewReturn={showNewReturn} toggleShowNewReturn={toggleShowNewReturn} selectedDate={selectedDate} />
        </Fragment>
    );
}

export default Returns;

import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import LoanTypeIcon from './LoanTypeIcon';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { centsToDollar } from '../utils/formatters';

import grey from '@material-ui/core/colors/grey';

const styles = makeStyles(() => ({
    breakdownText: {
        color: `${grey[900]} !important`,
        cursor: "pointer",
        fontSize: '10pt',
        lineHeight: '5px'
    },
    iconStyle: {
        paddingTop: '0px',
        paddingBottom: '0px',
        marginTop: '0px',
        marginBottom: '0px',
    },
}));

const LoanTypeMetricBreakdown = (props) => {
    const classes = styles();
    const {loanType, totalStat, countStat, additionalMetrics} = props || {};
    const {avgAge, avgCreditScore, avgTerm} = additionalMetrics || {};
    if(loanType) {
        return (
            <div>
                <IconButton className={classes.iconStyle} disabled variant="contained">
                    <LoanTypeIcon fontSize={'5vh'} loanType={loanType}> </LoanTypeIcon>
                    <Typography className={classes.breakdownText} variant="body1" align="left" component={'span'}><b></b>
                        <p><b>Total: </b>{centsToDollar(totalStat)}</p>
                        <p><b>Count: </b>{countStat}</p>
                        <p><b>Avg Loan: </b>{countStat > 0 ? centsToDollar(totalStat / countStat) : centsToDollar(0)}</p>
                        {additionalMetrics && (
                            <>
                                <p><b>Avg Age: </b>{avgAge}</p>
                                <p><b>Avg CS: </b>{avgCreditScore}</p>
                                <p><b>Avg Term: </b>{avgTerm}</p>
                            </>
                        )}
                    </Typography>
                </IconButton>
            </div>
        );
    } else {
        return (
            <div>
                Error loading loan type breakdown.
            </div>
        );
    }
}

export default LoanTypeMetricBreakdown;

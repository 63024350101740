import React, { useState }  from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ConfirmationModal from '../components/ConfirmationModal';
import TextField from '@material-ui/core/TextField';
import green from '@material-ui/core/colors/green';
import InputAdornment from '@material-ui/core/InputAdornment';
import { LoanService } from '../services';
import { useNotification } from '../contexts/NotificationContext';
const styles = makeStyles(() => ({
    submitButton: {
        marginLeft: '10px',
        marginTop: '25px'
    },
    confirmButton: {
        backgroundColor: green[400],
        float: 'right',
        marginTop:'16px',
        marginRight:'4px'

    },
    inputAmount: {
        float: 'right',
        marginTop: '8px',
        marginRight:'4px',
        marginBottom: '4px'
    }


}));




const SyncPaymentFRReturnWithHubspot = (props) => {
    const { createErrorNotification } = useNotification();
    const [loanID, setLoanID] = useState(null); 
    const handleChange = (event) => setLoanID(event.target.value)
    const [showConfirmConfirmationBox, setShowConfirmConfirmationBox] = useState(false);
    const toggleConfirmConfirmation = () => setShowConfirmConfirmationBox(!showConfirmConfirmationBox);

    const handleConfirmSyncButtonClick = async (event) => {
        
        toggleConfirmConfirmation();

    }
    
    const handleConfirmSyncButton = async (event) => {
        try{
            const response = await LoanService.syncPaymentFRReturnWithHubspot(loanID);

                if(response.status === 200)
                {
                    toggleConfirmConfirmation();
                } else {
                    toggleConfirmConfirmation();
                    createErrorNotification("Failed to sync with Hubspot");
                }
        }catch(err)
        {
            toggleConfirmConfirmation();
            createErrorNotification("Failed to sync with Hubspot");
        }
        
    }

    const classes = styles();
    return(

    <div>
        <ConfirmationModal title="Confirm" message="Are you sure you want to sync the loan's payment, funding request, and returns with hubspot?" onConfirm={handleConfirmSyncButton} onCancel={toggleConfirmConfirmation} showConfirmationBox={showConfirmConfirmationBox}/>
        
        <Button variant="contained" 
        className={classes.confirmButton} 
        onClick={handleConfirmSyncButtonClick}
        >submit
        </Button>
        <TextField
        id="outlined-select-currency-native"
        className={classes.inputAmount}
        value={loanID}
        placeholder="PP473473"
        onChange={handleChange}
        label="Loan #"
        variant="outlined"
        InputProps={{
            startAdornment: <InputAdornment position="start">Optional</InputAdornment>,
        }}
        ></TextField>
        
    </div>

    )
    
}

export default SyncPaymentFRReturnWithHubspot;
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { LoanService } from '../../services';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';
import { useNotification } from '../../contexts/NotificationContext';
import { numericRegex, isBlank, updateRequiredField } from '../../utils/dailyPaymentFormHelpers';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    left: "30%",
    top: "15%",
    width: '40vw',
    height: '50vh',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflowY: 'scroll',
  },
  modalButton: {
    marginLeft: '15px',
    marginTop: '15px',
    float: 'right',
  },
  coreForm: {
    height: '50vh'
  }
}));

const NewHold = (props) => {
  const classes = useStyles();
  const [loanNumber, setLoanNumber] = useState(null);
  const [loanNumberError, setLoanNumberError] = useState(false);
  const [holdComment, setHoldComment] = useState(null);
  const { createErrorNotification } = useNotification();
    
  const resetState = () => {
    setLoanNumber(null);
    setHoldComment(null);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    // First check for missing required fields
    let hasMissingRequiredFields = false;
    hasMissingRequiredFields = updateRequiredField(loanNumber, setLoanNumber, setLoanNumberError) || hasMissingRequiredFields;
    console.log(hasMissingRequiredFields); // esLint Hack

    if (loanNumberError || isBlank(loanNumber)) {
      createErrorNotification("Invalid loan number. Must be in format '12345'");
    } else {
      const response = await LoanService.newHold(('PP' + loanNumber), holdComment);

      if (response.status && response.status === 200) {
        resetState();
        props.toggleShowNewHold();
        // TODO: Improve me
        setTimeout(() => window.location.reload(), 500)
      } else {
        createErrorNotification("Failed to save new hold");
      }
    }
  }

  const handleCancel = () => {
    props.toggleShowNewHold();
    resetState();
  }

  const handleLoanNumberChange = (event) => {
    if (numericRegex.test(event.target.value)) {
      setLoanNumberError(false);
      setLoanNumber(event.target.value);
    } else {
      setLoanNumberError(true);
    }
  }

  const handleHoldCommentChange = (event) => {
      setHoldComment(event.target.value);
  }

  const body = (
    <div className={classes.paper}>
      <h2 id="simple-modal-title">Create Hold</h2>
        <form onSubmit={handleSubmit}>
          <TextField label="Loan Number" inputProps={{ maxLength: 10 }} InputProps={{startAdornment: <InputAdornment position="start">PP</InputAdornment>,}} autoFocus={true} required={true} error={loanNumberError} onChange={handleLoanNumberChange} />
          <TextField label="Comment" onChange={handleHoldCommentChange} fullWidth={true} />
          <Grid container spacing={5} justifyContent="flex-start">
            <Grid item xs={12}>
              <Button variant="contained" color="primary" className={classes.modalButton} onClick={handleSubmit}>Create</Button>
              <Button variant="contained" className={classes.modalButton} onClick={handleCancel}>Cancel</Button>
            </Grid>
          </Grid>
        </form>
    </div>
  );

  return (
    <div>
      <Modal
        open={props.showNewHold}
        onClose={props.toggleShowNewHold}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}

export default NewHold;
import React, { Fragment, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { centsToDollar } from '../../utils/formatters';

import FundingRequestsTable from './FundingRequestsTable';
import NewFundingRequest from './NewFundingRequest';
import { LoanTypeMetricBreakdown } from '../../components';

const styles = makeStyles((theme) => ({
    root: {
      },
      summaryContainer: {
        paddingLeft: '50px',
        paddingRight: '50px',
        width: '85vw'
      },
      summaryHeader: {
          textAlign: 'center'
      },
      summaryTotals: {
        paddingTop: '10px',
        paddingBottom: '10px'
      },
      summaryBody: {
        paddingTop: '10px',
        paddingBottom: '10px'
      },
      summary: {
        backgroundColor: theme.palette.grey.main,
        borderRadius: '5px',
        border: 'groove',
        borderColor: 'black',
        borderWidth: '0.5px',
      },
      table: {
          marginTop: '30px',
          width: '85vw',
          marginRight: '5vw'
      }
}));

const FundingRequests = ({ dailyProcessingData, selectedDate }) => {
    const classes = styles();
    useTheme();

    const [showNewFundingRequest, setShowNewFundingRequest] = useState(false);
    const {fundingRequestSummary, fundingRequests} = dailyProcessingData || {};

    const toggleShowNewFundingRequest = () => setShowNewFundingRequest(!showNewFundingRequest);
    
    return dailyProcessingData === null ? (
        <h1>Loading...</h1>
      ) : (
        <Fragment>
            <Grid container direction="column">
                <Grid item className={classes.summaryContainer}>
                    <h3 className={classes.summaryHeader}>Summary</h3>
                    <Grid container direction="column" className={classes.summary} >
                        <Grid item >
                            <Grid container spacing={2} justifyContent="space-evenly" direction="row" className={classes.summaryBody} >
                                <Grid item className={classes.summaryTotals}>
                                    <li>Total Requested Funding: {fundingRequestSummary[0].totalFundingAmount ? centsToDollar(fundingRequestSummary[0].totalFundingAmount) : "$0"}</li>
                                    <li>Total Funding Requests: {fundingRequestSummary[0].totalFundingNumber || 0}</li>
                                    <li>Avg Requested Funds: {centsToDollar(fundingRequestSummary[0].totalFundingAmount && fundingRequestSummary[0].totalFundingNumber ? fundingRequestSummary[0].totalFundingAmount / fundingRequestSummary[0].totalFundingNumber : 0)}</li>
                                </Grid>
                                <Grid item className={classes.summaryTotals}>
                                    <LoanTypeMetricBreakdown loanType={'Solar'} totalStat={fundingRequestSummary[0].totalFundingAmountESG || 0} countStat={fundingRequestSummary[0].totalFundingNumberESG || 0 } additionalMetrics={{
                                        avgAge: fundingRequestSummary[0].avgAgeESG || 0,
                                        avgCreditScore: fundingRequestSummary[0].avgCreditScoreESG || 0,
                                        avgTerm: fundingRequestSummary[0].avgTermESG || 0}}>
                                    </LoanTypeMetricBreakdown>
                                </Grid>
                                <Grid item className={classes.summaryTotals}>
                                    <LoanTypeMetricBreakdown loanType={'Home Improvement'} totalStat={fundingRequestSummary[0].totalFundingAmountHI || 0} countStat={fundingRequestSummary[0].totalFundingNumberHI || 0} additionalMetrics={{
                                        avgAge: fundingRequestSummary[0].avgAgeHI || 0,
                                        avgCreditScore: fundingRequestSummary[0].avgCreditScoreHI || 0,
                                        avgTerm: fundingRequestSummary[0].avgTermHI || 0}}>
                                    </LoanTypeMetricBreakdown>
                                </Grid>
                                <Grid item className={classes.summaryTotals}>
                                    <LoanTypeMetricBreakdown loanType={'Healthcare'} totalStat={fundingRequestSummary[0].totalFundingAmountHC || 0} countStat={fundingRequestSummary[0].totalFundingNumberHC || 0} additionalMetrics={{
                                        avgAge: fundingRequestSummary[0].avgAgeHC || 0,
                                        avgCreditScore: fundingRequestSummary[0].avgCreditScoreHC || 0,
                                        avgTerm: fundingRequestSummary[0].avgTermHC || 0}}>
                                </LoanTypeMetricBreakdown>
                                </Grid>
                                <Grid item className={classes.summaryTotals}>
                                    <li>Total Held Funding: {fundingRequestSummary[0].heldFundingAmount ? centsToDollar(fundingRequestSummary[0].heldFundingAmount) : "$0"}</li>
                                    <li>Total Held Requests: {fundingRequestSummary[0].heldFundingNumber || 0}</li>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid item>
                            <Grid container spacing={2} justifyContent="space-evenly" direction="row" className={classes.summaryBody} >
                                {fundingRequestSummary[0].fundingRequestsSummaryByLender ? fundingRequestSummary[0].fundingRequestsSummaryByLender.map((row) => (
                                    <Grid item key={row.id}>
                                        <p><b><u>{row.shortName}</u></b></p>
                                        <li>Total Requested Funding: {row.totalFundingAmount ? centsToDollar(row.totalFundingAmount) : "$0"}</li>
                                        <li>Funding Request Count: {row.totalFundingNumber || 0}</li>
                                    </Grid>
                                )) : null}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={classes.table}>
                    <FundingRequestsTable fundingRequests={fundingRequests} toggleShowNewFundingRequest={toggleShowNewFundingRequest} selectedDate={selectedDate} />
                </Grid>
            </Grid>
            <NewFundingRequest showNewFundingRequest={showNewFundingRequest} toggleShowNewFundingRequest={toggleShowNewFundingRequest} selectedDate={selectedDate} />
        </Fragment>
    );
}

export default FundingRequests;
/* eslint eqeqeq: "off" */
import React, { Fragment, memo } from 'react';
import MUIDataTable from 'mui-datatables';
import Tooltip from "@material-ui/core/Tooltip";
import { useNotification } from '../../contexts/NotificationContext';
import { formatDateTime } from '../../utils/formatters';
import Switch from '@material-ui/core/Switch';

const columns = [
  {
    name: 'Operation',
    options: {
      filter: true,
      filterOptions: {
        names: [
          'data dump',
          'payment',
          'return',
          'funding request',
          'delinquency',
          'delinquency comments',
          'nls all comments',
          'vision payment',
          'nls send funding requests',
          'nls send payments / returns',
          'nacha returns file',
          'nacha payments file',
          'nacha test trxns file',
          'MailSoar test emails',
          'Hubspot all loan notes',
          'Hubspot all merchant notes'
        ],
        logic(value, filters) {
            if (filters[0] === 'funding request') {
              return !value.includes('funding request');
            }
            return value !== filters[0];
        },
      },
    }
  },
  {
    name: 'Status',
    options: {
      filter: true,
      filterOptions: {
        names: ['OK', 'FAILED'],
        logic(value, filters) {
          return !value.includes(filters[0]);
        },
      },
    }
  },
  {
    name: 'Automation Initiated Date',
    options: {
      filter: false,
    }
  },
  {
    name: 'Pull Initiated By?',
  },
];

const tablifyPullHistory = pullHistory =>
  pullHistory == null ? null :
  pullHistory.map(pullHistory => {
      return [
        pullHistory.operation,
        pullHistory.operationStatus,
        formatDateTime(pullHistory.pullInitiatedDate),
        pullHistory.pullInitiatedBy
      ];
    });

  const getTableOptions = (selectedDate, errorNotification, toggleFetchAllPullHistory, fetchAllPullHistory, pullHistory) => {
      return {
        filterType: 'dropdown',
        print: false,
        download: false,
        selectableRows: false,
        rowsPerPage: pullHistory.length,
        rowsPerPageOptions: [pullHistory.length],
        search: false,
        viewColumns: false,
        setRowProps: (row, dataIndex, rowIndex) => { 
          if (rowIndex % 2 === 0) {
            return {
              style: { background: 'snow' }
            };
          }
        },
        customToolbar: () => {
          return (
            <Fragment>
            <FetchAllPullHistoryToolbarButton toggleFetchAllPullHistory={toggleFetchAllPullHistory} fetchAllPullHistory={fetchAllPullHistory}/>
            </Fragment>
          );
        }
      };
  };


  const FetchAllPullHistoryToolbarButton = ({ toggleFetchAllPullHistory, fetchAllPullHistory }) => {

    const handleFetchAllPullHistoryClick = () => {
        toggleFetchAllPullHistory();
    }

    return (
        <Fragment>
            <Tooltip title={"Only Latest Automation History"}>
            <Switch onChange={handleFetchAllPullHistoryClick} checked={!fetchAllPullHistory}>
            </Switch>
          </Tooltip>
      </Fragment>
    );
}

const PullHistoryTable = ({pullHistory, selectedDate, toggleFetchAllPullHistory, fetchAllPullHistory}) => {
  const { createErrorNotification } = useNotification();
  const options = getTableOptions(selectedDate, createErrorNotification, toggleFetchAllPullHistory, fetchAllPullHistory, pullHistory);
  
  return pullHistory === null ? (
    <h1>Loading...</h1>
    ) : (
      <Fragment>
        <MUIDataTable data={tablifyPullHistory(pullHistory)} columns={columns} options={options} />
      </Fragment>
  )
}

export default memo(PullHistoryTable);
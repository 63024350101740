import React from 'react';
import { Box, LinearProgress, makeStyles, Modal, Button} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import grey from '@material-ui/core/colors/grey';

const styles = makeStyles((theme, props) => ({
    modal: {
        backgroundColor: '#fff',
        border: '1px solid #000',
        borderRadius: '6px',
        display:'block',
        position: 'absolute', 
        top: '50%', left: '50%', 
        transform: 'translate(-50%, -50%)',
        width: '32%',
        height:'auto',
        paddingLeft: '12px',
        paddingRight: '12px',
        paddingBottom: '16px',
    },
    inline: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        paddingTop: '0px',
        marginTop: '12px',
        marginBottom: '12px',
        marginRight: '64px',
        pointerEvents: 'none'
    },
    title: {
        paddingLeft: '4px',
    },
    text: {
        paddingLeft: '12px',
        fontSize: '16px',
        color: `${grey[700]}`,
        pointerEvents: 'none'
    },
    button: {
        display: 'flex',
        alignItems: 'right',
        marginLeft: 'auto',
        marginRight: '2px',
        marginTop: '4px',
        color: '#fff',
        backgroundColor: 'transparent',
        pointerEvents: 'none'
    }
}));

const LoadingModal = (props) => {
    const classes = styles();
    return (
        <div>
          <Modal
            open={props.open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className={classes.modal}>   
                <div className={classes.inline}>
                    <Typography variant="h4" className={classes.title}>Loading...</Typography>
                </div> 
                <LinearProgress/> 
                <Button 
                    disableRipple 
                    variant="text" 
                    onClick={props.onClose} 
                    className={classes.button}
                > OK </Button>             
            </Box>
          </Modal>
        </div>
      );
}

export default LoadingModal;
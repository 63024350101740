import React, { Fragment, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { LoanHoldDiff } from '../../components';
import { LoanService } from '../../services';
import { formatDateTime } from '../../utils/formatters';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import InfoIcon from '@material-ui/icons/Info';
import orange from '@material-ui/core/colors/orange';
import ListItemText from '@material-ui/core/ListItemText';
import { useNotification } from '../../contexts/NotificationContext';


const styles = makeStyles((theme) => ({
    loanHoldHistory: {
        maxHeight: '60vh',
        overflowY: 'scroll'
    },
    commentDivider: {
        paddingLeft: '5px',
        paddingRight: '10px'
    },
    commentDate: {
        fontSize: '12px',
    },
    cutoverWarning: {
        backgroundColor: 'lightgoldenrodyellow',
        borderRadius: '10px',
        padding: '10px',
        marginTop: '10px',
    }
}));

const LoanHoldAuditLog = ({ loanNumber }) => {
    const classes = styles();
    const [loanHoldHistory, setLoanHoldHistory] = useState(null);
    const { createErrorNotification } = useNotification();

    useEffect(() => {
        LoanService.getLoanHoldHistory(loanNumber).then(data => {
            setLoanHoldHistory(data.loanHoldHistory);
        }).catch(err => {
            console.error(`Failed to load loan hold audit history for loan: ${loanNumber}`, err);
            createErrorNotification(`Failed to load loan hold audit history for loan: ${loanNumber}`);
        });
    }, [loanNumber, createErrorNotification]);

    return loanHoldHistory === null ?
        (<h1>Loading...</h1>) : 
        <div className={classes.loanHoldHistory}>
            <List>
                {
                    loanHoldHistory.map((loanHoldHistoryItem, index) => {
                        const newValue = loanHoldHistory[index - 1];

                        return loanHoldHistoryItem.changeType === 'LATEST' ? null : (
                            <>
                                <Divider variant="inset" component="li" />
                                <ListItem alignItems="flex-start">
                                    <ListItemAvatar>
                                        <Avatar alt={loanHoldHistoryItem.modifiedBy} src="/does/not/exist/1.jpg" />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <Fragment>
                                                <Typography
                                                    component="span"
                                                    className={`${classes.inline}`}
                                                >
                                                    {loanHoldHistoryItem.modifiedBy} -
                                                </Typography> 
                                                <Typography
                                                    component="span"
                                                    variant="body2"
                                                    className={`${classes.inline} ${classes.commentDivider}`}
                                                >
                                                    {loanHoldHistoryItem.changeType}
                                                </Typography> 
                                                <Typography
                                                    component="span"
                                                    className={`${classes.inline} ${classes.modificationDate}`}
                                                >
                                                {`(${formatDateTime(loanHoldHistoryItem.modificationDate)})`}
                                                </Typography>
                                            </Fragment>
                                        }
                                        secondary={
                                            <>
                                                { loanHoldHistoryItem.changeType === 'CUTOVER' ? 
                                                    <div className={classes.cutoverWarning}>
                                                        <Typography
                                                            component="span"
                                                            variant="body1"
                                                            color="textPrimary"
                                                        >
                                                                <InfoIcon style={{ color: orange[700], marginRight: '5px' }} />
                                                                {loanHoldHistoryItem.change}
                                                        </Typography>
                                                    </div>
                                                : null }
                                            </>
                                        }
                                    />
                                </ListItem>
                                <LoanHoldDiff newValue={newValue?.loanHold} oldValue={loanHoldHistoryItem.loanHold} changeType={loanHoldHistoryItem.changeType} />
                            </>
                        );
                    })
                }
            </List>
        </div>
}

export default LoanHoldAuditLog;
import React, { useState } from 'react';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FlagIcon from '@material-ui/icons/Flag';
import red from '@material-ui/core/colors/red';
import grey from '@material-ui/core/colors/grey';
import { makeStyles } from '@material-ui/core/styles';
import { DealerService } from '../services';
import { useNotification } from '../contexts/NotificationContext';
import ConfirmationModal from '../components/ConfirmationModal';


const useStyles = makeStyles((theme) => ({
    paper: {
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      top: '40%',
      left: '40%',
    },
    buttons: {
      paddingTop: '10px',
      float: 'right',
    },
    button: {
        margin: '5px',
    }
  }));

const FlagCell = (props) => {
    const classes = useStyles();
    const { createErrorNotification } = useNotification();
    const disableUpdates = (props.disableUpdates === true);
    const [open, setOpen] = useState(false);
    const [reason, setReason] = useState(null);

    const [showUnflagConfirmation, setShowUnflagConfirmation] = useState(false);
    const toggleUnflagConfirmation = () => setShowUnflagConfirmation(!showUnflagConfirmation);
    const loadUnflagConfirmation = async (event) => { 
        toggleUnflagConfirmation();
    }

    const handleToggleClick = async () => {
        // When we're setting the flag, we need to allow the reason to be set
        if (!props.value) {
            setOpen(true);
        } else {
            loadUnflagConfirmation();
        }
    }

    const sendUpdate = async (hasFlag, dealerNumber, flagReason) => {
        try {
            const response = await DealerService.updateDealerManualFlag(hasFlag, dealerNumber, flagReason);

            if (response.status && response.status === 200) {
                // TODO: Improve me
                setTimeout(() => window.location.reload(), 100)
            } else {
                createErrorNotification("Failed to update Manual Flag");
            }
        } catch (err) {
            createErrorNotification("Failed to update Manual Flag");
        }
    }

    const handleFlagSubmit = () => {
        sendUpdate(true, props.dealerNumber, reason);
    };

    const handleUnflagSubmit = () => {
        sendUpdate(false, props.dealerNumber, reason);
    };
    
    const handleClose = () => {
        setOpen(false);
    };

    const handleReasonChange = (event) => {
        setReason(event.target.value);
    }

    return (
        <>
            { !disableUpdates ? (
                <>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                    >
                        <div className={classes.paper}>
                            <h2 id="simple-modal-title">Reason for Manual Flag:</h2>
                            <form className={classes.root} noValidate autoComplete="off">
                                <TextField id="standard-basic" label="Reason" fullWidth onChange={handleReasonChange} />
                            </form>
                            <div className={classes.buttons}>
                                <Button className={classes.button} variant="contained" color="grey" onClick={handleClose}>Cancel</Button>
                                <Button className={classes.button} variant="contained" color="primary" onClick={handleFlagSubmit}>Submit</Button>
                            </div>
                        </div>
                    </Modal>
                    <ConfirmationModal 
						title="Unflagging Dealer"
						message="Are you sure you want to unflag?"
						onConfirm={handleUnflagSubmit}
						onCancel={loadUnflagConfirmation}
						showConfirmationBox={showUnflagConfirmation}/>
                    <Tooltip title={props.value ? props.flagReason ? props.flagReason : '' : "Click to toggle manual flag" }>
                            <IconButton onClick={handleToggleClick}> 
                                <FlagIcon fontSize="small" style={ props.value ? { color: red[500] } :  null } />
                            </IconButton>
                    </Tooltip>
                </>
            ) : (
                <>
                    <Tooltip title={props.flagReason || ""}>
                        <FlagIcon fontSize="small" style={ props.value ? { color: red[500], cursor: 'default' } : {color: grey[500], cursor: 'default' } } />
                    </Tooltip>
                </>
            )
            }
        </>
    );
}

export default FlagCell;
/* eslint eqeqeq: "off" */
import React, { Fragment, memo, useState } from 'react';
import MUIDataTable from 'mui-datatables';
import CustomSearchBar from '../../components/CustomSearchBar';
import { IconButton, Tooltip } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { centsToDollar, sortDollarColumn, aprToPercent, promosToChips, capitalizeString, stage2Text} from '../../utils/formatters';
import { dealerNeedsReviewCell, isConciergeCell } from '../../utils/cellRenderers';
import { saveTableSession, getTableSession } from '../../utils/sessionHelpers';
import { customTableSearch } from '../../utils/tableHelpers.js';
import { FlagCell, LoanMetricTooltip, LoanTypeIcon, DealerMetricTooltip, CommentPreviewTooltip, IssuedReturnsCell } from '../../components';
import { useRole } from '../../contexts/RolesContext';
import red from '@material-ui/core/colors/red';
import { LoanService } from '../../services';
import RepullButton from './RepullButton';
import ConfirmationModal from '../../components/ConfirmationModal'
import NotifyCheckboxCell from './NotifyCheckboxCell';
import PRCheckboxCell from './PRCheckboxCell.js';
import DeleteReturnsButton from './DeleteReturnsButton';
import UpdateReturnsAmountCell from './UpdateReturnsAmountCell';
import StageDropDown from './StageDropDown.js';
import { useNotification } from '../../contexts/NotificationContext';

  const tablifyReturns = returns =>
    returns == null ? null :
    returns.map(returnItem => {
      return [
        returnItem.id,
        returnItem.loanNumber,
        returnItem.dealer.number,
        returnItem.dealer.name,
        centsToDollar(returnItem.amount),
        (returnItem.hasStagedFunding === true ? 'Yes' : 'No'),
        returnItem.stage,
        returnItem.stage,
        returnItem.status,
        returnItem.dealer.organization.name,
        returnItem.dealer.needsReview,
        returnItem.dealer.isManuallyFlagged,
        returnItem.lender.shortName,
        returnItem.applicant.fullName,
        returnItem.dealer?.isConcierge,
        centsToDollar(returnItem.estimatedProjectAmount),
        centsToDollar(returnItem.totalLoanAmount),
        returnItem.termMonths,
        aprToPercent(returnItem.apr),
        returnItem.dealer?.manualFlaggedReason,
        returnItem.dealer?.totalFundedLoans || 0,
        promosToChips(returnItem),
        returnItem.projectType,
        returnItem.bounced,
        returnItem.notify,
        returnItem.commentCount,
        returnItem.loanType,
        returnItem.isPrincipalReduction,
        returnItem.dealer?.maxLoanAmount,
        returnItem.dealer?.totalLoanAmount,
        returnItem.dealer?.averageLoanAmount,
        returnItem.dealer?.firstFundingDate,
        returnItem.dealer?.lastFundingDate,
        returnItem.dealer?.numberOfDelinquentLoans,
        returnItem.dealer?.numberOfOverThirtyDayDelinquentLoans,
        returnItem.comments,
        returnItem.noPaymentsCompleted,
        returnItem.refundType,
        returnItem.applicationDate,
        returnItem.returnDate,
        returnItem
      ];
    });

  const ReturnsTable = ({ returns, selectedDate, toggleShowNewReturn }) => {
      const [selectedRowIndices, setSelectedRowsIndices] = useState([]);
      const [showConfirmation, setShowConfirmation] = useState(false);
      const { isDailyProcessingWrite, isAdmin } = useRole();
      const starterColumns = ['Loan ID', 'Dealer #', 'Dealer', 'Amount', 'Refund Type', 'Staged Funding', 'Stage', 'Completed Payments', 'Status', 'Org', 'Dealer Account Flag', 'Manual Flag', 'Lender', 'Notify?', 'Comments'];
      const [visibleColumns, setVisibleColumns] = useState((sessionStorage.getItem("returnsTable")) ? getTableSession("returnsTable") : saveTableSession(starterColumns, "returnsTable"));
      const [matchExactText, setmatchExactText] = useState(true);
      const [rowsPerPage, setRowsPerPage] = useState(50);
      const { createErrorNotification } = useNotification();

      const handleMatchExactText = (event) => {
        setmatchExactText(event.target.checked);
      }

      const handleAddReturnClick = () => {
        toggleShowNewReturn();
      }

      const handlePullClick = () => {
        LoanService.pullReturns(selectedDate).then(response => {
          // !FIXME: Do this better!
          setTimeout(() => window.location.reload(), 10000);
        });
        toggleConfirmation();
      };

      const toggleConfirmation = () => {
        setShowConfirmation(!showConfirmation);
      }

      const columns = [
        {
          name: 'Return ID',
          options: {
            filter: false,
            display: false,
            viewColumns: false,
          }
        },
        {
          name: 'Loan ID',
          options: {
              filter: false,
              display: visibleColumns ? visibleColumns.includes('Loan ID') : true,
              customBodyRender: (value, tableMeta) => {
                const item = tableMeta.rowData[40];
                return <LoanMetricTooltip
                          loanId={value}
                          estimatedProjectAmount={item.estimatedProjectAmount}
                          totalLoanAmount={item.totalLoanAmount}
                          loanTerm={item.termMonths}
                          loanApr={item.apr}
                          promos={promosToChips(item)}
                          projectType={item.projectType}
                          applicationDate={item.applicationDate}
                          returnDate={item.returnDate}
                        />
              }
          }
        },
        {
          name: 'Dealer #',
          options: {
            filter: false,
            display: visibleColumns ? visibleColumns.includes('Dealer #') : true,
            customBodyRender: value => <a href={`/dealers/${value}`}>{value}</a>
          }
        },
        {
          name: 'Dealer',
          options: {
            display: visibleColumns ? visibleColumns.includes('Dealer') : true,
            customBodyRender: (value, tableMeta) => {
              const item = tableMeta.rowData[40];
              return <DealerMetricTooltip
                        dealerName={value}
                        totalFundedLoans={item.dealer.totalFundedLoans}
                        maxLoanAmount={centsToDollar(item.dealer.maxLoanAmount)}
                        totalLoanAmount={centsToDollar(item.dealer.totalLoanAmount)}
                        averageLoanAmount={centsToDollar(item.dealer.averageLoanAmount)}
                        firstFundingDate={item.dealer.firstFundingDate}
                        lastFundingDate={item.dealer.lastFundingDate}
                        dealerConcierge={item.dealer.isConcierge ? "Yes" : "No"}
                        dealerDelinquentLoans={item.dealer.numberOfDelinquentLoans == null ? 0 : item.dealer.numberOfDelinquentLoans}
                        dealerThirtyDayDelinquentLoansCount={item.dealer.numberOfOverThirtyDayDelinquentLoans == null ? 0 : item.dealer.numberOfOverThirtyDayDelinquentLoans }
                      />
            }
          }
        },
        {
          name: 'Amount',
          options: {
              filter: false,
              display: visibleColumns ? visibleColumns.includes('Amount') : true,
              customBodyRender: (value, tableMeta) => {
                const item = tableMeta.rowData[40];
                return <UpdateReturnsAmountCell
                          isDPWrite={isDailyProcessingWrite()}
                          amount={value}
                          bounced={item.bounced}
                          tableMeta={tableMeta}
                          returnDate={item.returnDate}
                        />
              },
              sortCompare: sortDollarColumn
          }
        },
        {
          name: 'Staged Funding',
          options: {
            display: visibleColumns ? visibleColumns.includes('Staged Funding') : true,
            customFilterListOptions: {
              render: v => v === "No" ? "Non-Staged Funded" : "Staged Funded",
            },
          }
        },
        {
          name: 'Stage',
          options: {
            filter: false,
            display: visibleColumns ? visibleColumns.includes('Stage') : true,
            customBodyRender: (value, tableMeta) => {
              const item = tableMeta.rowData[40];
              return isDailyProcessingWrite() ? <StageDropDown currentStage={item.stage} id={item.id}/> : stage2Text(value)
            }
          }
        },
        {
          name: 'Completed Payments',
          options: {
              filter: false,
              display: visibleColumns ? visibleColumns.includes('Completed Payments') : true,
              customBodyRender: (value, tableMeta) => {
                const item = tableMeta.rowData[40];
                return <IssuedReturnsCell
                  id={item.id}
                  hasMultiplePartialRefunds={item.hasMultiplePartialRefunds}
                  alreadyPaidStages={item.paidStages}
                  heldStages={item.heldStages}
                  numberOfPaymentStages={item.numberOfPaymentStages}
                  hasStagedFunding={item.hasStagedFunding}
                  multipleRefundsIncludingFullRefund={item.multipleRefundsIncludingFullRefund}
                />
              }
          }
        },
        {
          name: 'Status',
          options: {
            display: visibleColumns ? visibleColumns.includes('Status') : true,
            customBodyRender: capitalizeString
          }
        },
        {
          name: 'Org',
          options: {
            display: visibleColumns ? visibleColumns.includes('Org') : true,
          }
        },
        {
          name: 'Dealer Account Flag',
          options: {
            display: visibleColumns ? visibleColumns.includes('Dealer Account Flag') : true,
            customBodyRender: dealerNeedsReviewCell,
            customFilterListOptions: {
              render: v => v ? "Dealer Flagged" : "Dealer Not Flagged",
            },
          }
        },
        {
          name: "Manual Flag",
          options: {
            display: visibleColumns ? visibleColumns.includes('Manual Flag') : true,
            customBodyRender: (value, tableMeta) => {
              const item = tableMeta.rowData[40];
              return ( isDailyProcessingWrite() ?
                <FlagCell value={value} dealerNumber={item.dealer.number} flagReason={item.dealer.manualFlaggedReason} /> :
                <FlagCell value={value} dealerNumber={item.dealer.number} flagReason={item.dealer.manualFlaggedReason} disableUpdates={true} />
              );
            },
            customFilterListOptions: {
              render: v => v ? "Manual Flagged" : "Non-Manual Flagged",
            },
          }
        },
        {
          name: 'Lender',
          options: {
            display: visibleColumns ? visibleColumns.includes('Lender') : true,
          }
        },
        {
          name: 'Borrower',
          options: {
            display: visibleColumns ? visibleColumns.includes('Borrower') : true,

          }
        },
        {
          name: 'Concierge Dealer',
          options: {
            filter: false,
            display: visibleColumns ? visibleColumns.includes('Concierge Dealer') : true,
            customBodyRender: isConciergeCell,
          }
        },
        {
          name: 'estimatedProjectAmount',
          options: {
            filter: false,
            display: false,
            viewColumns: false,
          }
        },
        {
          // This comes from loan table not FR table
          name: 'hiddenTotalLoanAmount',
          options: {
            filter: false,
            display: false,
            viewColumns: false,
          }
        },
        {
          name: 'term',
          options: {
            filter: false,
            display: false,
            viewColumns: false,
          }
        },
        {
          name: 'apr',
          options: {
            filter: false,
            display: false,
            viewColumns: false,
          }
        },
        {
          name: 'Manual Flag Reason',
          options: {
              filter: false,
              display: false,
              viewColumns: false
          }
        },
        {
          name: 'Total Funded Loans',
          options: {
              filter: false,
              display: visibleColumns ? visibleColumns.includes('Total Funded Loans') : true,
          }
        },
        {
          name: 'Promos',
          options: {
              filter: false,
              display: visibleColumns ? visibleColumns.includes('Promos') : true,
          }
        },
        {
          name: 'Project Type',
          options: {
              filter: false,
              display: visibleColumns ? visibleColumns.includes('Project Type') : true,
          }
        },
        {
          name: 'Bounced',
          options: {
              filter: true,
              display: false,
              viewColumns: false,
              customFilterListOptions: {
                render: v => v ? "Bounced Payments" : "Non Bounced Payments",
              }
          }
        },
        {
          name: 'Notify?',
          options: {
              filter: false,
              display: visibleColumns ? visibleColumns.includes('Notify?') : true,
              customBodyRender: (value, tableMeta) => {
                const item = tableMeta.rowData[40];
                return (
                  (isDailyProcessingWrite() || isAdmin()) ?
                    <NotifyCheckboxCell value={value} id={item.id} transactionType={'return'} date={selectedDate} disableUpdates={false}/> :
                    <NotifyCheckboxCell value={value} id={item.id} transactionType={'return'} date={selectedDate} disableUpdates={true}/>
                  )
            }
          }
        },
        {
          name: 'Comments',
          options: {
              filter: false,
              display: visibleColumns ? visibleColumns.includes('Comments') : true,
              /*ignore jslint start*/
              customBodyRender: (value, tableMeta) => {
                return (parseInt(value) === 0) ? null : (<CommentPreviewTooltip count={value} comments={tableMeta.rowData[40].comments} />)
              }
              /*ignore jslint end*/
          }
        },
        {
          name: 'Loan Type',
          options: {
              filter: true,
              display: visibleColumns ? visibleColumns.includes('Loan Type') : true,
              sort: false,
              viewColumns: true,
              filterOptions: {
                names: ['Home Improvement', 'Healthcare', 'Solar'],
              },
              customBodyRender: value => <LoanTypeIcon loanType={value} />
          }
        },
        {
          name: 'Principal Reduction',
          options: {
              filter: true,
              display: visibleColumns ? visibleColumns.includes('Principal Reduction') : true,
              customBodyRender: (value, tableMeta) => {
                const item = tableMeta.rowData[40];
                return (
                  (isDailyProcessingWrite() || isAdmin()) ?
                  <PRCheckboxCell value={item.isPrincipalReduction} id={item.id} disableUpdates={false} date={selectedDate}/> :
                  <PRCheckboxCell value={item.isPrincipalReduction} id={item.id} disableUpdates={true} date={selectedDate}/>
                )
              },
              customFilterListOptions: {
                render: v => v ? "Principal Reduction" : "Non Principal Reduction",
              }
          }
        },
        {
          name: 'Dealer Max Loan Amount',
          options: {
            filter: false,
            display: false,
            viewColumns: false,
          }
        },
        {
          name: 'Dealer Total Loan Amount',
          options: {
            filter: false,
            display: false,
            viewColumns: false,
          }
        },
        {
          name: 'Dealer Average Loan Amount',
          options: {
            filter: false,
            display: false,
            viewColumns: false,
          }
        },
        {
          name: 'Dealer First Funding Date',
          options: {
            filter: false,
            display: false,
            viewColumns: false,
          }
        },
        {
          name: 'Dealer Latest Funding Date',
          options: {
            filter: false,
            display: false,
            viewColumns: false,
          }
        },
        {
          name: 'Dealer Delinquent Loans',
          options: {
            filter: false,
            display: false,
            viewColumns: false,
          }
        },
        {
          name: 'Dealer 30+ Delinquent Loans',
          options: {
            filter: false,
            display: false,
            viewColumns: false,
          }
        },
        {
          name: 'Comment Messages',
          options: {
            filter: false,
            display: false,
            viewColumns: false,
          }
        },
        {
          name: 'Alerts',
          options: {
            filter: false,
            display: visibleColumns ? visibleColumns.includes('Alerts') : true,
            viewColumns: true,
            customBodyRender: value => value ? 'This loan has had no payments completed yet.' : 'N/A'
          }
        },
        {
          name: 'Refund Type',
          options: {
            filter: false,
            display: visibleColumns ? visibleColumns.includes('Refund Type') : true,
            customBodyRender: (value, tableMeta) => tableMeta.rowData[40].refundType
          }
        },
        {
          name: 'Application Date',
          options: {
            filter: false,
            display: false,
            viewColumns: false,
          }
        },
        {
          name: 'Return Date',
          options: {
            filter: false,
            display: false,
            viewColumns: false,
          }
        },
        {
          name: 'Application Date',
          options: {
            display: false,
            filter: false,
            viewColumns: false
          }
        },
        // allows us to access whole return object vs shifting indices to access data in customBodyRender
        {
          name: 'item',
          options: {
            display: false,
            filter: false,
            viewColumns: false
          }
        }
      ];

      const options = {
        filterType: 'dropdown',
        print: false,
        download: isDailyProcessingWrite(),
        onDownload: () => {
          LoanService.downloadReturnsReport(selectedDate).then(data => {
          }).catch(error => {
            createErrorNotification('Failed to Download Returns');
          });
          return false;
        },
        searchOpen: true,
        rowsPerPage: rowsPerPage,
        rowsPerPageOptions: [50, 100, 250],
        rowsSelected: selectedRowIndices,
        setRowProps: (row, dataIndex, rowIndex) => {
          if (row[40].bounced) {
            return {
              style: {background: red[200]}
            };
          }
          else if (rowIndex % 2 === 0) {
            return {
              style: { background: 'snow' }
            };
          }
        },
        onRowSelectionChange: (rowsSelected, allRows) => {
          setSelectedRowsIndices(allRows.map(row => row.dataIndex));
        },
        onTableChange: (action, tableState) => {
          switch (action) {
            case 'viewColumnsChange':
              let displayedVisibleColumns = tableState.columns.map(column => {
                if (column.display === "true") {
                  return column.name;
                } else {
                  return null;
                }
              }).filter(column => column !== null);

              if (sessionStorage.getItem("returnsTable")) {
                saveTableSession(displayedVisibleColumns, "returnsTable");
              }
              setVisibleColumns(displayedVisibleColumns);

              break;
            default:
              break;
          }
        },
        selectToolbarPlacement: 'none',
        customToolbar: () => {
          if (selectedRowIndices.length > 0) {
            return (isDailyProcessingWrite() || isAdmin()) ? (
              <Fragment>
                <DeleteReturnsButton selectedRowIndices={selectedRowIndices} potentialDeletions={returns} date={selectedDate}  />
              </Fragment>
            ) : null;
        } else {
          return isDailyProcessingWrite() ? (
                  <Fragment>
                    <Tooltip title={'Add New Return'}>
                      <IconButton onClick={handleAddReturnClick}>
                        <AddIcon />
                      </IconButton>
                    </Tooltip>
                    <RepullButton hasItems={returns.length > 0} pullName={'Returns'} onClick={toggleConfirmation}/>
                  </Fragment>
                ) : null;
        }
        },
        customSearchRender: (searchText, handleSearch, hideSearch, options) => {
          return (
            <CustomSearchBar
              searchText={searchText}
              handleSearch={handleSearch}
              hideSearch={hideSearch}
              options={options}
              matchExactText={matchExactText}
              handleMatchExactText={handleMatchExactText}
            />
          );
        },
        customSearch: (searchQuery, currentRow, columns) => {
          return customTableSearch(searchQuery, currentRow, columns, matchExactText);
        },
        onChangeRowsPerPage: (rowNumber) => {
          setRowsPerPage(rowNumber);
        },
      };

      return returns === null ? (
        <h1>Loading...</h1>
      ) : (
        <Fragment>
          <ConfirmationModal title="Confirm" message="Are you sure you want to pull returns?"  showConfirmationBox={showConfirmation} onConfirm={handlePullClick} onCancel={toggleConfirmation}/>
          <MUIDataTable data={tablifyReturns(returns)} columns={columns} options={options} />
        </Fragment>
      );
  }

  export default memo(ReturnsTable);

import React, { useState } from 'react';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import CreateIcon from '@material-ui/icons/Create';
import PublishIcon from '@material-ui/icons/Publish';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
import { LoanService } from '../../services';
import { ReturnService } from '../../services';
import { useNotification } from '../../contexts/NotificationContext';
import { capitalizeString } from '../../utils/formatters';

const useStyles = makeStyles((theme) => ({
    activeEditCell: {
        display: 'flex', 
        maxWidth: '250px',
        width: '250px'
    },
    dormantEditCell: {
        display: 'flex', 
    },
    paymentEditIcon: {
        marginTop: '-10px',
    },
    paymentSubmitIcon: {
        marginTop: '25px',
    },
  }));

const EditableCell = (props) => {
    const classes = useStyles();
    const [editModeEnabled, setEditModeEnabled] = useState(false);
    const [newAmount, setNewAmount] = useState(parseFloat(props.value.replace(/[$,]+/g,"")).toFixed(2));
    const { createErrorNotification } = useNotification();

    const handleEditClick = () => {
        setEditModeEnabled(true);
        setNewAmount(props.value.replace(/[$,]+/g,""));
    }

    const handlePublishClick = async () => {
        try {
            const response = await (props.type === 'payment' ?
                LoanService.updatePaymentAmount(props.id, newAmount) :
                ReturnService.updateReturnAmount(props.id, newAmount)
            );

            if (response.status && response.status === 200) {
                // TODO: Improve me
                setTimeout(() => window.location.reload(), 100)
            } else {
                createErrorNotification(`Failed to update ${capitalizeString(props.type)} amount`);
            }
        } catch (err) {
            createErrorNotification(`Failed to update ${capitalizeString(props.type)} amount`);
        }
    }

    const handleCancelClick = () => {
        setEditModeEnabled(false);
        setNewAmount(props.value.replace(/[$,]+/g,""));
    }

    const handleAmountChange = (event) => {
        setNewAmount(event.target.value);
    }

    return editModeEnabled ?
        <div className={classes.activeEditCell}>
            <div>
                <TextField value={newAmount} 
                    label="Amount" 
                    inputProps={{ maxLength: 9 }} 
                    InputProps={{startAdornment: <InputAdornment position="start">$</InputAdornment>,}} 
                    fullWidth={true} 
                    margin="normal" 
                    required={true} 
                    onChange={handleAmountChange} 
                />
            </div>
            <div>
                <Tooltip title={`Submit ${capitalizeString(props.type)} Update`}>
                    <IconButton onClick={handlePublishClick} className={classes.submitIcon}>
                        <PublishIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </div>
            <div>
                <Tooltip title={"Cancel"}>
                    <IconButton onClick={handleCancelClick} className={classes.submitIcon}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </div>
        </div> :
        <div className={classes.dormantEditCell}>
            <div>{props.value} </div>
            <div>
                <Tooltip title={`Update ${capitalizeString(props.type)}`}>
                    <IconButton onClick={handleEditClick} className={classes.editIcon}>
                        <CreateIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </div>
        </div>;
}

export default EditableCell;
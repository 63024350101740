import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useNotification } from '../../contexts/NotificationContext';
import { TextField, MenuItem, Tooltip } from '@material-ui/core';
import { ReturnService } from '../../services';
import IconButton from '@material-ui/core/IconButton';
import PublishIcon from '@material-ui/icons/Publish';
import CloseIcon from '@material-ui/icons/Close';
import CreateIcon from '@material-ui/icons/Create';
import { stage2Text } from '../../utils/formatters';

const useStyles = makeStyles((theme) => ({
    activeEditCell: {
        display: 'flex',
        maxWidth: '200px',
        width: '250px',
        marginTop: '16px'
    },
    dormantEditCell: {
        display: 'flex',
    },
    editIcon: {
        marginLeft: '70px',
        marginTop: '-25px'
    },
    controlIcons: {
        marginTop: '-16px',
    },
  }));

function StageDropDown(props) {
    const classes = useStyles();
    const { createErrorNotification } = useNotification();
    const [newStage, setNewStage] = useState(props.currentStage);
    const [editModeEnabled, setEditModeEnabled] = useState(false);

    const selectOptions = [
        { value: 'unstaged', label: 'Unstaged' },
        { value: 'first', label: 'Stage 1' },
        { value: 'second', label: 'Stage 2' },
        { value: 'final', label: 'Final Stage' }
    ]

    const handlePublishClick = async () => {
        try {
            const response = await ReturnService.updateStage(props.id, newStage);
            if (response.status && response.status === 200) {
                setTimeout(() => window.location.reload(), 100)
            } else {
                createErrorNotification('Failed to update stage');
            }
        } catch (err) {
            createErrorNotification(`Failed to update stage: ${err}`);
        }
    }

    const handleControlClick = () => {
        setEditModeEnabled(!editModeEnabled);
        setNewStage(props.currentStage);
    }

    const handleStageChange = (event) => {
        setNewStage(event.target.value);
    }

    return (
        <>
        {editModeEnabled ?
        <div className={classes.activeEditCell}>
            <TextField
                select
                value={[newStage]}
                margin="normal"
                required={true}
                onChange={handleStageChange}
                style={{ width: 110 }}
            >
                {
                    selectOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                    ))
                }
            </TextField>
            <div>
                <Tooltip title='Submit'>
                    <IconButton onClick={handlePublishClick} className={classes.controlIcons}>
                        <PublishIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </div>
            <div>
                <Tooltip title='Cancel'>
                    <IconButton onClick={handleControlClick} className={classes.controlIcons}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </div>
        </div> :
        <div>
            {stage2Text(props.currentStage)}
            <div className={classes.dormantEditCell}>
                <div>{props.value} </div>
                <div>
                    <Tooltip title='Update stage'>
                        <IconButton onClick={handleControlClick} className={classes.editIcon}>
                            <CreateIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
        </div>
        }
        </>
    )
}

export default StageDropDown;
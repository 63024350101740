import React from 'react';
import Error from '@material-ui/icons/Error';
import { makeStyles } from '@material-ui/core';
import { red } from '@material-ui/core/colors';

const styles = makeStyles((theme, props) => ({
    error: {
        fill: `${red[500]}`,
        width: '40px',
        height: '40px',
        marginLeft: '0px',
        marginRight: '0px',
        marginTop: '0px',
        marginBottom: '0px',
        paddingLeft: '0px',
        paddingRight: '0px',
        paddingTop: '0px',
        paddingBottom: '0px',
        pointerEvents: 'none'
        
    },
}));


const ErrorIcon = () => {
    const classes = styles();
    return(
        <Error className={classes.error}/>

    );

} 

export default ErrorIcon;
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DealerService } from '../../services';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import ChipInput from 'material-ui-chip-input'
import { useNotification } from '../../contexts/NotificationContext';
import Tooltip from '@material-ui/core/Tooltip';
import Switch from '@material-ui/core/Switch';
import { Typography } from '@material-ui/core';

function isBlank(value) {
  return value == null || value.trim().length === 0;
}

function updateRequiredField(value, updater, errorUpdater) {
  if (isBlank(value)) {
    errorUpdater(true);
    return true;
  } else {
    errorUpdater(false);
    updater(value);
    return false;
  }
}

function updateField(value, updater, errorUpdater) {
  updater(value);
  return false;
}

const useStyles = makeStyles((theme) => ({
  paper: {
      position: 'absolute',
      left: "20%",
      top: "15%",
      width: '60vw',
      height: '75vh',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      overflowY: 'scroll',
  },
  modalButton: {
      marginLeft: '15px',
      float: 'right',
  },
  coreForm: {
    height: '50vh'
  }
}));

// const einRegex = RegExp(/^[1-9]\d?-\d{7}$/g);
// const ssnRegex = RegExp(/^\d{3}-\d{2}-\d{4}$/g);
const nineDigitRegex = RegExp(/^\d{9}$/g);

const NewDealer = (props) => {
    const classes = useStyles();
    const [dealerId, setDealerId] = useState(null);
    const [dealerIdError, setDealerIdError] = useState(false);
    const [legalBusinessName, setLegalBusinessName] = useState(null);
    const [legalBusinessNameError, setLegalBusinessNameError] = useState(false);
    const [businessLicenseNumber, setBusinessLicenseNumber] = useState(null);
    const [businessLicenseNumberError, setBusinessLicenseNumberError] = useState(false);
    const [ein, setEin] = useState(null);
    const [einError, setEinError] = useState(false);
    const [ssn, setSsn] = useState(null);
    const [ssnError, setSsnError] = useState(false);
    const [bankAccountNumber, setBankAccountNumber] = useState(null);
    const [bankAccountNumberError, setBankAccountNumberError] = useState(false);
    const [bankRoutingNumber, setBankRoutingNumber] = useState(null);
    const [bankRoutingNumberError, setBankRoutingNumberError] = useState(false);
    const [organization, setOrganization] = useState(null);
    const [organizationError, setOrganizationError] = useState(false);
    const [notificationEmails, setNotificationEmails] = useState([]);
    const [createTestTrxn, setCreateTestTrxn] = useState(false);
    const { createErrorNotification } = useNotification();

    const resetState = () => {
      setDealerId(null);
      setDealerIdError(false);
      setLegalBusinessName(null);
      setLegalBusinessNameError(false);
      setBusinessLicenseNumber(null);
      setBusinessLicenseNumberError(false);
      setEin(null);
      setEinError(false);
      setSsn(null);
      setSsnError(false);
      setBankAccountNumber(null);
      setBankAccountNumberError(false);
      setBankRoutingNumber(null);
      setBankRoutingNumberError(false);
      setOrganization(null);
      setOrganizationError(false);
      setNotificationEmails([]);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        // First check for missing required fields
        let hasMissingRequiredFields = false;
        hasMissingRequiredFields = updateRequiredField(dealerId, setDealerId, setDealerIdError) || hasMissingRequiredFields;
        hasMissingRequiredFields = updateRequiredField(legalBusinessName, setLegalBusinessName, setLegalBusinessNameError) || hasMissingRequiredFields;
        hasMissingRequiredFields = updateRequiredField(businessLicenseNumber, setBusinessLicenseNumber, setBusinessLicenseNumberError) || hasMissingRequiredFields;
        hasMissingRequiredFields = updateRequiredField(bankAccountNumber, setBankAccountNumber, setBankAccountNumberError) || hasMissingRequiredFields;

        if (hasMissingRequiredFields) {
          createErrorNotification("Missing Required Fields");
        } else if (einError || isBlank(ein)) {
          createErrorNotification("Invalid EIN number. Must be in format '00-1234567'");
        } else if (ssnError || isBlank(ssn)) {
          createErrorNotification("Invalid SSN number. Must be in format '123-45-6789'");
        } else if (bankRoutingNumberError || isBlank(bankRoutingNumber)) {
          createErrorNotification("Invalid Bank Routing number. Must be exactly 9 digits.");
        } else {
          await DealerService.newDealer({
            dealerId: dealerId,
            legalBusinessName: legalBusinessName,
            businessLicenseNumber: businessLicenseNumber,
            ein: ein,
            ssn: ssn,
            bankAccountNumber: bankAccountNumber,
            bankRoutingNumber: bankRoutingNumber,
            dealerGroup: organization,
            notificationEmails: notificationEmails,
          });

          if (createTestTrxn) {
            const TEST_TRANSACTION_UPPER_LIMIT = 0.51;
            const testTransactionAmount = Math.ceil(Math.random() * TEST_TRANSACTION_UPPER_LIMIT * 100);
            const response = await DealerService.newTestTransaction(dealerId, testTransactionAmount);

            if (response.status && response.status === 200) {
                // TODO: Improve me
                setTimeout(() => window.location.reload(), 100);
            } else {
                createErrorNotification(`Failed to create test transaction, please contact support`);
            }
          }
          
          resetState();
          props.toggleShowNewDealer();
          // Forces dealer table to reload with new dealer
          props.forceReloadDealers();
        }
    }

    const handleCancel = () => {
      props.toggleShowNewDealer();
      resetState();
    }

    const handleCreateTestTrxnToggle = () => {
      setCreateTestTrxn(!createTestTrxn);
    }

    const handleDealerIdChange = (event) => updateRequiredField(event.target.value, setDealerId, setDealerIdError)
    const handleLegalBusinessNameChange = (event) => updateRequiredField(event.target.value, setLegalBusinessName, setLegalBusinessNameError)
    const handleBusinessLicenseNumberChange = (event) => updateRequiredField(event.target.value, setBusinessLicenseNumber, setBusinessLicenseNumberError)
    const handleEinChange = (event) => updateRequiredField(event.target.value, setEin, setEinError)
    const handleSsnChange = (event) => {
      if (nineDigitRegex.test(event.target.value)) {
        setSsnError(false);
        setSsn(event.target.value);
      } else {
        setSsnError(true);
      }
    }
    const handleBankAccountNumberChange = (event) => updateRequiredField(event.target.value, setBankAccountNumber, setBankAccountNumberError)
    const handleBankRoutingNumberChange = (event) => {
      if (nineDigitRegex.test(event.target.value)) {
        setBankRoutingNumberError(false);
        setBankRoutingNumber(event.target.value);
      } else {
        setBankRoutingNumberError(true);
      }
    }
    const handleOrganizationChange = (event) => updateField(event.target.value, setOrganization, setOrganizationError)

    const body = (
      <div className={classes.paper}>
          <h2 id="simple-modal-title">Create Dealer</h2>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={5} justifyContent="flex-start">
              <Grid item xs={12}>
                <TextField label="Dealer ID" autoFocus={true} required={true} error={dealerIdError} onChange={handleDealerIdChange} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField label="Legal Business Name" fullWidth={true} margin="normal" required={true} error={legalBusinessNameError} onChange={handleLegalBusinessNameChange} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField label="Business License Number" fullWidth={true} margin="normal" required={true} error={businessLicenseNumberError} onChange={handleBusinessLicenseNumberChange} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField label="EIN" inputProps={{ maxLength: 9 }} fullWidth={true} margin="normal" error={einError} onChange={handleEinChange} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField label="SSN" inputProps={{ maxLength: 9 }} fullWidth={true} margin="normal" error={ssnError} onChange={handleSsnChange} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField label="Bank Account Number" fullWidth={true} margin="normal" required={true} error={bankAccountNumberError} onChange={handleBankAccountNumberChange} />
              </Grid>
              <Grid item xs={12} sm={6}>
              <TextField label="Bank Routing Number" inputProps={{ maxLength: 9 }} fullWidth={true} margin="normal" required={true} error={bankRoutingNumberError} onChange={handleBankRoutingNumberChange} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField label="Organization" fullWidth={true} margin="normal" error={organizationError} onChange={handleOrganizationChange} />
              </Grid>
              <Grid item xs={12}>
                {/* ?TODO: Validate that the input is actually an email? */}
                <ChipInput
                  label="Notification Emails"
                  value={notificationEmails}
                  onAdd={(email) => setNotificationEmails([...notificationEmails, email])}
                  onDelete={(email, index) => setNotificationEmails(notificationEmails.filter(val => val !== email))}
                  fullWidth={true}
                  helperText="Emails that will receive funding notifications"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography className={classes.toggleSwitchElement}>Create Test Transaction:</Typography>
                <Tooltip title={<span className={classes.tooltip}><b>Active: </b>Will create a test transaction for dealer<br /><b>Inactive: </b>Will not create a test transaction for dealer</span>}>
                  <Switch checked={createTestTrxn} onChange={handleCreateTestTrxnToggle} color="primary"/>
                </Tooltip>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button variant="contained" color="primary" className={classes.modalButton} onClick={handleSubmit}>Create</Button>
                <Button variant="contained" className={classes.modalButton} onClick={handleCancel}>Cancel</Button>
              </Grid>
            </Grid>
          </form>
      </div>
  );

  return (
      <div>
          <Modal
          open={props.showNewDealer}
          onClose={props.toggleShowNewDealer}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          >
              {body}
          </Modal>
      </div>
  );
}

export default NewDealer;
import React from 'react';
import { parseCentsToDisplay } from '../../utils/formatters';

const CountAmountItem = ({label, amount, count}) => {
    return (<li><b>{label}:</b> {count || 0} / {parseCentsToDisplay(amount)}</li>);
}

const AmountCountItem = ({label, amount, count, boldLabel = true}) => {
    return (
    <li>
        {boldLabel ? <b>{label}</b> : label}: {parseCentsToDisplay(amount)} / {count || 0}
    </li>);
}

export {
    CountAmountItem,
    AmountCountItem
}

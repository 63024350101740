import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const styles = makeStyles(() => ({
    breakdownText: {
      color: `black`,
      fontSize: '13pt',
      lineHeight: 'normal',
      marginTop: '15px'
    },
  }));

const Description = (props) => {
    const classes = styles();
      const {description} = props || {};
        return (
            <div>
                <Typography className={classes.breakdownText} variant="body1" align="center">
                  {description}
                </Typography>
                
            </div>
        )
}

export default Description;
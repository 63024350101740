import React from 'react';
import LoansTable from './LoansTable';

const Loans = (props) => {
    return (
        <div id="loans-container">
          <div className="loans-table-header-container">
            <h1>Loans</h1>
          </div>
          <LoansTable />
        </div>
    );

}

export default Loans;
import React, { useState }  from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ConfirmationModal from '../components/ConfirmationModal';
import green from '@material-ui/core/colors/green';
import { HubspotService } from '../services';

const styles = makeStyles(() => ({
  submitButton: {
    marginLeft: '10px',
    marginTop: '25px'
  },
  confirmButton: {
    backgroundColor: green[400],
    float: 'right',
    marginTop:'16px',
    marginRight:'4px'
  },
  inputAmount: {
    float: 'right',
    marginTop: '8px',
    marginRight:'4px',
    marginBottom: '4px'
  }
}));

const SendAllLoanNotesToHubspot = () => {
  const [showConfirmConfirmationBox, setShowConfirmConfirmationBox] = useState(false);
  const toggleConfirmConfirmation = () => setShowConfirmConfirmationBox(!showConfirmConfirmationBox);

  const handleConfirmSendAllLoanNotesButtonClick = async () => {
    toggleConfirmConfirmation();
  }

  const handleConfirmSendAllLoanNotesButton = async () => {
    HubspotService.sendAllLoanNotes();
    toggleConfirmConfirmation();
  }

  const classes = styles();

  return(
    <div>
      <ConfirmationModal title="Confirm" message="Are you sure you want to send all loan notes to Hubspot?" onConfirm={handleConfirmSendAllLoanNotesButton} onCancel={toggleConfirmConfirmation} showConfirmationBox={showConfirmConfirmationBox} />
      <Button variant="contained" className={classes.confirmButton} onClick={handleConfirmSendAllLoanNotesButtonClick}>Submit</Button>
    </div>
  )
}

export default SendAllLoanNotesToHubspot;

import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { QuicksightService } from '../../services';
import { useNotification } from '../../contexts/NotificationContext';
import { makeStyles } from '@material-ui/core/styles';
import './map.css';
import Iframe from 'react-iframe';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    header: {
        textAlign: 'center',
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
    },
    title: {
      fontSize: 14,
    },
    embedContainer: {
        display:"flex",
        textAlign:"center",
        position:"relative",
        overflow:"hidden",
        paddingBottom: "56.75%",
        height:"0"
    },
  }));

const QuicksightDashboard = (props) => {
    const [quicksightDetails, setQuicksightDetails] = useState(null);
    const classes = useStyles();
    const { createErrorNotification } = useNotification();


    useEffect(() => {
        QuicksightService.getURL().then(res => {
            if(res) {
                setQuicksightDetails(res.EmbedUrl)
            } else {
                createErrorNotification("Failed to retrieve quicksight URL");
            }
        });
    }, [createErrorNotification])

    

    return quicksightDetails === null ?  (<h1>Loading...</h1>) :
        (
        <div className={classes.root}>
          <h1 className={classes.header}>PowerPay Quicksight Dashboard</h1>

            <Grid className={classes.embedContainer}>
                    <Iframe url={quicksightDetails}
                        width="100%"
                        height="100%"
                        top="0"
                        left="0"
                        id="myId"
                        className={"classname"}
                        display="flex"
                        position="absolute"
                        frameBorder="0"/>
            </Grid>

        </div>
        );
}

export default QuicksightDashboard;
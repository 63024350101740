import React from 'react';
import ReactJsonViewCompare from 'react-json-view-compare';

const LoanHoldDiff = ({newValue, oldValue, changeType}) => {
    let newData = {};
    let oldData = {};
    if (changeType === 'CUTOVER') {
        newData = oldValue;
        oldData = {};
    } else {
        for (const [key, value] of Object.entries(oldValue)) {
            if (value !== newValue[key]) {
              // remove the unchanged values
              newData[key] = newValue[key];
              oldData[key] = oldValue[key];
            }
        }
    }

    return (
      <div>
          <ReactJsonViewCompare oldData={oldData} newData={newData} />
      </div>
    );
  }

export default LoanHoldDiff;
import React from "react";

import TextField from "@material-ui/core/TextField";
import Grow from '@material-ui/core/Grow';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  main: {
    display: 'flex',
    flex: '1 0 auto',
    alignItems: 'center',
  },
  searchIcon: {
    marginRight: '8px',
    color: theme.palette.text.secondary
  },
  searchText: {
    flex: '0.8 0',
  },
  clearIcon: {
    '&:hover': {
      color: theme.palette.error.main,
    }
  }
}));

const CustomSearchRender = ({
  searchText,
  handleSearch,
  hideSearch,
  options,
  matchExactText,
  handleMatchExactText,
  tooltipText
}) => {
  const classes = useStyles();

  const handleTextChange = event => {
    handleSearch(event.target.value);
  };

  const onKeyDown = event => {
    if (event.key === 'Escape') {
      hideSearch();
    }
  };

  return (
    <Grow appear in={true} timeout={300}>
      <div className={classes.main} >
        <SearchIcon className={classes.searchIcon} />
        <TextField
          className={classes.searchText}
          autoFocus={true}
          variant={'standard'}
          InputProps={{
            'data-test-id': options.textLabels.toolbar.search,
          }}
          inputProps={{
            'aria-label': options.textLabels.toolbar.search,
          }}
          value={searchText || ''}
          onKeyDown={onKeyDown}
          onChange={handleTextChange}
          fullWidth={true}
          {...(options.searchProps ? options.searchProps : {})}
        />
        <IconButton className={classes.clearIcon} onClick={hideSearch}>
          <ClearIcon />
        </IconButton>
        <FormControlLabel
          control={
            <Checkbox
              checked={matchExactText}
              onChange={handleMatchExactText}
              color="primary"
            />
          }
          label="Exact Match Search"
        />
        {
          !matchExactText &&
          <Tooltip title={tooltipText || "For multiple searches, enter the terms separated by a space. For example: PP1111 PP2222 PP3333"}>
            <IconButton>
              <InfoIcon />
            </IconButton>
          </Tooltip>
        }
      </div>
    </Grow>
  );
}

export default CustomSearchRender;

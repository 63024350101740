export const customTableSearch = (searchQuery, currentRow, columns, matchExactText) => {
  let isFound = false;

  if (matchExactText) {
    currentRow.forEach(col => {
      if (col?.toString().toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0) {
        isFound = true;
      }
    });
  } else {
    const valuesToSearch = searchQuery.split(' ').filter(v => v !== '');

    valuesToSearch.forEach(value => {
      currentRow.forEach(col => {
        if (col?.toString().toLowerCase().indexOf(value.toLowerCase()) >= 0) {
          isFound = true;
        }
      });
    })
  }

  return isFound;
}

// IMPORTS
import React from 'react';
import { Box, LinearProgress } from '@material-ui/core';

const InlineLoadingText = (props) => {
    return ( 
        <Box sx={{ width: '100%' }}>
            <h1>Loading...</h1> 
            <LinearProgress/>
        </Box>
    );
}

// Exports
export default InlineLoadingText;
import React, { memo, Fragment } from 'react';
import { PaymentService } from '../../services';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';

const BulkReleaseProcessingPaymentsButton = ({selectedRowIndices, payments}) => {
      const handleProcessingClick = () => {
        PaymentService.bulkInstantReleaseProcessingPayments(selectedRowIndices.map(rowId => {
          return payments[rowId].id;
        })).then(response => {
          // !FIXME: Do this better!
          window.location.reload();
        });
      }

      return (
          <Fragment>
              <Tooltip title={'Bulk Instant Release Selected Payments'}>
                <IconButton onClick={handleProcessingClick}>
                  <AssignmentTurnedInIcon />
                </IconButton>
              </Tooltip>
        </Fragment>
      );
  }

export default memo(BulkReleaseProcessingPaymentsButton);

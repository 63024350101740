import React, { useState } from "react";
import { request } from "../services/utils";
import "./login.css";
import { useNotification } from "../contexts/NotificationContext";

export default function ResetPassword({ history, error }) {
  const [isFetching, setIsFetching] = useState(false);
  const [verificationCodeWasSent, setVerificationCodeWasSent] = useState(false)
  const [email, setEmail] = useState("");
  const [verificationCode, setVerificationCode] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const { createErrorNotification, createNotification } = useNotification();

  function handleResetSubmit(event) {
    event.preventDefault();
    setIsFetching(true);
    request
      .post("/reset-password", {
        email: email,
      })
      .then((response) => {
        /* Before, we used to do a get request that got another page, and this get had a middleware check in it */
        /* Because i'm merging the validation page and the reset page, that middleware check doesn't happen. This is an issue to be discussed later */
        setVerificationCodeWasSent(true)
      })
      .catch((error) => {
        createErrorNotification(
          `Error: ${error?.response?.data?.message}. Try again or contact us.`
        );
        console.error(
          error
        ); /**i need to know in more detail what kinds of errors can appear on backend, right now its all kinda the same */
      })
      .finally(() => {
        setIsFetching(false);
      });
  }

  function handleSetNewPasswordSubmit(event) {
    event.preventDefault();
    setIsFetching(true);
    request
      .post("/verification", {
        email: email,
        code: verificationCode,
        pw: newPassword,
        pwConfirm: confirmPassword,
      })
      .then((response) => {
        createNotification("Password changed, please log in again using your new password")
        history.push("/login");
      })
      .catch((error) => {
        createErrorNotification(
          `Error: ${error?.response?.data?.message}. Try again or contact us.`
        );
        console.error(
          error
        ); /**i need to know in more detail what kinds of errors can appear on backend, right now its all kinda the same */
      })
      .finally(() => {
        setIsFetching(false);
      });
  }

  const renderEmailInput = () => {
    return (
      <>
        <p className="sign" align="center">
          Reset Password
        </p>
        <p align="center">
          Enter your email
        </p>
        <form
          onSubmit={(e) => handleResetSubmit(e)}
          className="form1"
          id="reset-form"
          autocomplete="on"
        >
          <input
            id="reset-form_email-input"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            name="email"
            className="un"
            type="email"
            align="center"
            disabled={isFetching}
            placeholder="Email"
            autocomplete="username"
            required
          />
          {isFetching ? (
            <p className="please-wait-text">Please wait...</p>
          ) : (
            <button
              type="submit"
              value="reset"
              id="submit"
              className="submit"
              align="center"
            >
              Reset
            </button>
          )}
        </form>
      </>
    );
  };

  const renderNewPasswordInputs = () => {
    return (
      <>
        <p className="sign" align="center">
          Set a new password
        </p>
        <p align="center">
          A verification code was sent to your email
        </p>
        <form
          onSubmit={(e) => handleSetNewPasswordSubmit(e)}
          className="form2"
          id="reset-form"
        >
          <input
            id="set-new-password-form_code-input"
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
            name="code"
            className="un"
            type="text"
            align="center"
            placeholder="Verification code"
            autocomplete="one-time-code"
            required
          />
          <input
            id="set-new-password-form_new-password-input"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            name="pw"
            className="pass"
            type="password"
            align="center"
            placeholder="New password"
            autocomplete="new-password"
            required
          />
          <input
            id="set-new-password-form_confirm-password-input"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            name="pwConfirm"
            className="pass"
            type="password"
            align="center"
            placeholder="Confirm password"
            autocomplete="new-password"
            required
          />
          <button
            type="submit"
            value="reset"
            id="submit"
            className="submit"
            align="center"
          >
            Confirm
          </button>
        </form>
      </>
    );
  };

  return (
    <div className="card">
      {verificationCodeWasSent
        ? renderNewPasswordInputs()
        : renderEmailInput()}
        <button disabled={isFetching} onClick={() => history.push("/login")} className="forgotpw" align="center">Go back to Login</button>
    </div>
  );
}

import { request } from './utils';

export default class AccountService {
    static async logout() {
      const response = await request('/logout', {
        method: 'POST'
      });

      return response; /** @important response should be raw, not response.data, as we need to be able to read the status code. */
    }

    static async refreshToken() {
      const response = await request('/refresh-session', {
        method: 'POST'
      });

      return response;
    }
}
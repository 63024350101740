import React, { useState }  from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ConfirmationModal from './ConfirmationModal';
import green from '@material-ui/core/colors/green';
import { HubspotService } from '../services';

const styles = makeStyles(() => ({
  submitButton: {
    marginLeft: '10px',
    marginTop: '25px'
  },
  confirmButton: {
    backgroundColor: green[400],
    float: 'right',
    marginTop:'16px',
    marginRight:'4px'
  },
  inputAmount: {
    float: 'right',
    marginTop: '8px',
    marginRight:'4px',
    marginBottom: '4px'
  }
}));

const PushPendingLoanPCGNotes = () => {
  const [showConfirmConfirmationBox, setShowConfirmConfirmationBox] = useState(false);
  const toggleConfirmConfirmation = () => setShowConfirmConfirmationBox(!showConfirmConfirmationBox);

  const handleConfirmPushPendingPCGLoanNotesButtonClick = async () => {
    toggleConfirmConfirmation();
  }

  const handleConfirmPushPendingPCGLoanNotesButton = async () => {
    HubspotService.pushPendingLoanPCGNotes();
    toggleConfirmConfirmation();
  }

  const classes = styles();

  return(
    <div>
      <ConfirmationModal title="Confirm" message="Are you sure you want to push pending PCG loan notes to Hubspot?" onConfirm={handleConfirmPushPendingPCGLoanNotesButton} onCancel={toggleConfirmConfirmation} showConfirmationBox={showConfirmConfirmationBox} />
      <Button variant="contained" className={classes.confirmButton} onClick={handleConfirmPushPendingPCGLoanNotesButtonClick}>Submit</Button>
    </div>
  )
}

export default PushPendingLoanPCGNotes;

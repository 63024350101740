import React, { useState } from 'react';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { useNotification } from '../../contexts/NotificationContext';
import { dollarAmountRegex, updateRequiredField, isBlank, stages} from '../../utils/dailyPaymentFormHelpers';
import { LoanService } from '../../services';
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    left: "30%",
    top: "15%",
    width: '40vw',
    height: '50vh',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflowY: 'scroll',
  },
  modalButton: {
    marginLeft: '15px',
    marginTop: '15px',
    float: 'right',
  },
  coreForm: {
    height: '50vh'
  }
}));

const UnpaidStages = (option) => {
  return (
    <MenuItem key={option.value} value={option.value}>
      {option.label}
    </MenuItem>
  )
}

const ForceCompletePayment = (props) => {
  const classes = useStyles();
  const [forceCompleteStage, setForceCompleteStage] = useState('');
  const [forceCompleteStageError, setForceCompleteError] = useState(false);
  const [forceCompleteComment, setForceCompleteComment] = useState(null);
  const [amount, setAmount] = useState(null);
  const [amountError, setAmountError] = useState(false);
  const { createErrorNotification } = useNotification();

  const resetState = () => {
    setForceCompleteStage(null);
    setForceCompleteComment(null);
    setAmount(null);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    let hasMissingRequiredFields = false;
    hasMissingRequiredFields = updateRequiredField(forceCompleteStage, setForceCompleteStage, setForceCompleteError) || hasMissingRequiredFields;
    hasMissingRequiredFields = updateRequiredField(amount, setAmount, setAmountError) || hasMissingRequiredFields;
    console.log(hasMissingRequiredFields); // linting hack

    if(forceCompleteStageError || isBlank(forceCompleteStage)){
      createErrorNotification("Invalid payment stage.");
    } else if (amountError || isBlank(amount)) {
      createErrorNotification("Invalid Amount. Must be in format '123' or '123.45");
    } else {
      const response = await LoanService.forceCompletePayment(props.loanHistoryData.loanNumber, forceCompleteStage, forceCompleteComment, amount);

      if(response.status && response.status === 200){
        resetState();
        props.toggleForceComplete();
        // TODO: Improve me
        setTimeout(() => window.location.reload(), 500)
      } else {
        createErrorNotification("Failed to save force complete payment");
      }
    }
  }

  const handleCancel = () => {
    props.toggleForceComplete();
    resetState();
  }

  const handleCommentChange = (event) => {
    setForceCompleteComment(event.target.value);
  }

  const handleStageChange = (event) => {
    setForceCompleteStage(event.target.value);
  }

  const handleAmountChange = (event) => {
    if (dollarAmountRegex.test(event.target.value)) {
      setAmountError(false);
      setAmount(event.target.value);
    } else {
      setAmountError(true);
    }
  }

  const body = (
    <div className={classes.paper}>
      <h2 id="simple-modal-title">Force Complete Payment</h2>
      <form onSubmit={handleSubmit}>
        <TextField label="Stage" select value={forceCompleteStage} fullWidth={true} margin="normal" required={true} error={forceCompleteStageError} onChange={handleStageChange} >
          {stages.filter(stage =>
            (props.loanHistoryData.hasStagedFunding && !props.loanHistoryData.paidStages.includes(stage.value) && stage.value !== 'unstaged')
             ||
            (!props.loanHistoryData.hasStagedFunding && !props.loanHistoryData.paidStages.includes(stage.value) && stage.value === 'unstaged')
          ).map(stage => UnpaidStages(stage))}
          <MenuItem key={'none'} value={'none'}>No Stage</MenuItem>
        </TextField>
        <TextField label="Amount" inputProps={{ maxLength: 9 }} InputProps={{startAdornment: <InputAdornment position="start">$</InputAdornment>,}} fullWidth={true} margin="normal" required={true} error={amountError} onChange={handleAmountChange} />
        <TextField label="Comment" onChange={handleCommentChange} fullWidth={true} />
        <Grid container spacing={5} justifyContent="flex-start">
          <Grid item xs={12}>
            <Button variant="contained" color="primary" className={classes.modalButton} onClick={handleSubmit}>Create</Button>
            <Button variant="contained" className={classes.modalButton} onClick={handleCancel}>Cancel</Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );

  return (
    <div>
      <Modal
        open={props.showForceComplete}
        onClose={props.toggleShowForceComplete}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}

export default ForceCompletePayment;

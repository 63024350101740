import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import PersonIcon from '@material-ui/icons/Person';
import blue from '@material-ui/core/colors/blue';

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }))(Tooltip);

const BorrowerMetricTooltip = (props) => {
    return (
        <div style={{display:"flex"}}>
          <HtmlTooltip interactive={true}
            title={
              <React.Fragment>
                <Typography color="inherit"><b><u>Borrower Details</u></b></Typography>
                <p><b>Name: </b>{props.borrowerName}</p>
                <p><b>Credit Score: </b>{props.borrowerCreditScore}</p>
                <p><b>Employer: </b>{props.borrowerEmployer}</p>
                {props.borrowerAddress ? 
                (<p><b>Address: </b>{props.borrowerAddress}</p>) : null}
                <p><b>DTI: </b>{props.borrowerDTI}</p>
                <p><b>Income: </b>{props.borrowerIncome}</p>
                {props.coborrowerCreditScore ? 
                <React.Fragment>
                  <Typography color="inherit"><b><u>Coborrower Details</u></b></Typography>
                  <p><b>Name: </b>{props.coborrowerName}</p>
                  <p><b>Credit Score: </b>{props.coborrowerCreditScore}</p>
                  <p><b>Employer: </b>{props.coborrowerEmployer}</p>
                </React.Fragment>
                : ''}
              </React.Fragment>
            }
          >
            <IconButton component="span">
                <PersonIcon style={{ color: blue[700] }} />
            </IconButton>
          </HtmlTooltip>
        </div>
      );
}

export default BorrowerMetricTooltip;
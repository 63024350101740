import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 280,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }))(Tooltip);

const DealerMetricTooltip = (props) => {
    return (
          <HtmlTooltip
            title={
              <React.Fragment>
                <Typography color="inherit"><b><u>Dealer Metrics</u></b></Typography>
                <p><b>Loan Count: </b>{props.totalFundedLoans}</p>
                <p><b>Max Loan Amount: </b>{props.maxLoanAmount}</p>
                <p><b>Total Amount Loaned: </b>{props.totalLoanAmount}</p>
                <p><b>Average Loan Amount: </b>{props.averageLoanAmount}</p>
                <p><b>First Funding Date: </b>{props.firstFundingDate}</p>
                <p><b>Last Funding Date: </b>{props.lastFundingDate}</p>
                <p><b>Concierge Dealer: </b>{props.dealerConcierge}</p>
                <p><b>Delinquent Loans: </b>{props.dealerDelinquentLoans} ({props.totalFundedLoans === 0 ? 0 : Math.trunc(100 * (props.dealerDelinquentLoans / props.totalFundedLoans))}%)</p>
                <p><b>Delinquent Loans For 30+ Days: </b>{props.dealerThirtyDayDelinquentLoansCount} ({props.totalFundedLoans === 0 ? 0 : Math.trunc(100 * (props.dealerThirtyDayDelinquentLoansCount / props.totalFundedLoans))}%)</p>
                <p><b>All-Time Average Credit Score: </b>{props.dealerAllTimeAverageCreditScore}</p>
                <p><b>Last Year Average Credit Score: </b>{props.dealerLastYearAverageCreditScore}</p>
              </React.Fragment>
            }
          >
            <Button>{props.dealerName}</Button>
          </HtmlTooltip>
      );
}

export default DealerMetricTooltip;
import React, { useState } from 'react';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import CreateIcon from '@material-ui/icons/Create';
import PublishIcon from '@material-ui/icons/Publish';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { useNotification } from '../../contexts/NotificationContext';

const useStyles = makeStyles((theme) => ({
    activeEditCell: {
        display: 'flex', 
        maxWidth: '250px',
    },
    dormantEditCell: {
        display: 'flex', 
    },
    editIcon: {
        marginTop: '-10px',
    },
    submitIcon: {
        marginTop: '25px',
    },
  }));

const EditableTextCell = (props) => {
    const classes = useStyles();
    const [editModeEnabled, setEditModeEnabled] = useState(false);
    const [newValue, setNewValue] = useState(props.value);
    const { createErrorNotification } = useNotification();
    
    const handleEditClick = () => {
        setEditModeEnabled(true);
        setNewValue(props.value);
    }

    const handlePublishClick = async () => {
        try {
            if(props.regex !== undefined && props.regex !== null && !props.regex.test(newValue)) {
                throw Error('Value not in desired format.');
            } 
            const response = await props.onSave(props.id, newValue);

            if (response.status && response.status === 200) {
                // TODO: Improve me
                setTimeout(() => window.location.reload(), 100)
            } else {
                createErrorNotification(`Failed to update ${props.label}`);
            }
        } catch (err) {
            createErrorNotification(`Failed to update ${props.label}: ${err}`);
        }
    }

    const handleCancelClick = () => {
        setEditModeEnabled(false);
        setNewValue(props.value);
    }

    const handleTextChange = (event) => {
        setNewValue(event.target.value);
    }

    return editModeEnabled ?
        <div className={classes.activeEditCell}>
            <div>
                <TextField value={newValue} 
                    label={props.label} 
                    fullWidth={true} 
                    margin="normal" 
                    required={true} 
                    onChange={handleTextChange} 
                />
            </div>
            <div>
                <Tooltip title={`Submit ${props.label}`}>
                    <IconButton onClick={handlePublishClick} className={classes.submitIcon}> 
                        <PublishIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </div>
            <div>
                <Tooltip title={"Cancel"}>
                    <IconButton onClick={handleCancelClick} className={classes.submitIcon}> 
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </div>
        </div> :
        <div className={classes.dormantEditCell}>
            <div>{props.value} </div>
            <div>
                <Tooltip title={`Update ${props.label}`}>
                    <IconButton onClick={handleEditClick} className={classes.editIcon}> 
                        <CreateIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </div>
        </div>;
}

export default EditableTextCell;
import React, { Fragment } from 'react';
import AttachMoneyRoundedIcon from '@material-ui/icons/AttachMoneyRounded';
import green from '@material-ui/core/colors/green';
import grey from '@material-ui/core/colors/grey';
import yellow from '@material-ui/core/colors/yellow';
import blue from '@material-ui/core/colors/blue';

const getStageColor = (paidStages, heldStages, stagesToCheck, hasForcedPayment) => {
    let colorChoice = grey[500]; // default

    if (paidStages.some(s=> stagesToCheck.indexOf(s) >= 0)) {
        if (hasForcedPayment) {
            colorChoice = blue[500]
        } else {
            colorChoice = green[500]
        }
    } else if (heldStages.some(s=> stagesToCheck.indexOf(s) >= 0)) {
        colorChoice = yellow[400];
    } 

    return colorChoice;
}

// cant get forcedPayment value from loan props
const FundingStagesCell = (props) => {
    const { paidStages = [], heldStages = [], hasStagedFunding = false, hasForcedPayment = false, numberOfPaymentStages = 3} = props;

    return (
        <Fragment>
            <AttachMoneyRoundedIcon style={{ width: '20px', color: getStageColor(paidStages, heldStages, ['unstaged', 'first'], hasForcedPayment), pointerEvents: 'none' }} />
            {
                hasStagedFunding ?
                    <Fragment>
                        {/* Vision sends 2 stage second payment as second but Defi sends those as final so in that cae check for second or final */}
                        { numberOfPaymentStages >= 2 ? 
                            <AttachMoneyRoundedIcon style={{ width: '20px', color: getStageColor(paidStages, heldStages, (numberOfPaymentStages === 2 ? ['second','final'] : ['second']), hasForcedPayment), pointerEvents: 'none' }} /> :
                            null
                        }
                        { numberOfPaymentStages >= 3 ? 
                            <AttachMoneyRoundedIcon style={{ width: '20px', color: getStageColor(paidStages, heldStages, ['final'], hasForcedPayment), pointerEvents: 'none' }} /> :
                            null
                        }
                    </Fragment> :
                    null
            }
        </Fragment>
    );
}

export default FundingStagesCell;
import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Paper';
import CardContent from '@material-ui/core/CardContent';
import { LoanService } from '../../services';
import { useNotification } from '../../contexts/NotificationContext';
import { makeStyles } from '@material-ui/core/styles';
import { centsToDollar } from '../../utils/formatters';
import USAMap from "react-usa-map";
import './map.css';
import { transform } from 'lodash';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    header: {
        textAlign: 'center',
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
    },
    title: {
      fontSize: 14,
    },
    mapSection: {
        paddingBottom: '75px !important',
    },
    mapContainer: {
        minWidth: '1000px',
    },
    legend: {
        listStyle: 'none',
        '& li': {
            float: 'left',
            marginRight: '10px',
        },
        '& span': {
            border: '1px solid #ccc',
            float: 'left',
            width: '12px',
            height: '12px',
            margin: '2px',
        }
    },
    white: {
        backgroundColor: '#ffffff',
    },
    orange1: {
        backgroundColor: '#ffaf7a',
    },
    orange2: {
        backgroundColor: '#ff9d5c',
    },
    orange3: {
        backgroundColor: '#ff8b3d',
    },
    orange4: {
        backgroundColor: '#ff781f',
    },
    orange5: {
        backgroundColor: '#ff6600',
    }
  }));

const Dashboard = (props) => {
    const [metrics, setMetrics] = useState(null);
    const classes = useStyles();
    const { createErrorNotification } = useNotification();

    useEffect(() => {
        LoanService.getDashboardMetrics().then(res => {
            if (res) {
              setMetrics(JSON.parse(res).dashboard);
            } else {
              createErrorNotification("Failed to retrieve loans, try refreshing...");
            }
        });
    }, [createErrorNotification])

    const mapHandler = (event) => {
        alert(`${event.target.dataset.name} has 0 loans`);
    };

    const statesCustomConfig = () => {
        const x = transform(metrics.stateLookup, (r, v, k) => {
            r[k] = {
                fill: lookupColor(parseInt(v)),
                clickHandler: (event) => alert(`${event.target.dataset.name} has ${v} loans`)
            }
        });

        return x;
    };

    const lookupColor = (count) => {
        let color = '#ffffff';
        if (count > 0 && count <= 100) {
            color = '#ffaf7a';
        } else if (count > 100 && count <= 500) {
            color = '#ff9d5c';
        } else if (count > 500 && count <= 1000) {
            color = '#ff8b3d';
        } else if (count > 1000 && count <= 2500) {
            color = '#ff781f';
        } else if (count > 2500) {
            color = '#ff6600';
        }

        return color;
    }

    return metrics === null ?  (<h1>Loading...</h1>) :
        (
        <div className={classes.root}>
          <h1 className={classes.header}>PowerPay Processing Portal</h1>
          <Grid container spacing={5}>
            <Grid item xs={8} sm={6} md={3}>
                <Card className={classes.paper}>
                    <h2>Loans</h2>
                    <CardContent>
                        <h1>{metrics.loanCount}</h1>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={8} sm={6} md={3}>
                <Card className={classes.paper}>
                    <h2>Dealers</h2>
                    <CardContent>
                        <h1>{metrics.dealerCount}</h1>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={8} sm={6} md={3}>
                <Card className={classes.paper}>
                    <h2>Monthly Funding</h2>
                    <CardContent>
                        <h1>{centsToDollar(metrics.monthlyFunding)}</h1>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={8} sm={6} md={3}>
                <Card className={classes.paper}>
                    <h2>Monthly Payments</h2>
                    <CardContent>
                        <h1>{centsToDollar(metrics.monthlyPayments)}</h1>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} className={classes.mapSection}>
                <Paper className={`${classes.paper} ${classes.mapContainer}`}>
                    <Grid container spacing={5}>
                        <Grid item xs={12}>
                            <div>
                                <ul className={classes.legend}>
                                    <li><span className={classes.white}></span> 0</li>
                                    <li><span className={classes.orange1}></span> 0-100</li>
                                    <li><span className={classes.orange2}></span> 100-500</li>
                                    <li><span className={classes.orange3}></span> 500-1000</li>
                                    <li><span className={classes.orange4}></span> 1000-2500</li>
                                    <li><span className={classes.orange5}></span> 2500+</li>
                                </ul>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <USAMap className={classes.map} customize={statesCustomConfig()} onClick={mapHandler}/>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
          </Grid>
        </div>
        );
}

export default Dashboard;
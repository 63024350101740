import React, { memo, useState, Fragment } from 'react';
import { LoanService } from '../../services';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Delete from '@material-ui/icons/Delete';
import ConfirmationModal from '../../components/ConfirmationModal'
import { formatDate, getCurrentDateFormatted } from '../../utils/formatters';
import { useRole } from '../../contexts/RolesContext';

const DeleteReturnsButton = ({selectedRowIndices, potentialDeletions, date}) => {
  const [showDeleteReturnsConfirmation, setShowDeleteReturnsConfirmation] = useState(false);
  const { hasRole, isAdmin } = useRole();

  const toggleDeleteReturnsConfirmation = () => setShowDeleteReturnsConfirmation(!showDeleteReturnsConfirmation);

  const handleDeleteClick = () => {
    toggleDeleteReturnsConfirmation();
    LoanService.deleteReturns(selectedRowIndices.map(rowId => {
      return potentialDeletions[rowId].id;
    })).then(response => {
      // !FIXME: Do this better!
      window.location.reload();
    });
  }

  return (formatDate(date) === getCurrentDateFormatted() && (isAdmin()  && !hasRole('cs')) ? (
    <>
    <Fragment>
        <Tooltip title={"Delete Returns"}>
          <IconButton onClick={toggleDeleteReturnsConfirmation}> 
            <Delete />
          </IconButton>
        </Tooltip>
    </Fragment>
    <ConfirmationModal title="Confirm" message="Are you sure you want to delete these returns" onConfirm={handleDeleteClick} onCancel={toggleDeleteReturnsConfirmation} showConfirmationBox={showDeleteReturnsConfirmation}/>
    </>
  ) : null);
}

export default memo(DeleteReturnsButton);
import React, { useState, useEffect } from 'react';
import { DealerService } from '../../services';
import TestTransactionTable from './TestTransactionTable';


const TestTransactions = (props) => {
    const [testTransactions, setTestTransactions] = useState(null);

    useEffect(() => {
        if (testTransactions === null) {
            DealerService.getAllTestTransactions().then(data => {
                setTestTransactions(data);
            })
        }
    }, [testTransactions]); 

    return (
        <div id="test-transaction-container">
          <h1>Test Transactions</h1>
          <TestTransactionTable testTransactions={testTransactions} />
        </div>
    );
}

export default TestTransactions;
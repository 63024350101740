import React, { memo, Fragment, useState } from 'react';
import { LoanService } from '../../services';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import WatchLater from '@material-ui/icons/WatchLater';
import ConfirmationModal from '../../components/ConfirmationModal';
import { useNotification } from '../../contexts/NotificationContext';

const AddMarkAsProcessingButton = ({selectedRowIndices, payments}) => {
  const { createErrorNotification } = useNotification();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const updateablePaymentIds = selectedRowIndices.map(rowId => payments[rowId]).filter(p => p.status !== 'paid').map(p => p.id);
  const nonUpdatablePaymentsCount = selectedRowIndices.length - updateablePaymentIds.length;

  const handleClick = () => {
    if (updateablePaymentIds.length === 0) {
      createErrorNotification('Aborting: "Paid" payments may not be marked as processing.');
    } else if (nonUpdatablePaymentsCount > 0) {
      setShowConfirmation(!showConfirmation);
    } else {
      handleProcessingClick();
    }
  }

  const handleProcessingClick = () => {
    LoanService.markPaymentsAsProcessing(updateablePaymentIds).then(response => {
      // !FIXME: Do this better!
      window.location.reload();
    });
  }

  return (
    <Fragment>
      <ConfirmationModal
        title='Cannot mark paid payments as processing'
        message={`${nonUpdatablePaymentsCount} payments have a status of 'Paid' and cannot be marked as 'Processing'. Continue marking the remaining ${updateablePaymentIds.length} payments?`}
        onConfirm={handleProcessingClick}
        onCancel={handleClick}
        showConfirmationBox={showConfirmation}/>
      <Tooltip title={`Mark Payments as 'Processing'`}>
        <IconButton onClick={handleClick}>
          <WatchLater />
        </IconButton>
      </Tooltip>
    </Fragment>
  );
}

export default memo(AddMarkAsProcessingButton);

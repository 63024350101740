export const numericRegex = RegExp(/^\d+$/);

export const dollarAmountRegex = RegExp(/^[0-9]+(\.[0-9][0-9])?$/)

export function isBlank(value) {
    return value == null || value.trim().length === 0;
}

export function updateRequiredField(value, updater, errorUpdater) {
    if (isBlank(value)) {
      errorUpdater(true);
      return true;
    } else {
      errorUpdater(false);
      updater(value);
      return false;
    }
}

export const stages = [
  {
    value: 'unstaged',
    label: 'Unstaged',
  },
  {
    value: 'first',
    label: 'First',
  },
  {
    value: 'second',
    label: 'Second',
  },
  {
    value: 'final',
    label: 'Final',
  },
];
import React, { useEffect } from "react";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import { NotificationProvider } from "./contexts/NotificationContext";
import { RoleProvider } from "./contexts/RolesContext";
import { Base } from "./layouts"; /**@todo rename to something like "BaseUILayout" or "BaseLayout". "Base" is a bit too vague... */
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { Provider, ErrorBoundary } from '@rollbar/react';
import {
  Loans,
  Loan,
  DailyProcessing,
  UserAdmin,
  Dealers,
  Dealer,
  QuicksightDashboard,
  TestTransactions,
  Tools,
  Login,
  UpdatePassword,
  ResetPassword,
  PageNotFound,
  Fees,
} from "./pages";
import theme from "./utils/theme";
import "./App.css";
import { createBrowserHistory } from "history";
import { readCookie } from "./utils/cookiesHandler";

function App() {
  const history = createBrowserHistory();
  const publicPages = ["/login", "/reset-password", "/register"]
  const rollbarConfig = {
    accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      environment: process.env.REACT_APP_DEPLOY_ENV === 'production' ? 'PROD' : process.env.REACT_APP_DEPLOY_ENV,
    },
  };

  const allRoles = ["admin", "daily-processing-write", "daily-processing-read", "ach-admin"]
  const roles = process.env.REACT_APP_DEPLOY_ENV.includes("LOCAL") ? allRoles : JSON.parse(readCookie("roles"))

  useEffect(() => {
    const unlisten = history.listen((location) => {
      if (process.env.REACT_APP_DEPLOY_ENV.includes("LOCAL")) return;

      // if the page is not public, and the user isn't logged in, redirect to login
      if (!readCookie("jwt") && !([...publicPages, "/404"].includes(location.pathname))){
        history.push("/login")
      }

      // if the page is public, but the user is logged in, redirect to "/"
      if (readCookie("jwt") && publicPages.includes(location.pathname)){
        history.push("/lms")
      }
    })

    return () => {
      unlisten();
    }
  }, [history, publicPages])

  return (
    <Provider config={rollbarConfig}>
    <ErrorBoundary>
    <ThemeProvider theme={theme}>
      <NotificationProvider>
        <RoleProvider roles={roles}>
          <Router history={history}>
            <Switch>
              <Route exact path="/login" render={({history}) => <Login history={history} />} />
              <Route exact path="/" render={({history}) => <Login history={history} />} />
              <Route exact path="/reset-password" render={({history}) => <ResetPassword history={history} />} />
              <Route exact path="/update-password" render={({history}) => <UpdatePassword history={history} />} />
              <Route exact path="/404" render={(props) => <PageNotFound {...props} />} />
              <Route
                render={({history}) => (
                  <Base>
                    <Switch>
                      <Route exact path="/dashboard" render={(props) => <QuicksightDashboard />} />
                      <Route exact path="/lms" render={(props) => <Loans />} />
                      <Route
                        exact
                        path="/lms/loans" // maybe this path is unnecesary, considering the one above...
                        render={(props) => <Loans />}
                       />
                      <Route exact path="/lms/daily-processing" render={(props) => <DailyProcessing />} />
                      <Route path="/lms/loans/:loanNumber" render={(props) => <Loan />} />
                      <Route exact path="/admin" render={(props) => <Tools />} />
                      <Route exact path="/admin/users" render={(props) => <UserAdmin />} />
                      <Route path="/admin/Tools" render={(props) => <Tools />} />
                      <Route path="/admin/Fees" render={props => <Fees /> } />
                      <Route exact path="/dealers" render={(props) => <Dealers />} />
                      <Route exact path="/dealers/test-transactions" render={(props) => <TestTransactions />} />
                      <Route path="/dealers/:dealerNumber" render={(props) => <Dealer />} />
                      <Route><Redirect to="/404" /></Route>
                    </Switch>
                  </Base>
                )}
              />
            </Switch>
          </Router>
        </RoleProvider>
      </NotificationProvider>
    </ThemeProvider>
    </ErrorBoundary>
    </Provider>
  );
}

export default App;

import React from 'react';
import { Box, makeStyles, Modal, Button} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import SuccessIcon from './SuccessIcon';
import ErrorIcon from './ErrorIcon';
import grey from '@material-ui/core/colors/grey';

const styles = makeStyles((theme, props) => ({
    modal: {
        backgroundColor: '#fff',
        border: '1px solid #000',
        borderRadius: '6px',
        display:'block',
        position: 'absolute', 
        top: '50%', left: '50%', 
        transform: 'translate(-50%, -50%)',
        width: '32%',
        height:'auto',
        paddingLeft: '12px',
        paddingRight: '12px',
        paddingBottom: '12px',
    },
    inline: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        paddingTop: '0px',
        marginTop: '12px',
        marginRight: '64px',
        pointerEvents: 'none'
    },
    title: {
        paddingLeft: '4px',
    },
    text: {
        paddingLeft: '12px',
        fontSize: '16px',
        color: `${grey[700]}`,
        pointerEvents: 'none'
    },
    button: {
        display: 'flex',
        alignItems: 'right',
        marginLeft: 'auto',
        marginRight: '2px',
        marginTop: '4px',
        color: `${grey[800]}`,
    }
}));

const SuccessFailModal = (props) => {
    const classes = styles();
    return (
        <div>
          <Modal
            open={props.open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className={classes.modal}>   
                {(props.success === true) ? (
                  <div className={classes.inline}>
                    <SuccessIcon className={classes.icon}/>
                    <Typography variant="h4" className={classes.title}>Success</Typography>
                  </div> 
                ) : (
                  <div className={classes.inline}>
                    <ErrorIcon className={classes.icon}/>
                    <Typography variant="h4" className={classes.title}>Failed</Typography>
                  </div> 
                )}
                
                <div className={classes.text}>
                    {props.dialog}
                </div>
                <Button 
                    disableRipple 
                    variant="text" 
                    onClick={props.onClose} 
                    className={classes.button}
                > OK </Button>             
            </Box>
          </Modal>
        </div>
      );
}

export default SuccessFailModal;
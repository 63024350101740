import React, { useState, useEffect } from 'react';
import { UserService } from '../../services';
import UserAdminTable from './UserAdminTable';
import NewUser from './NewUser';
import { useRole } from '../../contexts/RolesContext';

const UserAdmin = (props) => {
    const [users, setUsers] = useState(null);
    const [roles, setRoles] = useState(null);
    const [showNewUser, setShowNewUser] = useState(false);
    const { isUserAdmin } = useRole();

    useEffect(() => {
        if (isUserAdmin()) {
            UserService.getRoles().then(data => {
                setRoles(data.roles);
            })
        }
    }, [isUserAdmin]);

    useEffect(() => {
        if (isUserAdmin()) {
            UserService.getAll().then(data => {
                setUsers(data.users);
            })
        }
    }, [isUserAdmin]);

    const toggleShowNewUser = () => setShowNewUser(!showNewUser);

    return (
        isUserAdmin() ?
        <div id="user-admin-container">
          <h1>User Admin</h1>
          <UserAdminTable users={users} roles={roles} toggleShowNewUser={toggleShowNewUser}/>
          <NewUser showNewUser={showNewUser} toggleShowNewUser={toggleShowNewUser} />
        </div>
        : null
    );

}

export default UserAdmin;
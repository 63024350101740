import React, { Fragment, useEffect, useState }  from 'react';
import { LoanService } from '../services';
import { useNotification } from '../contexts/NotificationContext';
import MUIDataTable from 'mui-datatables';
import { saveTableSession, getTableSession } from '../utils/sessionHelpers';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import RefreshIcon from '@material-ui/icons/Refresh';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import LinearProgress from '@material-ui/core/LinearProgress';

const styles = makeStyles(() => ({
    idsButton: {
        width: 170, maxWidth: 200,
        textAlign: 'left',
    }, idsButtonInactive: {
        width: 170, maxWidth: 200,
        textAlign: 'left',
        pointerEvents: 'none',
    },
    toolBarButton: {
        marginRight: '8px',
    }, box: {
        position: 'absolute',
        top: '50%', left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '800px',
        backgroundColor: '#fff',
        paddingLeft: '32px',
        paddingTop: '16px',
        paddingBottom: '16px',
        border: '1px solid #000',
        borderRadius: '8px',
        overflow:'scroll',
        height:'400px',
        display:'block'
    }, loadingBox: {
        position: 'absolute',
        top: '50%', left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '800px',
        backgroundColor: '#fff',
        paddingLeft: '32px', paddingRight: '32px', paddingTop: '16px', paddingBottom: '16px',
        border: '1px solid #000',
        borderRadius: '8px',
        overflow:'scroll',
        height:'100px',
        display:'block'
    }
}));

const renderIdsButtonDisplay = (idsLst) => {
    const item1 = idsLst[0];
    const item2 = idsLst[1];
    if (idsLst.length > 2 && item2 != null){
        return item1 + ', ' + item2 + '...';
    }
    return item1;
}

const HubspotRetryQueue = () => {
    const [queryPull, setQueryPull] = useState(null);
    const { createErrorNotification, createNotification } = useNotification();
    const starterColumns = ['id', 'endpoint', 'ids', 'created'];
    const [visibleColumns] = useState( (sessionStorage.getItem("hubspotRetryQueue")) ? getTableSession("hubspotRetryQueue") : saveTableSession(starterColumns, "hubspotRetryQueue"));
    const [selectedRowIndices, setSelectedRowsIndices] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [loadingOpen, setLoadingOpen] = useState(false);
    const toggleLoadingModal = () => setLoadingOpen(!loadingOpen);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [idsModalText, setIdsModalText] = useState(null);
    const classes = styles();
    const openIDSField = value => { setIdsModalText(value.join(', ')); handleOpen();}


    const tablifyHubspotRetryQueue = (queryPull) => (queryPull == null)
    ? null
    : queryPull.map(rows => {
        const rowsIdsVerbose = rows.ids.replace(/["]+|[{]+|[}]+/g, '').split(",");
        return [rows.id, rows.endpoint, rowsIdsVerbose, rows.created];
    });

    const retryEntries = async () => {
        toggleLoadingModal();
        const vr = selectedRowIndices.map((currElement, index) => {
            return queryPull[currElement];
        });

        LoanService.retryHubspotRetryEntry(vr).then(response => {
            if (response && response === 200) {
                toggleLoadingModal();
                createNotification(`Successfully sent ${vr.length} ${vr.length > 1 ? 'entries' : 'entry'}. Reloading Screen...`);
                setTimeout(window.location.reload(), 50000)
                // window.location.reload();
            } else {
                toggleLoadingModal();
                createErrorNotification("Failed to send entries. Try again.");
            }
        });

    }
    const deleteEntries = () => {
        const vr = selectedRowIndices.map((currElement, index) => {
            return queryPull[currElement];
        });
        LoanService.deleteHubspotRetryEntry(vr);
        window.location.reload();
    }

    const columns = [{
            name: 'Entry ID',
            options: {
                display: visibleColumns ? visibleColumns.includes('ids') : true,
                filter: false, sort: false,
            }
        }, {
            name: 'Endpoint',
            options: {
                display: visibleColumns ? visibleColumns.includes('endpoint') : true,
                filter: false, sort: false,
            }
        },{
            name: 'IDS',
            options: {
                customBodyRender: (value) => {
                    return value.length < 3 ? (
                        <Button
                            size="large"
                            disableElevation
                            disableRipple
                            className={classes.idsButtonInactive}
                        >
                            {renderIdsButtonDisplay(value)}
                        </Button>
                    ) : (
                        <div>
                            <Button onClick={() => openIDSField(value)}
                                size="large"
                                disableElevation
                                disableTouchRipple
                                className={classes.idsButton}
                            >
                                {renderIdsButtonDisplay(value)}
                            </Button>
                        </div>
                );},
                filter: false, sort: false,
            }
        }, {
            name: 'Timestamp',
            options: {
                display: visibleColumns ? visibleColumns.includes('created') : true,
                filter: false, sort: false,
            }
        },
    ];

    // DATABASE QUERY
    useEffect(() => {
        if (queryPull === null) {
            LoanService.getRetryHubspotQueue().then(response => {
                if (response && response.status === 200 && response.data != null) {
                    setQueryPull(response.data.data);
                } else {
                    createErrorNotification("Error: Failed to pull queue");
                }
            });
        }
    }, [queryPull, createErrorNotification]);

    // TABLE
    return (queryPull === null)
        ? (
            <div>
                <h3>Loading...</h3>
            </div>
        )
        : (
            <Fragment>
                <MUIDataTable
                    data={tablifyHubspotRetryQueue(queryPull)}
                    columns={columns}
                    options={{
                        selectableRows: 'multiple',
                        rowsSelected: selectedRowIndices,
                        onRowSelectionChange: (rowsSelected, allRows) => {setSelectedRowsIndices(allRows.map(row => row.dataIndex));},
                        customToolbarSelect: selectedRows => (
                            <div>
                                <div>
                                    <Button
                                        className={classes.toolBarButton}
                                        endIcon={<RefreshIcon/>}
                                        onClick={retryEntries}>
                                    </Button>
                                    <Button
                                        className={classes.toolBarButton}
                                        endIcon={<DeleteIcon/>}
                                        onClick={deleteEntries}
                                    >
                                    </Button>
                                </div>
                            </div>
                        ),
                        search: false,
                        download: false,
                        print:false,
                        viewColumns: false,
                        filter: false
                    }}
                />
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className={classes.box}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            IDS
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            {idsModalText}
                        </Typography>
                    </Box>
                </Modal>
                <Modal
                    open={loadingOpen}
                    onClose={toggleLoadingModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className={classes.loadingBox}>
                        <Typography id="modal-modal-title" sx={{ mt: 2, width: '380px' }}>
                            Loading. . .
                        </Typography>
                        <LinearProgress />
                    </Box>
                </Modal>
            </Fragment>
    );
}

export default HubspotRetryQueue;
import React, { Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import NotificationTable from './NotificationsTable';

const styles = makeStyles((theme) => ({
    root: {
      },
      summaryContainer: {
        paddingLeft: '50px',
        paddingRight: '50px'
      },
      summaryHeader: {
          textAlign: 'center'
      },
      summaryTotals: {
        textAlign: 'center',
        paddingTop: '10px',
        paddingBottom: '10px'
      },
      summaryBody: {
        paddingTop: '10px',
        paddingBottom: '10px'
      },
      summary: {
        backgroundColor: theme.palette.grey.main,
        borderRadius: '5px',
        border: 'groove',
        borderColor: 'black',
        borderWidth: '0.5px',
      },
      table: {
          marginTop: '30px'
      }
}));

const Notifications = ({ dailyProcessingData, selectedDate }) => {
    const classes = styles();
    useTheme();
    const { notificationSummary, notifications } = dailyProcessingData || {};

    return dailyProcessingData === null ? (
        <h1>Loading...</h1>
    ) : (
        <Fragment>
            <Grid container direction="column">
                <Grid item className={classes.summaryContainer}>
                    <h3 className={classes.summaryHeader}>Summary</h3>
                    <Grid container direction="column" className={classes.summary} >
                        <Grid item >
                            <Grid container spacing={2} justifyContent="space-evenly" direction="row" className={classes.summaryBody} >
                                <Grid item className={classes.summaryTotals}>
                                    <li>Merchant Payment Total: {notificationSummary[0].dealerPaymentTotal || 0}</li>
                                    <li>Merchant Payment Successful: {notificationSummary[0].dealerPaymentSuccessful || 0}</li>
                                </Grid>
                                <Grid item className={classes.summaryTotals}>
                                    <li>Internal Funding Total: {notificationSummary[0].internalFundingTotal || 0}</li>
                                    <li>Internal Funding Successful: {notificationSummary[0].internalFundingSuccessful || 0}</li>
                                </Grid>
                                <Grid item className={classes.summaryTotals}>
                                    <li>Merchant Return Total: {notificationSummary[0].dealerReturnTotal || 0}</li>
                                    <li>Merchant Return Successful: {notificationSummary[0].dealerReturnSuccessful || 0}</li>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={classes.table}>
                    <NotificationTable notifications={notifications} selectedDate={selectedDate}/>
                </Grid>
            </Grid>
        </Fragment>
    )
}

export default Notifications;
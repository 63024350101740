import { request } from './utils';

export default class ReturnService {
    static async updateReturnAmount(returnId, newAmount) {
        const response = await request(`/returns/${returnId}/update/amount`, {
            method: 'PATCH',
            data: JSON.stringify({amount: newAmount})
        });

        return response;
    }

    static async updateStage(returnId, stage) {
        const response = await request(`/returns/${returnId}/update/stage`, {
            method: 'PATCH',
            data: JSON.stringify({stage: stage})
        });

        return response;
    }
}
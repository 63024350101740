import React, { Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import {
    SESInputOutput,
    ToolsComponent,
    SyncHubspotLoanInputOutput,
    ReassignLoanDealer,
    ResetTestTransactiion,
    HubspotRetryQueue,
    RepullDataDump,
    PullDelinquencies,
    PullDelinquenciesComment,
    RefreshDealerMaterializedView,
    SyncPaymentFRReturnWithHubspot,
    SyncDealerWithHubspot,
    PullAllNLSComments,
    NLSDownloadXML,
    SendMailSoarTestEmails,
    SendAllLoanNotesToHubspot,
    SendAllMerchantNotesToHubspot,
    PushPendingLoanPCGNotes,
    DownloadPaymentWithoutVisionIdCsv,
    ToolsTableComponent,
} from '../../components';

const styles = makeStyles((theme) => ({
    root: {},
    summaryContainer: {
        paddingLeft: '0px',
        paddingRight: '0px',
        width: '90vw',
    },
    summaryHeader: {
        textAlign: 'left',
        fontSize: '20pt'
    },
}));

const Tools = (props) => {
    const classes = styles();
    return(
        <Fragment>
            <h3 className={classes.summaryHeader}>Tools Page</h3>
            <Grid container direction="column">
                <ToolsComponent toolName = {'Send Email to Admin'} description = {'This will send an email to admin'} InputOutput = {<SESInputOutput/>} />
                <ToolsComponent toolName = {'Sync Loan HubSpot'} description = {`Input loan number(s) to sync with HubSpot. Multiple loan numbers should be separated by a comma.`} InputOutput = {<SyncHubspotLoanInputOutput/>} />
                <ToolsComponent toolName = {'Sync Loan Funding Hubspot'} description = {`Input a loan number to sync the loan's payment, funding request, and returns with HubSpot.`} InputOutput = {<SyncPaymentFRReturnWithHubspot/>} />
                <ToolsComponent toolName = {'Sync Dealer With Hubspot'} description = {`Input a dealer number to sync with hubspot. P4 dealer number (5057), not vision dealer number (15057).`} InputOutput = {<SyncDealerWithHubspot/>} />
                <ToolsComponent toolName = {'Pull Delinquencies'} description = {`Click the button to pull today's delinquency record`} InputOutput = {<PullDelinquencies/>} />
                <ToolsComponent toolName = {'Pull Delinquency Comments'} description = {`Click the button to pull today's delinquency comments`} InputOutput = {<PullDelinquenciesComment/>} />
                <ToolsComponent toolName = {'Repull Data Dump'} description = {`It will repull data dump when the button is clicked`} InputOutput = {<RepullDataDump/>} />
                <ToolsComponent toolName = {'Reassign Loan'} description = {`Reassign Loan by Dealer ID`} InputOutput = {<ReassignLoanDealer/>} />
                <ToolsComponent toolName = {'Reset Test Transaction for Current Date'} description = {`Click the button to reset/undo test transactions that are sent today`} InputOutput = {<ResetTestTransactiion/>} />
                <ToolsComponent toolName = {'Refresh Dealer Metric View'} description = {`Click the button to refresh dealer metric`} InputOutput = {<RefreshDealerMaterializedView/>} />
                <ToolsComponent toolName = {'Download NLS Release Hold'} description = {`Click button to download the nightly NLS XML`} InputOutput = {<NLSDownloadXML/>} />
                <ToolsComponent toolName = {'Pull All NLS Comments'} description = {`Click the button to pull All NLS comments`} InputOutput = {<PullAllNLSComments/>} />
                <ToolsComponent toolName = {'Send MailSoar Test Emails'} description = {`Click the button to send test emails to MailSoar's recipients`} InputOutput = {<SendMailSoarTestEmails/>} />
                <ToolsComponent toolName = {'Send All Loan Notes To Hubspot'} description = {`Click the button to send all loan notes to Hubspot`} InputOutput = {<SendAllLoanNotesToHubspot/>} />
                <ToolsComponent toolName = {'Send All Merchant Notes To Hubspot'} description = {`Click the button to send all merchant notes to Hubspot`} InputOutput = {<SendAllMerchantNotesToHubspot/>} />
                <ToolsComponent toolName = {'Push Pending PCG Loan Notes To Hubspot'} description = {`Click the button to push pending PCG loan notes to Hubspot`} InputOutput = {<PushPendingLoanPCGNotes/>} />
                <ToolsComponent toolName = {'Download a CSV of Payments without a Vision payment id'} description = {`Click the button to download a CSV of payments without a Vision payment id`} InputOutput = {<DownloadPaymentWithoutVisionIdCsv/>} />
                <ToolsTableComponent toolName = {'Hubspot Retry Queue'} Table = {<HubspotRetryQueue/>} />
            </Grid>
        </Fragment>
    )
}

export default Tools;

/*eslint no-unused-vars: ["error", { "varsIgnorePattern": "[rR]oleCon" }]*/
import React from 'react';
import { readCookie } from "../utils/cookiesHandler";


const RoleContext = React.createContext();

const { Consumer: RoleConsumer, Provider} = RoleContext;

function RoleProvider({ roles, children }){
    if (roles === undefined) {
        roles = JSON.parse(readCookie("roles"))
    }

    const hasRole = (role) => Array.isArray(roles) && roles.includes(role);

    const isAdmin = () => hasRole("admin");

    const isDailyProcessingWrite = () => hasRole("daily-processing-write");

    const isDailyProcessingRead = () => hasRole("daily-processing-read");

    const isDealerBrowser = () => hasRole("dealer-browser");

    const isAchAdmin = () => hasRole("ach-admin");

    const isUserAdmin = () => hasRole("user-admin");

    return (
        <Provider value={{isAdmin, isDailyProcessingWrite, isDailyProcessingRead, isDealerBrowser, isAchAdmin, isUserAdmin, hasRole}}>
            {children}
        </Provider>
    )
}

function useRole() {
    const context = React.useContext(RoleContext);

    if(context === undefined){
        throw new Error('useRole must be used within a RolesProvider');
    }

    return context;
}

export { RoleProvider, useRole }

import React, { memo, useState, Fragment } from 'react';
import { LoanService } from '../../services';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Delete from '@material-ui/icons/Delete';
import ConfirmationModal from '../../components/ConfirmationModal'
import { formatDate, getCurrentDateFormatted } from '../../utils/formatters';
import { useRole } from '../../contexts/RolesContext';
import { useNotification } from '../../contexts/NotificationContext';

const DeletePaymentButtons = ({selectedRowIndices, potentialDeletions, date}) => {
  const [showDeletePaymentConfirmation, setShowDeletePaymentConfirmation] = useState(false);
  const { hasRole, isAdmin } = useRole();
  const { createErrorNotification } = useNotification();

  const toggleDeletePaymentConfirmation = () => setShowDeletePaymentConfirmation(!showDeletePaymentConfirmation);

  const handleDeleteClick = () => {
    toggleDeletePaymentConfirmation();
    LoanService.deletePayments(selectedRowIndices.map(rowId => {
      return potentialDeletions[rowId].id;
    })).then(response => {
      // !FIXME: Do this better!
      window.location.reload();
    }).catch(error => {
      createErrorNotification(`Error: ${error.response.data.errorMessage}`);
    });
  }

  return (formatDate(date) === getCurrentDateFormatted() && (isAdmin()  && !hasRole('cs')) ? (
    <>
    <Fragment>
        <Tooltip title={"Delete Payments"}>
          <IconButton onClick={toggleDeletePaymentConfirmation}>
            <Delete />
          </IconButton>
        </Tooltip>
    </Fragment>
    <ConfirmationModal title="Confirm" message="Are you sure you want to delete these payments?" onConfirm={handleDeleteClick} onCancel={toggleDeletePaymentConfirmation} showConfirmationBox={showDeletePaymentConfirmation}/>
    </>
  ) : null);
}

export default memo(DeletePaymentButtons);
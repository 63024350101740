import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { UserService } from '../../services';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
// import IconButton from "@material-ui/core/IconButton";
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import { useNotification } from '../../contexts/NotificationContext';
import SendIcon from '@material-ui/icons/Send';
import green from '@material-ui/core/colors/green';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    left: "30%",
    top: "15%",
    width: '40vw',
    height: '65vh',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  editButton: {
    backgroundColor: green[400],
    marginTop:'4px',
    marginRight:'4px',
    textTransform: 'none', 
  },
  rolesItem: {

  },
  rolesText: {
    alignItems: 'center',
    display: 'inline-flex',
    marginTop: '-20px',
    marginLeft: '10px',
  },
  submit: {
    marginTop: '10px',
  },
}));

const EditRolesCell = (props) => {
  const classes = useStyles();
  const [selectedRoles, setSelectedRoles] = useState(props.currentRoles);
  const [showEditRoles, setShowEditRoles] = useState(false);
  const { createErrorNotification } = useNotification();
  const [showWaitMessage, setShowWaitMessage] = useState(false);

  const handleOpen = () => setShowEditRoles(true);
  const handleClose = () => {
    setShowEditRoles(false);
    setSelectedRoles(props.currentRoles);
  }

  const handleCheckboxChange = (role) => {
    setSelectedRoles((prevRoles) => {
      if (prevRoles.includes(role)) {
        return prevRoles.filter((r) => r !== role);
      }
      return [...prevRoles, role];
    });
  };

  const handleSubmit = async () => {
    try {
      const response = await UserService.updateUserRoles({
        username: props.username,
        roles: selectedRoles,
        email: props.email,
      });

      if (response.status === 200) {
        setShowEditRoles(false);
        setShowWaitMessage(true);
        setTimeout(() => window.location.reload(), 10000);
      } else {
        throw new Error("Failed to save User Role change");
      }
    } catch (err) {
      createErrorNotification("Failed to save User Role change");
    }
  };

  const closeWaitMessage = () => {
    setShowWaitMessage(false);
  }

  return (
    <>
      <Snackbar open={showWaitMessage} autoHideDuration={3000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={closeWaitMessage} severity="success">
          {"Your changes will appear in a few seconds. They take effect after the user logs out and logs back in."}
        </Alert>
      </Snackbar>
      <Button onClick={handleOpen} className={classes.editButton} >
        Edit
      </Button>
      <Modal
        open={showEditRoles}
        onClose={handleClose}
      >
        <div className={classes.paper}>
          <h3>Edit Roles for {props.email}</h3>
            {props.rolesData.map((role) => (
              <div key={role} className={classes.rolesItem}>
                {selectedRoles.includes(role) ? (
                  <CheckBoxIcon onClick={() => handleCheckboxChange(role)} />
                ) : (
                  <CheckBoxOutlineBlank onClick={() => handleCheckboxChange(role)} />
                )}
                <p className={classes.rolesText}>{role}</p>
                {/* {role} */}
              </div>
            ))}
          <Button className={classes.submit}
            variant="contained"
            color="primary"
            endIcon={<SendIcon/>}
            onClick={handleSubmit}
            >Submit
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default EditRolesCell;

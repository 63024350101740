import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Description from '../components/Description'
import Grid from '@material-ui/core/Grid';

const styles = makeStyles((theme) => ({
    breakdownText: {
      color: `blue`,
      fontSize: '12pt',
      lineHeight: 'normal',
      marginLeft: '10px',
    },
    summary: {
      backgroundColor: theme.palette.grey.main,
      borderRadius: '5px',
      border: 'groove',
      borderColor: 'black',
      borderWidth: '1px',
      margin: "10px",
      padding: "5px",
      width: "88vw",
    },
  }));

  const ToolsComponent = (props) => {
      const classes = styles();
      const {toolName} = props || {};
      const {description} = props || {};
      const {InputOutput} = props || {};
        return (
            <div>
              <Grid container className={classes.summary}>
                <Grid item xs={3} md={3}>
                  <item>
                    <Typography className={classes.breakdownText} variant="body1" align="left">
                    <p><b>{toolName}</b></p>
                    </Typography>
                  </item>
                </Grid>
                <Grid item xs={6} md={6}>
                  <item>
                    <Description description = {description}>
                    </Description>
                  </item>    
                </Grid>
                <Grid item xs={3} md={3}>
                  <item>
                    {InputOutput}
                  </item>
                </Grid> 
              </Grid>   
            </div>
        )

  }
  
  export default ToolsComponent;
import React, { Fragment, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import RulesTable from './RulesTable';
import RulesList from './RulesList';
import { LoanService } from '../../services';

const styles = makeStyles((theme) => ({
    root: {
      },
      summaryContainer: {
        paddingLeft: '50px',
        paddingRight: '50px',
        width: '85vw'
      },
      summaryHeader: {
          textAlign: 'center'
      },
      summaryBody: {
        paddingTop: '10px',
        paddingBottom: '10px'
      },
      summary: {
        backgroundColor: theme.palette.grey.main,
        borderRadius: '5px',
        border: 'groove',
        borderColor: 'black',
        borderWidth: '0.5px',
      },
      table: {
          marginTop: '30px',
          width: '85vw',
          marginRight: '5vw'
      }
}));

const Rules = ({ dailyProcessingData, selectedDate }) => {
  
    const classes = styles();
    useTheme();
    const [filters, setFilters] = useState(null);

    const handleFilter = (filter) => {
      if(filter === filters) {
        setFilters(null);
      } else {
        setFilters(filter);
      }
    }

    const handleRuleExecution = () => {
      LoanService.executeFundingRules(selectedDate).then(setTimeout(() => window.location.reload(), 100));
    }
    
    return dailyProcessingData === null ? (
        <h1>Loading...</h1>
      ) : (
        <Fragment>
            <Grid container direction="column">
                <Grid item className={classes.summaryContainer}>
                    <h3 className={classes.summaryHeader}>Rules</h3>
                    <Grid container direction="column" className={classes.summary} >
                        <Grid container spacing={2} justifyContent="space-evenly" direction="row" className={classes.summaryBody} >
                            <RulesList rules={dailyProcessingData.rules} filters={filters} setFilters={handleFilter}/>
                        </Grid>                       
                    </Grid>
                </Grid>
                <Grid item className={classes.table}>
                  <h3 className={classes.summaryHeader}>Results</h3>
                  <RulesTable ruleFilters={filters} ruleFailures={dailyProcessingData.ruleFailures.filter((rule) => {return filters ? rule.failureReasons.includes(filters) ? true : false : true})} selectedDate={selectedDate} ruleFunction={handleRuleExecution}/>
                </Grid>
            </Grid>
        </Fragment>
    );
}

export default Rules;
import React, { Fragment } from 'react';
import { editableCell } from '../../utils/cellRenderers';

const UpdateReturnsAmountCell = (props) => {
    if (new Date(props.returnDate).toDateString() === new Date().toDateString() && props.isDPWrite){
        return props.bounced ?
        <Fragment>[BOUNCED] editableCell(props.amount, props.tableMeta, 'return')</Fragment>
        : editableCell(props.amount, props.tableMeta, 'return')
    }
    return props.amount;
}

export default UpdateReturnsAmountCell;